import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setStudentsNumberFrom } from 'store/slices/activateRequestsSlice'
import './NumberOfStudentsFromFilter.css'

const NumberOfStudentsFromFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { studentsNumberFrom } = useSelector((state: RootState) => state.activateRequests)
  const [numberFrom, setNumberFrom] = useState<number | null>(studentsNumberFrom)

  const handleNumberFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null
    setNumberFrom(value)
    // dispatch(setStudentsNumberFrom(value))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setStudentsNumberFrom(numberFrom))
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, numberFrom])

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('NUMBER_OF_STUDENTS_FROM_FILTER')}</p>
      <input
        type="number"
        placeholder={t('NUMBER_OF_STUDENTS_FROM_FILTER')}
        // value={studentsNumberFrom || ''}
        value={numberFrom || ''}
        onChange={handleNumberFromChange}
        className="custom-input"
      />
    </div>
  )
}

export default NumberOfStudentsFromFilter
