import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'
import OTPVerification from 'pages/AccountPages/components/forms/otp-verifiy/otp-verification'
import { useDispatch } from 'react-redux'
import { closeUpdateEmailOTPModal } from 'store/slices/updateEmailOTPModalSlice'

type OtpModalRestEmailProps = {
  onSuccessCallback: () => void
}

function OtpModalRestEmail({ onSuccessCallback }: OtpModalRestEmailProps) {
  const { _ } = useLingui()
  const dispatch = useDispatch()
  const handleBack = () => {
    dispatch(closeUpdateEmailOTPModal())
  }
  return (
    <ModalLayout
      onBack={handleBack}
      backdrop="opaque"
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <Typography weight="bold" size="xl" align="center">
                  {_(msg`تحديث البريد الالكتروني`)}
                </Typography>
                <Typography align="center" weight="light">
                  {_(
                    msg`يُرجى إدخال كلمة المرور المؤقتة التي تم إرسالها إلى بريدك الإلكتروني لتحديث البريد الألكتروني`
                  )}
                </Typography>
              </div>
            </>
          }
        />
      }
      body={
        <OTPVerification
          onClose={handleBack}
          onSuccessCallback={onSuccessCallback}
          isLoginFlow={true}
          newEmail={true}
        />
      }
    />
  )
}

export default OtpModalRestEmail
