import { zodResolver } from '@hookform/resolvers/zod'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import Typography from 'components/typography/typography'
import { FormProvider, useForm } from 'react-hook-form'
import { useCoordinatorActiveAccountSchema } from './schema'
import { z } from 'zod'
import SelectInput from 'components/hook-form/select-input'
import { useRegionOptions } from 'pages/AccountProfile/personal-info/hook/regions-options'
import {
  useEducationStageOptions,
  useGenderOfEducationOptions
} from 'pages/AccountProfile/personal-info/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchMe } from 'store/slices/meSlice'
import { useEducationAdministrationOptions } from 'pages/AccountProfile/personal-info/hook/eduction-adminstrations-options'
import CheckboxInput from 'components/hook-form/checkbox-input'
import { useUniversityOptions } from 'pages/AccountProfile/personal-info/hook/university-options'
import TextInput from 'components/hook-form/text-input'
import UploadInput from 'components/hook-form/upload-input'
import TextareaInput from 'components/hook-form/textarea-input'
import ButtonVariant from 'components/button-variant/button'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { toast } from 'react-toastify'
import PendingApplicationModule from './pending-application-module/pendding-applicatoin-module'
import {
  fetchFileFromURL,
  getCoordinatorStatusApplication,
  getRegionLabelFromCountry
} from './utils'
import RejectApplicationModule from './reject-application-module/reject-application-module'
import SubmitApplicationModule from './submit-application-module/submit-application-module'
import { completeCoordinator } from 'store/slices/completeCoordinatorSlice'
import useLanguage from 'hooks/useLanguage'

function CoordinatorActiveAccount() {
  const { _ } = useLingui()
  const language = useLanguage()
  const { me } = useSelector((state: RootState) => state.me)
  const dispatch = useDispatch<AppDispatch>()
  const [countryCode, setCountryCode] = useState<string | null>(null)
  const [countryId, setCountryId] = useState<string | null>(null)
  const [applicationStatus, setApplicationStatus] = useState<
    'draft' | 'pending' | 'approved' | 'rejected' | undefined
  >(undefined)
  const [showRejectModule, setShowRejectModule] = useState<boolean>(true)
  const [showSubmitModule, setShowSubmitModule] = useState<boolean>(false)
  const [regionLabelCountry, setRegionLabelCountry] = useState<string | null>(null)

  const coordinatorActiveAccountSchema = useCoordinatorActiveAccountSchema(countryCode === 'SA')
  //   const regionOptions = useRegionOptions()
  const educationStageOptions = useEducationStageOptions()
  const {
    options: educationAdministrationOptions,
    isLoading: EducationAdministrationOptionLoading
  } = useEducationAdministrationOptions({
    countryId: countryId!
  })
  const { options: regionOptions, isLoading: RegionOptionsLoading } = useRegionOptions(countryId!)
  const { options: universityOptions, isLoading: UniversityOptionsLoading } = useUniversityOptions({
    countryId: countryId!
  })
  const genderOfEducationOptions = useGenderOfEducationOptions()

  // Infer the type from the schema
  type CoordinatorActiveAccountType = z.infer<typeof coordinatorActiveAccountSchema>

  // Initialize the form
  const methods = useForm<CoordinatorActiveAccountType>({
    resolver: zodResolver(coordinatorActiveAccountSchema),
    defaultValues: {
      regions: [],
      education_stage: undefined,
      education_administration: [],
      all_regions: false,
      all_education_stages: false,
      all_education_administrations: false,
      university: undefined,
      gender_of_education: undefined,
      number_of_students: undefined,
      proof_document: undefined,
      comment: undefined,
      school_university_name: undefined,
      action: 'submit'
    }
  })

  // Extract methods for form handling
  const {
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { isSubmitting }
  } = methods
  const education_stage = watch('education_stage')
  const all_regions = watch('all_regions')
  const all_education_stage = watch('all_education_stages')
  const all_education_administrations = watch('all_education_administrations')
  const university = watch('university')
  const action = watch('action')

  const onSubmit = async (data: CoordinatorActiveAccountType) => {
    try {
      const result = await dispatch(
        completeCoordinator({
          regions: data.regions,
          education_stages: [data.education_stage],
          education_administration: data.education_administration,
          all_regions: data.all_regions === true ? 1 : 0,
          all_education_stages: data.all_education_stages === true ? 1 : 0,
          all_education_administrations: data.all_education_administrations === true ? 1 : 0,
          university: data.university === 'other' ? undefined : data.university,
          gender_of_education: data.gender_of_education,
          number_of_students: data.number_of_students,
          proof_document: data.proof_document,
          comment: data.comment,
          school_university_name: data.school_university_name,
          action: data.action
        })
      ).unwrap()
      if (result.status === 'success') {
        if (data.action === 'submit') {
          await dispatch(fetchMe()).unwrap()
          setShowSubmitModule(true)
          setValue('action', 'submit')
        } else {
          await dispatch(fetchMe()).unwrap()
          toast.success(_(msg`تم الحفظ بنجاح`))
        }
      }
    } catch (error: any) {
      setValue('action', 'submit')
      toast.error(_(msg`${error.message}`) || _(msg`هنالك خطأ`))
    }
  }

  const handleSaveDraft = async () => {
    setValue('action', 'save')
    clearErrors()
    await handleSubmit(onSubmit)()
  }

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch, reset])

  useEffect(() => {
    if (me?.data) {
      setCountryCode(me.data.country.code)
      setCountryId(String(me.data.country.id))
      if (me.data.country.region_label)
        setRegionLabelCountry(getRegionLabelFromCountry(me.data.country.region_label, language))
      setApplicationStatus(getCoordinatorStatusApplication(me.data))
    }
  }, [language, me])

  useEffect(() => {
    const updateFormDefaults = async () => {
      if (
        me?.data?.coordinator &&
        (applicationStatus === 'pending' ||
          applicationStatus === 'rejected' ||
          applicationStatus === 'draft')
      ) {
        const coordinator = me.data.coordinator

        // Map regions to their IDs
        const formattedRegions = coordinator.regions.map((region) => String(region.id))

        // Map education_administration to their IDs
        const formattedEducationAdministration = coordinator.education_administration
          ? coordinator.education_administration?.map((administration) => String(administration.id))
          : []

        const proofDocumentFile = coordinator.proof_document
          ? await fetchFileFromURL(coordinator.proof_document)
          : undefined

        // Set the form values
        reset({
          regions: formattedRegions,
          education_stage: coordinator.education_stages
            ? coordinator.education_stages[0]
            : undefined,
          education_administration: formattedEducationAdministration,
          all_regions: Boolean(coordinator.all_regions) || false,
          all_education_stages: Boolean(coordinator.all_education_stages) || false,
          all_education_administrations:
            Boolean(coordinator.all_education_administrations) || false,
          university: coordinator.university
            ? String(coordinator.university.id)
            : coordinator.school_university_name
              ? 'other'
              : undefined,
          gender_of_education: coordinator.gender_of_education
            ? coordinator.gender_of_education
            : undefined,
          number_of_students: coordinator.number_of_students
            ? String(coordinator.number_of_students)
            : undefined,
          comment: coordinator.comment ? coordinator.comment : undefined,
          school_university_name: coordinator.school_university_name
            ? coordinator.school_university_name
            : undefined,
          proof_document: proofDocumentFile || undefined,
          action: 'submit'
        })
      }
    }

    updateFormDefaults()
  }, [applicationStatus, me, reset])

  useEffect(() => {
    if (all_regions) setValue('regions', [])
    if (all_education_stage) setValue('education_stage', undefined)
    if (all_education_administrations) setValue('education_administration', [])
  }, [all_education_administrations, all_education_stage, all_regions, setValue])

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-2 items-start">
        <Typography size="xl" weight="bold">
          {_(msg`المعلومات الضرورية لتنشيط الحساب`)}
        </Typography>
        <Typography>{_(msg`المعلومات الضرورية لتنشيط الحساب`)}</Typography>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 w-full">
          <div className="flex flex-row gap-4 w-full items-center">
            <div className="w-1/3">
              <SelectInput
                isChips
                name="regions"
                selectionMode="multiple"
                isDisabled={all_regions}
                isLoading={RegionOptionsLoading}
                options={regionOptions}
                label={regionLabelCountry ? regionLabelCountry : _(msg`المنطقة`)}
                placeholder={_(msg`اختر المنطقة`)}
              />
            </div>
            <CheckboxInput
              rounded
              className="mt-4"
              removeBorder
              reverse
              name="all_regions"
              label={_(msg`جميع المناطق`)}
            />
          </div>
          <div className="flex flex-row items-center gap-4 w-full">
            <div className="w-1/3">
              <SelectInput
                name="education_stage"
                isDisabled={all_education_stage}
                options={educationStageOptions}
                label={_(msg`مرحلة التعليم`)}
                placeholder={_(msg`اختر المرحلة التعليمية`)}
              />
            </div>
            <CheckboxInput
              rounded
              removeBorder
              reverse
              className="mt-4"
              name="all_education_stages"
              label={_(msg`جميع المراحل التعلمية `)}
            />
          </div>
          {countryCode === 'SA' && education_stage === 'general' && (
            <div className="flex flex-row items-center gap-4 w-full">
              <div className="w-1/3">
                <SelectInput
                  isChips
                  name="education_administration"
                  selectionMode="multiple"
                  isDisabled={all_education_administrations}
                  isLoading={EducationAdministrationOptionLoading}
                  options={educationAdministrationOptions}
                  label={_(msg`الإدارة التعليمية`)}
                  placeholder={_(msg`اختر الادارات التعليمية`)}
                />
              </div>
              <CheckboxInput
                rounded
                removeBorder
                className="mt-3"
                reverse
                name="all_education_administrations"
                label={_(msg`جميع الادارات التعليمية `)}
              />
            </div>
          )}
          <div className="flex w-full items-center">
            <div className="w-1/3">
              <SelectInput
                name="gender_of_education"
                multiple
                options={genderOfEducationOptions}
                label={_(msg`جنس التعليم`)}
                placeholder={_(msg`اختر جنس التعليم`)}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-4 w-full">
            {education_stage === 'university' && (
              <div className="flex flex-row items-center gap-4 w-full">
                <div className="w-1/3">
                  <SelectInput
                    name="university"
                    multiple
                    isLoading={UniversityOptionsLoading}
                    options={universityOptions}
                    label={_(msg`الجامعة`)}
                    placeholder={_(msg`اختر الجامعة`)}
                  />
                </div>
                {university === 'other' && (
                  <div className="w-1/3">
                    <TextInput
                      name="school_university_name"
                      label={_(msg`يرجى كتابة اسم الجامعه`)}
                      placeholder={_(msg`يرجى كتابة اسم الجامعه`)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <TextInput
            className="w-1/3"
            name="number_of_students"
            type="number"
            label={_(msg`عدد الطلاب`)}
            placeholder={_(msg`عدد الطلاب`)}
          />
          <UploadInput name="proof_document" placeholder={_(msg`حجم الملف الاقصى 3 ميغا`)} />
          <TextareaInput
            name="comment"
            maxLength={200}
            minRows={5}
            label={_(msg`اضافة تعليق `)}
            placeholder={_(msg`اضافة تعليق `)}
          />
          <div className="flex justify-end gap-4 flex-row items-center w-full">
            <ButtonVariant
              type="button"
              isLoading={isSubmitting && action !== 'submit'}
              isDisabled={(isSubmitting && action !== 'submit') || applicationStatus === 'pending'}
              variant="bordered"
              className="border-success border-2 font-bold rounded-full text-success bg-transparent"
              onClick={handleSaveDraft}
            >
              {_(msg`حفظ كمسودة`)}
            </ButtonVariant>
            <ButtonVariant
              type="submit"
              variant="secondary"
              isLoading={isSubmitting && action !== 'save'}
              isDisabled={(isSubmitting && action !== 'save') || applicationStatus === 'pending'}
              endContent={<ArrowLeft />}
            >
              {_(msg`طلب تفعيل الحساب `)}
            </ButtonVariant>
          </div>
        </form>
      </FormProvider>
      {applicationStatus === 'pending' && <PendingApplicationModule />}
      {showRejectModule && applicationStatus === 'rejected' && (
        <RejectApplicationModule onClose={() => setShowRejectModule(false)} />
      )}
      {showSubmitModule && <SubmitApplicationModule />}
    </div>
  )
}

export default CoordinatorActiveAccount
