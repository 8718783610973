import { FC, ReactNode } from 'react'
import { Pagination, Spinner } from '@nextui-org/react'

interface DataCardsProps {
  data: any[] // Array of data items to be displayed in cards
  isLoading: boolean // Loading state
  page: number // Current page number
  per_page: number // Number of items per page
  total_pages: number // Total number of pages
  onPageChange: (page: number) => void // Function to handle page change
  renderCard: (item: any) => ReactNode // Function to render the entire card layout
}

const DataCards: FC<DataCardsProps> = ({
  data,
  isLoading,
  page,
  total_pages,
  onPageChange,
  renderCard
}) => {
  return (
    <div className="flex flex-col min-h-[60vh]">
      <div className="grid grid-cols-4 gap-8">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner size="lg" />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, index) => (
            <div key={index}>{renderCard(item)}</div> // Render the card layout
          ))
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <p>No data available</p>
          </div>
        )}
      </div>
      <div className="mt-auto flex justify-end w-full">
        <Pagination
          showControls
          loop
          total={total_pages}
          initialPage={page}
          onChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default DataCards
