import { z } from 'zod'
import { msg } from '@lingui/macro'

const validationSchema = z
  .object({
    password: z
      .string()
      .trim()
      .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
      .min(6, { message: msg`يجب أن تتكون كلمة المرور من 6 أحرف على الأقل`.id })
      .refine((string) => !/^[A-Za-z0-9]+$|^[a-z0-9]+$|^[A-Z0-9]+$|^[\W_]+$/.test(string), {
        message:msg`كلمه السر ليست قوية بما فيه الكفاية. يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام ورموز`.id}),

    confirm_password: z
      .string()
      .trim()
      .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })})

  .refine((data) => data.password === data.confirm_password, {
    message: msg`كلمة المرور وتأكيد كلمة المرور غير متطابقين`.id,
    path: ['confirm_password'] 
  })

export default validationSchema
