import { FC } from 'react'

interface CheckboxVariantProps {
  label: string
  checked: boolean
  onChange: () => void
  className?: string
  removeBorder?: boolean
  reverse?: boolean
  rounded?: boolean // New prop
}

const CheckboxVariant: FC<CheckboxVariantProps> = ({
  label,
  checked,
  onChange,
  className,
  removeBorder,
  reverse,
  rounded = false // Default to false (square checkbox)
}) => {
  return (
    <div
      className={`flex ${reverse && 'flex-row-reverse gap-4'} items-center justify-between ${!removeBorder && 'border-b border-gray-300'} py-2 ${className}`}
    >
      <span className="ml-2 text-gray-700 font-bold">{label}</span>
      {rounded ? (
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          className="appearance-none w-6 h-6 bg-primary_lv1_text_normal rounded-full border-2 border-natural_stroke_normal_disabled checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary"
        />
      ) : (
        <input
          type="checkbox"
          className={`form-checkbox h-5 w-5 text-blue-600`}
          onChange={onChange}
          checked={checked}
        />
      )}
    </div>
  )
}

export default CheckboxVariant
