import { useState } from 'react'
import PathCard from './path-card'
import pathList from './path-list'
import { Path } from 'pages/landing/models'
import SectionTitle from 'components/base/section-title'
import { msg } from '@lingui/macro'

const PathsSection = () => {
  const [activeCard, setActiveCard] = useState<number>(1)

  return (
    <div className="md:pt-20 md:pb-28 px-3.5 md:px-0">
      <div className="md:w-10/12 mx-auto flex flex-col space-y-7 md:space-y-[51px]">
        <SectionTitle
          title={msg`مسارات أقرأ`}
          sectionDescription={msg`اختر مسارك الآن في مسابقة أقرأ`}
        />
        <div className="flex flex-col md:flex-row-reverse space-y-3.5 space-y md:space-y-0 md:*:mx-1.5 ">
          {pathList.map((path: Path, index: number) => (
            <PathCard
              key={path.title.id}
              path={path}
              isActive={path.id === activeCard}
              handleSetActive={setActiveCard}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PathsSection
