import React from 'react';

interface SearchIconProps {
  fill: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_2227)">
        <path
          d="M15.0257 13.8473L18.5948 17.4157L17.4157 18.5948L13.8473 15.0257C12.5196 16.09 10.8682 16.6689 9.1665 16.6665C5.0265 16.6665 1.6665 13.3065 1.6665 9.1665C1.6665 5.0265 5.0265 1.6665 9.1665 1.6665C13.3065 1.6665 16.6665 5.0265 16.6665 9.1665C16.6689 10.8682 16.09 12.5196 15.0257 13.8473ZM13.354 13.229C14.4116 12.1414 15.0022 10.6835 14.9998 9.1665C14.9998 5.94317 12.389 3.33317 9.1665 3.33317C5.94317 3.33317 3.33317 5.94317 3.33317 9.1665C3.33317 12.389 5.94317 14.9998 9.1665 14.9998C10.6835 15.0022 12.1414 14.4116 13.229 13.354L13.354 13.229V13.229Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_8_2227">
          <rect width="20" height="20" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
