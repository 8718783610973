import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AccountProfileActiveTabType = 'personalInfo' | 'email' | 'password'

type TabsState = {
  activeTab: AccountProfileActiveTabType
}

const initialState: TabsState = {
  activeTab: 'personalInfo'
}

const accountProfileTabsSlice = createSlice({
  name: 'accountProfileTabs',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<AccountProfileActiveTabType>) => {
      state.activeTab = action.payload
    }
  }
})

export const { setActiveTab } = accountProfileTabsSlice.actions
export default accountProfileTabsSlice
