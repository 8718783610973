import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CreateWorkflowResponse } from 'api/types'

interface UpdateWorkflowState {
  isLoading: boolean
  error: string | null
}

const initialState: UpdateWorkflowState = {
  isLoading: false,
  error: null
}

export const updateWorkflow = createAsyncThunk(
  'updateWorkflow',
  async (
    {
      competitionId,
      workflowId,
      name,
      description,
      end_date,
      status
    }: {
      competitionId: number
      workflowId: number
      name: string
      description: string
      end_date: string
      status: 'active' | 'archived'
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.put<CreateWorkflowResponse>(
        `/management/competition-years/${competitionId}/workflows/${workflowId}`,
        {
          name,
          description,
          end_date,
          status
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const updateWorkflowSlice = createSlice({
  name: 'updateWorkflow',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateWorkflow.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateWorkflow.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateWorkflow.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default updateWorkflowSlice
