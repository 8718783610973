import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'
import SearchInput from 'components/search-field/search-field'
import { ChangeEvent, useState } from 'react'
import RadioInputSimple from '../supplementary-files/radio-input-simple'
import SupplementaryFilesData from '../supplementary-files/category-list'

interface SupplementaryNavbarProps {
  setSelectedCategory: (category: string) => void
}

const SupplementaryNavbar: React.FC<SupplementaryNavbarProps> = ({ setSelectedCategory }) => {
  const [searchValue, setSearchValue] = useState('')
  const [radioValue, setRadioValue] = useState('-1')

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleRadioChange = (newValue: string) => {
    setRadioValue(newValue)
    setSelectedCategory(newValue)
  }

  const radioOptions = [
    { value: '-1', label: msg`الكل`.id }, // All
    ...SupplementaryFilesData.map((item) => ({
      value: String(item.category?.id),
      label: item.category?.name.id || ''
    }))
  ]

  return (
    <div
      id="file-navbar"
      className="py-12 flex flex-col space-y-5 w-full md:w-10/12 mx-auto px-3.5 md:px-0"
    >
      <SearchInput
        placeholder={msg`ابحث من هنا`.id}
        value={searchValue}
        onChange={handleSearchChange}
      />
      <div className="md:overflow-visible overflow-x-auto scrollbar-hide touch-pan-x">
        <div className="md:flex md:justify-start flex justify-center space-x-4 w-max md:w-full mx-auto">
          <RadioInputSimple
            name="category"
            options={radioOptions}
            value={radioValue}
            onChange={handleRadioChange}
            className="flex-1 min-w-[300px]"
          />
        </div>
      </div>
    </div>
  )
}

export default SupplementaryNavbar
