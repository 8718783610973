import React from 'react'
import backgroundImage from 'assets/images/debate.png'
import YouTubeVideo from 'components/base/youtube-video'
import { Trans } from '@lingui/macro'

const DebateCard: React.FC = () => {
  return (
    <div className="flex flex-row items-center md:flex-col p-4 w-full rounded-xl hover:bg-customTeal10">
      <div className="relative w-1/2 md:w-full">
        <YouTubeVideo videoId="" fallbackImg={backgroundImage} altText="Marathon" />
      </div>
      <div className=" w-1/2  md:w-full md:mt-4 mx-4 md:mx-0">
        <h3 className="text-teal-600 text-lg font-semibold">
          <Trans>ماراثون أقرأ ب'إثراء'</Trans>
        </h3>
        <p className="text-naturalLv2 md:mt-2">
          <Trans>في مسابقة أقرأ من إثراء 2024 Mar 22</Trans>
        </p>
        <p className="text-naturalLv2 md:mt-2 font-medium text-base line-clamp-1 md:line-clamp-none">
          <Trans>
            لساعات متواصلة عبر ثلاث أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز
            الثقافي العالمي (إثراء) تفاعلًا
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default DebateCard
