import { FormProvider, useForm } from 'react-hook-form'
import { useUpdateEmailSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import TextInput from 'components/hook-form/text-input'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import ButtonVariant from 'components/button-variant/button'
import { updateEmail } from 'store/slices/updateEmailSlice'
import { toast } from 'react-toastify'
import { fetchMe } from 'store/slices/meSlice'
import { setNewEmail } from 'store/slices/newEmailSlice'
import {
  closeUpdateEmailOTPModal,
  openUpdateEmailOTPModal
} from 'store/slices/updateEmailOTPModalSlice'
import Alert from 'components/alert/alert'
import OtpModalRestEmail from './otp-modal/otp-modal-rest-email'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import useLanguage from 'hooks/useLanguage'

function RestEmail() {
  const { _ } = useLingui()
  const language = useLanguage()
  const { me } = useSelector((state: RootState) => state.me)
  const { isUpdateEmailOTPModalOpen } = useSelector((state: RootState) => state.updateEmailOTPModal)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const restEmailSchema = useUpdateEmailSchema()

  // Infer the type from the schema
  type RestEmailType = z.infer<typeof restEmailSchema>

  // Initialize the form
  const methods = useForm<RestEmailType>({
    resolver: zodResolver(restEmailSchema),
    defaultValues: {
      email: undefined,
      new_email: undefined,
      confirm_email: undefined
    }
  })

  const [showEmailFields, setShowEmailFields] = useState(false) // Toggle state for email fields

  // Extract methods for form handling
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting }
  } = methods

  const onSubmit = async (data: RestEmailType) => {
    try {
      const result = await dispatch(
        updateEmail({
          email: data.new_email
        })
      ).unwrap()
      if (result.status === 'success') {
        dispatch(setNewEmail(data.new_email))
        dispatch(openUpdateEmailOTPModal())
      }
    } catch (error: any) {
      toast.error(_(msg`${error.message}`) || _(msg`هنالك خطأ`))
    }
  }

  const emailUpdatedMessage = () => {
    const oldEmail = localStorage.getItem('email')
    const newEmail = watch('email')

    if (language === 'ar') {
      return `نود إعلامك بأنه تم تحديث ${oldEmail} بنجاح الى ${newEmail}`
    } else {
      return `We would like to inform you that ${oldEmail} has been successfully updated to ${newEmail}`
    }
  }

  useEffect(() => {
    if (me?.data) setValue('email', me.data.email)
  }, [me, setValue])

  const onSuccessCallback = async () => {
    try {
      await dispatch(fetchMe()).unwrap()

      // Close the modal and reset the email fields after successful fetch
      dispatch(closeUpdateEmailOTPModal())
      setValue('new_email', '')
      setValue('confirm_email', '')
      setShowEmailFields(false)
      setShowAlert(true)
    } catch (error) {
      toast.error('Failed to fetch user data')
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {showAlert && (
          <Alert
            closable
            mainText={emailUpdatedMessage()}
            sizeMainText="md"
            weightMainText="normal"
            onClose={() => setShowAlert(false)}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row gap-4 items-end">
            <div className="w-72">
              <TextInput
                size="lg"
                label={_(msg`البريد الالكتروني الحالي `)}
                name="email"
                type="email"
                isDisabled
                classNames={{
                  inputWrapper:
                    'bg-natural_fill_disabled border border-natural_stroke_normal_disabled'
                }}
              />
            </div>
            {!showEmailFields && (
              <ButtonVariant
                type="button"
                variant="secondary"
                onPress={() => setShowEmailFields(true)}
              >
                {_(msg`تحديث`)}
              </ButtonVariant>
            )}
          </div>

          {/* Conditionally render New Email and Confirm Email fields */}
          {showEmailFields && (
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12 w-2/3 my-5">
                <TextInput
                  size="lg"
                  label={_(msg`البريد الالكتروني الجديد `)}
                  name="new_email"
                  type="email"
                />
                <TextInput
                  size="lg"
                  label={_(msg`تأكيد البريد الالكتروني الجديد`)}
                  name="confirm_email"
                  type="email"
                />
              </div>

              <div className="flex w-full justify-end">
                <ButtonVariant
                  type="submit"
                  variant="secondary"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  {_(msg`تحديث`)}
                </ButtonVariant>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
      {isUpdateEmailOTPModalOpen && <OtpModalRestEmail onSuccessCallback={onSuccessCallback} />}
    </>
  )
}

export default RestEmail
