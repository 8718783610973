import { Trans } from '@lingui/macro'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import React from 'react'
import { Link } from 'react-router-dom'

interface PointsCardprops {
  points: number
}

const PointsCard: React.FC<PointsCardprops> = ({ points }) => {
  return (
    <div className="flex flex-col justify-between items-center">

      <p className="font-heading-h6-ar text-natural_icon_normal"><Trans>النقاط المكتسبة</Trans></p>
      <h2 className="font-sub-heading-h2 text-natural_lv1_text_normal">{points}</h2>

      <Link
        to={'/app/points'}
        className={`p-3 gap-3 rounded-md flex items-center transition-all duration-300`}
      >
        <p className='font-heading-h6 text-secondary_fill_normal_pressed'><Trans>النقاط و الجوائز</Trans></p>
        <ArrowLeft fill='#7ECD33' />
      </Link>
    </div>
  )
}

export default PointsCard
