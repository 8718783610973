import ClipboardIcon from 'assets/icons/ClipboardIcon'
import ButtonVariant from 'components/button-variant/button'
import React from 'react'

interface CodeCardprops {
  code: string
  layout?: string
}

const CodeCard: React.FC<CodeCardprops> = ({ code, layout = 'col' }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code)
  }

  return (
    <div className={`size-full flex ${layout === 'col'? 'flex-col' : 'flex-row'} justify-end items-center gap-2.5`}>
        
      <div className="w-full max-w-[178px] p-2 flex items-center justify-center border-2 border-x-natural_lv1_text_normal bg-primary_fill_soft_overlay rounded-lg">
        <p className=" text-natural_icon_normal font-heading-h6">{code}</p>
      </div>

      <ButtonVariant
        variant="secondary"
        iconStart={<ClipboardIcon />}
        isIconOnly
        onClick={copyToClipboard}
      />

    </div>
  )
}

export default CodeCard
