const FilterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_192_7846)">
        <path
          d="M14 14V20L10 22V14L4 5V3H20V5L14 14ZM6.404 5L12 13.394L17.596 5H6.404Z"
          fill="#13100D"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_7846">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FilterIcon
