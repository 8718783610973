import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CountriesResponse, Country } from 'api/types'

interface RegisterState {
  countries: Country[]
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  countries: [],
  isLoading: false,
  error: null
}

export const fetchCountries = createAsyncThunk('countries', async () => {
  const response = await axiosInstance.get<CountriesResponse>('/geo/countries')
  return response.data
})

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.isLoading = false
        const countries = action.payload.data ?? []
        state.countries = countries
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default countriesSlice
