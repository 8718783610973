import { useState } from 'react'
import StageCard from './stage-card'
import stagesList from './stages-list'
import SectionTitle from 'components/base/section-title'

import { msg } from '@lingui/macro'
import StageImage from './stage-image'

const StagesSection: React.FC = () => {
  const [activeCard, setActiveCard] = useState<number>(stagesList[0].id)
  const [prevActiveCard, setPrevActiveCard] = useState<number | null>(null)

  const handleSetActive = (id: number) => {
    setPrevActiveCard(activeCard)
    setActiveCard(id)
  }

  return (
    <div className="relative md:w-10/12 mx-auto py-4 md:pt-4 md:pb-20 px-3.5 md:px-0">
      <div className="hidden md:block md:absolute top-0 md:w-[50%] md:h-[95%] rounded-[20px] overflow-hidden end-0 -z-10">
        <StageImage activeCard={activeCard} prevActiveCard={prevActiveCard} />
      </div>
      <div className="flex flex-col space-y-6 md:space-y-[54px]">
        <SectionTitle
          title={msg`مراحل المسابقة`}
          sectionDescription={msg`للتعرف على مراحل المنافسة`}
        />
        <div className="flex flex-col space-y-6 w-full md:w-3/5">
          {stagesList.map((stage) => (
            <div key={stage.id}>
              <StageCard
                stage={stage}
                isActive={stage.id === activeCard}
                handleSetActive={() => handleSetActive(stage.id)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StagesSection
