import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ResendVerificationNewEmailResponse } from 'api/types'

interface ResendVerificationNewEmailState {
  isLoading: boolean
  error: string | null
  email: string
}

const initialState: ResendVerificationNewEmailState = {
  isLoading: false,
  error: null,
  email: ''
}

export const resendVerificationNewEmail = createAsyncThunk(
  'resendVerificationNewEmail',
  async (
    {
      email
    }: {
      email: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<ResendVerificationNewEmailResponse>(
        '/auth/profile/new-email/otp/resend',
        {
          email: email
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const resendVerificationNewEmailSlice = createSlice({
  name: 'resendVerificationNewEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resendVerificationNewEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(resendVerificationNewEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resendVerificationNewEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default resendVerificationNewEmailSlice
