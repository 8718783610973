import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { SchoolsResponse, School } from 'api/types'

interface RegisterState {
  schools: School[]
  isLoading: boolean
  error: string | null
  regionsIds: string[]
}

const initialState: RegisterState = {
  schools: [],
  isLoading: false,
  error: null,
  regionsIds: []
}

export const fetchSchools = createAsyncThunk(
  'schools',
  async (params: { regionsIds?: string[]; countryId?: string }) => {
    const { regionsIds = [], countryId } = params

    const paramsObj = {
      regions: regionsIds,
      country_id: countryId
    }

    const response = await axiosInstance.get<SchoolsResponse>('/schools', {
      params: {
        ...paramsObj
      }
    })
    return response.data
  }
)

const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    setRegionsIds(state, action: PayloadAction<string[]>) {
      state.regionsIds = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSchools.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchSchools.fulfilled, (state, action) => {
        state.isLoading = false
        const schools = action.payload.data ?? []
        state.schools = schools
      })
      .addCase(fetchSchools.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setRegionsIds } = schoolsSlice.actions

export default schoolsSlice
