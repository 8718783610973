interface GenderOfEducationOption {
  value: string
  labelEn: string
  labelAr: string
}

export const genderOfEducationOptions: GenderOfEducationOption[] = [
  { value: 'male', labelEn: 'Male', labelAr: 'ذكر' },
  { value: 'female', labelEn: 'Female', labelAr: 'أنثى' },
  { value: 'both', labelEn: 'Both', labelAr: 'كلاهما' }
]

type Language = 'en' | 'ar'

export const getGenderOfEducationOptions = (lang: Language) => {
  return genderOfEducationOptions.map((option) => ({
    value: option.value,
    label: lang === 'en' ? option.labelEn : option.labelAr
  }))
}

export const getGenderOfEducationLabel = (value: string, lang: Language) => {
  const option = genderOfEducationOptions.find((option) => option.value === value)
  return option ? (lang === 'en' ? option.labelEn : option.labelAr) : ''
}

export const getGenderOfEducationValue = (value: string) => {
  const option = genderOfEducationOptions.find((option) => option.value === value)
  return option?.value ?? ''
}
