import React from 'react'
import backgroundImage from 'assets/images/dialogue.png'
import { Trans } from '@lingui/macro'

const DialogueCard: React.FC = () => {
  return (
    <div className="group p-3 md:p-6 flex flex-row items-center rounded-xl hover:bg-customTeal10">
      <div className="w-1/4 md:mx-3 md:w-1/3 transform transition-transform duration-300 group-hover:scale-105">
        <img src={backgroundImage} alt="Marathon" className="rounded-lg" />
      </div>
      <div className="w-3/4 md:w-2/3 mx-3">
        <h3 className="text-teal-600 text-lg md:text-3xl font-semibold">
          <Trans>ماراثون أقرأ ب'إثراء'</Trans>
        </h3>
        <p className="text-gray-500 md:text-xl mt-2 line-clamp-1 md:line-clamp-none">
          <Trans>
            لساعات متواصلة عبر ثلاث أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز
            الثقافي العالمي (إثراء) تفاعلًا{' '}
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default DialogueCard
