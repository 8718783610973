import React, { useState, forwardRef } from 'react'
import { Input, InputProps } from '@nextui-org/react'
import { useController, useFormContext } from 'react-hook-form'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import './phone-input.css'
import EyeOffIcon from 'assets/icons/EyeOffIcon'
import EyeIcon from 'assets/icons/EyeIcon'
import { Trans } from '@lingui/react'
import { i18n } from '@lingui/core'

type InputFieldProps = InputProps & {
  label?: string
  helperText?: React.ReactNode
  error?: string | null
  type?: string
  invisible?: boolean
  placeholder?: string
  isDisabled?: boolean
  isReadOnly?: boolean
  disabled?: boolean
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    { label, helperText, error, type = 'text', invisible = false, placeholder, disabled, ...other },
    ref
  ) => {
    const { control } = useFormContext()
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => setIsVisible(!isVisible)

    const isPasswordType = type === 'password'
    const isPhoneNumberType = type === 'phone-number'

    const { field } = useController({
      name: other.name!,
      control,
      rules: { required: 'Phone number is required' }
    })

    if (invisible) {
      return null
    }

    return (
      <div className="flex flex-col justify-start gap-2">
        {label && (
          <p className="font-sub-heading-h6-ar text-natural_icon_normal capitalize">
            <Trans id={label} />
          </p>
        )}
        {isPhoneNumberType ? (
          <PhoneInput
            ref={ref as any} // Ref forwarding
            placeholder="000000000"
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            className={`w-full font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal ${error ? 'border-danger_default_stroke_normal' : ''}`}
            style={{
              direction: 'ltr',
              width: '100%'
            }}
            defaultCountry="sa"
          />
        ) : (
          <Input
            isDisabled={disabled}
            ref={ref} // Ref forwarding
            aria-label={label || 'input component'}
            placeholder={i18n._(placeholder || '')}
            color="primary"
            variant="bordered"
            type={isPasswordType && isVisible ? 'text' : type}
            radius="sm"
            fullWidth
            className={`w-full rounded-lg font-sub-heading-h6-ar text-natural_icon_normal placeholder:text-primary_lv2_text_normal ${error ? 'border-danger_default_stroke_normal' : ''} ${disabled ? 'bg-natural_fill_disabled border-natural_stroke_normal_disabled border-2' : ''}`}
            helperText={error || helperText}
            endContent={
              isPasswordType && (
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                  aria-label="toggle password visibility"
                >
                  {isVisible ? <EyeOffIcon /> : <EyeIcon />}
                </button>
              )
            }
            {...other}
          />
        )}
        {error && (
          <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
            <Trans id={error} />
          </p>
        )}
      </div>
    )
  }
)

export default InputField
