import React from 'react'

interface ArrowIconProps {
  fill: string
  width: number | string
  height: number | string
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7007_72277)">
        <path
          d="M33.6891 27.0823L8.33073 27.0823V22.9156L33.6891 22.9156L22.5141 11.7406L25.4599 8.79479L41.6641 24.999L25.4599 41.2031L22.5141 38.2573L33.6891 27.0823Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7007_72277">
          <rect
            width="50"
            height="50"
            fill={fill}
            transform="matrix(-1 0 0 -1 50 50)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowIcon
