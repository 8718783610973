import { combineReducers } from 'redux'
import signInSlice from 'store/slices/signInSlice'
import layoutSlice from 'store/slices/layoutSlice'
import authSlice from 'store/slices/authSlice'
import registerSlice from 'store/slices/registerSlice'
import nationalitiesSlice from 'store/slices/nationalitiesSlice'
import verifyEmailSlice from 'store/slices/verifyEmailSlice'
import resendVerificationEmailSlice from 'store/slices/resendVerificationEmailSlice'
import verifyOTPSlice from 'store/slices/verifyOTPSlice'
import resendOTPSlice from 'store/slices/resendOTPSlice'
import forgotPasswordSlice from 'store/slices/forgotPasswordSlice'
import resetPasswordSlice from 'store/slices/resetPasswordSlice'
import regionsSlice from 'store/slices/regionsSlice'
import educationAdministrationsSlice from 'store/slices/educationAdministrationsSlice'
import schoolsSlice from 'store/slices/schoolsSlice'
import universitiesSlice from 'store/slices/universitiesSlice'
import completeCoordinatorSlice from 'store/slices/completeCoordinatorSlice'
import meSlice from 'store/slices/meSlice'
import verifyEmailHashSlice from 'store/slices/verifyEmailHashSlice'
import activateRequestsSlice from 'store/slices/activateRequestsSlice'
import ithraUsersSlice from 'store/slices/ithraUsersSlice'
import activateRequestDetailSlice from 'store/slices/activateRequestDetailSlice'
import activateRequestSlice from 'store/slices/activateRequestSlice'
import countriesSlice from 'store/slices/countriesSlice'
import educationLevelsSlice from 'store/slices/educationLevelsSlice'
import updateProfileSlice from 'store/slices/updateProfileSlice'
import accountProfileTabsSlice from 'store/slices/accountProfileTabsSlice'
import updateEmailSlice from 'store/slices/updateEmailSlice'
import updateEmailOTPModalSlice from 'store/slices/updateEmailOTPModalSlice'
import verifyNewEmailSlice from 'store/slices/verifyNewEmailSlice'
import resendVerificationNewEmailSlice from 'store/slices/resendVerificationNewEmailSlice'
import updatePasswordSlice from 'store/slices/updatePasswordSlice'
import newEmailSlice from 'store/slices/newEmailSlice'
import competitionYearSlice from 'store/slices/competitionYearSlice'
import logoutSlice from 'store/slices/logoutSlice'
import createCompetitionSlice from './slices/createCompoetitionSlice'
import competitionYearDetailsSlice from './slices/competitionYearDetails'
import updateCompetitionSlice from './slices/updateCompetitionYear'
import activateRequestsTabsSlice from './slices/activateRequestsTabsSlice'
import workflowSlice from './slices/workFlowSlice'
import createWorkflowSlice from './slices/createWorkflowSlice'
import workflowDetailSlice from './slices/workflowDetailsSlice'
import updateWorkflowSlice from './slices/updateWorkflowSlice'
import deleteWorkflowSlice from './slices/deleteWorkflowSlice'
import changeEmailSlice from 'store/slices/changeEmailSlice'

export const rootReducer = combineReducers({
  layout: layoutSlice.reducer,
  auth: authSlice.reducer,
  register: registerSlice.reducer,
  nationalities: nationalitiesSlice.reducer,
  verifyEmail: verifyEmailSlice.reducer,
  resendVerificationEmail: resendVerificationEmailSlice.reducer,
  signIn: signInSlice.reducer,
  verifyOTP: verifyOTPSlice.reducer,
  resendOTP: resendOTPSlice.reducer,
  forgotPassword: forgotPasswordSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  regions: regionsSlice.reducer,
  educationAdministrations: educationAdministrationsSlice.reducer,
  schools: schoolsSlice.reducer,
  universities: universitiesSlice.reducer,
  completeCoordinator: completeCoordinatorSlice.reducer,
  me: meSlice.reducer,
  verifyEmailHash: verifyEmailHashSlice.reducer,
  activateRequests: activateRequestsSlice.reducer,
  ithraUsers: ithraUsersSlice.reducer,
  activateRequestDetail: activateRequestDetailSlice.reducer,
  activateRequest: activateRequestSlice.reducer,
  countries: countriesSlice.reducer,
  educationLevels: educationLevelsSlice.reducer,
  updateProfile: updateProfileSlice.reducer,
  accountProfileTabs: accountProfileTabsSlice.reducer,
  updateEmail: updateEmailSlice.reducer,
  updateEmailOTPModal: updateEmailOTPModalSlice.reducer,
  verifyNewEmail: verifyNewEmailSlice.reducer,
  resendVerificationNewEmail: resendVerificationNewEmailSlice.reducer,
  updatePassword: updatePasswordSlice.reducer,
  newEmail: newEmailSlice.reducer,
  competitionYear: competitionYearSlice.reducer,
  logout: logoutSlice.reducer,
  createCompetition: createCompetitionSlice.reducer,
  competitionYearDetails: competitionYearDetailsSlice.reducer,
  updateCompetitionYear: updateCompetitionSlice.reducer,
  activateRequestsTabs: activateRequestsTabsSlice.reducer,
  workflows: workflowSlice.reducer,
  createWorkflow: createWorkflowSlice.reducer,
  workflowDetails: workflowDetailSlice.reducer,
  updateWorkflow: updateWorkflowSlice.reducer,
  deleteWorkflow: deleteWorkflowSlice.reducer,
  changeEmail: changeEmailSlice.reducer,
})
