import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import confirmationIcon from 'assets/icons/confirmationIcon.svg'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'

const ConfirmationModal: React.FC = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/login')
  }

  return (
    <ModalLayout
      onBack={handleBack}
      accountPageStyle
      body={
        <div className="flex flex-col items-center">
          <div className="w-20 h-20">
            <img src={confirmationIcon} alt="confirmationIcon" />
          </div>
          <ModalHeader
            headerText={msg`تم إرسال رسالة الى بريدك الإلكتروني `}
            subheaderText={
              <>
                <Trans>تم إرسال رابط لإعادة تعيين كلمة المرور الخاصة بك لبريدك الالكتروني</Trans>
                <span className="text-primary_lv2_text_normal px-2">{localStorage.getItem('email')}</span>
              </>
            }
          />
        </div>
      }
    />
  )
}

export default ConfirmationModal
