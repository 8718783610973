import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setStudentsNumberTo } from 'store/slices/activateRequestsSlice'
import './NumberOfStudentsToFilter.css'

const NumberOfStudentsToFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { studentsNumberTo } = useSelector((state: RootState) => state.activateRequests)
  const [numberTo, setNumberTo] = useState<number | null>(studentsNumberTo)

  const handleNumberToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null
    setNumberTo(value)
    // dispatch(setStudentsNumberTo(value))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setStudentsNumberTo(numberTo))
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, numberTo])

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('NUMBER_OF_STUDENTS_TO_FILTER')}</p>
      <input
        type="number"
        placeholder={t('NUMBER_OF_STUDENTS_TO_FILTER')}
        // value={studentsNumberTo || ''}
        value={numberTo || ''}
        onChange={handleNumberToChange}
        className="custom-input"
      />
    </div>
  )
}

export default NumberOfStudentsToFilter
