import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { VerifyEmailResponse } from 'api/types'

interface VerifyEmailState {
  isLoading: boolean
  error: string | null
}

const initialState: VerifyEmailState = {
  isLoading: false,
  error: null
}

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  async (
    {
      code
    }: {
      code: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<VerifyEmailResponse>('/auth/verify-email', {
        code: code,
        guard: 'users'
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default verifyEmailSlice
