import React, { useState, useEffect } from 'react'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { useDispatch } from 'react-redux'
import { changeEmail } from 'store/slices/changeEmailSlice'
import { toast } from 'react-toastify'
import { AppDispatch } from 'store'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'

interface changeEmailProps {
  handleEmailModalDisplay: (shouldShow: boolean) => void
  handleOTPModalDisplay: (shouldShow: boolean) => void
}

const ChangeEmail: React.FC<changeEmailProps> = ({ handleEmailModalDisplay, handleOTPModalDisplay }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const [currentEmail, setCurrentEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState<string>('')

  const defaultValues = {
    current_email: '',
    new_email: ''
  }

  useEffect(() => {
    const email = localStorage.getItem('email') || ''
    setCurrentEmail(email)
  }, [])

  const handleSubmitSuccess = (result: any) => {
    // console.log("change email form", localStorage)
    if (result.status === 'success') {
      toast.success(i18n._(result.message) || i18n._(msg`تم تغيير البريد الإلكتروني بنجاح!`))
      handleEmailModalDisplay(false)
      handleOTPModalDisplay(true)
    } else {
      throw new Error(result.message || i18n._(msg`فشلت عملية تغيير البريد الإلكتروني.`))
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    // console.log(data)
    setErrorMsg('')
    setIsLoading(true)
    try {
      const result = await dispatch(
        changeEmail({
          email: data.new_email
        })
      ).unwrap()

      handleSubmitSuccess(result)
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message) || i18n._(msg`حدث خطأ أثناء تغيير البريد الإلكتروني.`))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}
      <TextInput
        name="current_email"
        label={msg`البريد الالكتروني الحالي`.id}
        type="email"
        value={currentEmail}
        disabled={true}
      />
      <TextInput
        name="new_email"
        label={msg`البريد الالكتروني الجديد`.id}
        type="email"
        placeholder={msg`Exmple@gmail.com`.id}
        helperText={msg`أدخل بريدك الإلكتروني الجديد`.id}
      />
      
      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
          isLoading={isLoading}
          disabled={isLoading}
        >
          <p><Trans>تأكيد</Trans></p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default ChangeEmail
