import { FC } from 'react'
import { RadioGroup, RadioGroupProps } from '@nextui-org/react'
import clsx from 'clsx'
import Typography from 'components/typography/typography'
import { Trans } from '@lingui/react'

interface RadioOption {
  value: string
  label: string
}

interface RadioInputProps extends Omit<RadioGroupProps, 'onChange'> {
  name: string
  options: RadioOption[]
  label?: string
  helperText?: string
  value: string
  onChange: (value: string) => void
}

const RadioInputSimple: FC<RadioInputProps> = ({
  name,
  options,
  label,
  helperText,
  onChange,
  value,
  ...other
}) => {
  return (
    <div className="flex flex-col gap-2">
      {label && (
        <Typography align="right" weight="medium" size="sm">
          <Trans id={label} />
        </Typography>
      )}
      <RadioGroup
        orientation="horizontal"
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        {...other}
        className="flex flex-row gap-2"
      >
        {options.map((option) => (
          <label
            key={option.value}
            className={clsx(
              'px-4 py-2 border rounded-full text-center cursor-pointer',
              value === option.value
                ? 'bg-primary text-white border-primary hover:bg-primary'
                : ' hover:bg-secondary_fill_hover bg-secondary_fill_active text-danger_inverted_lv1_text_normal border-secondary_fill_normal_pressed',
              'transition',
              'select-none'
            )}
          >
            <input
              type="radio"
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              className="hidden"
            />
            <Trans id={option.label} />
          </label>
        ))}
      </RadioGroup>
      {helperText && <Typography className="text-gray-500 text-sm">{helperText}</Typography>}
    </div>
  )
}

export default RadioInputSimple
