import { Speaker } from 'pages/landing/models'
import img1 from 'assets/images/speaker-1.jpg'
import img2 from 'assets/images/speaker-2.jpg'
import img3 from 'assets/images/speaker-3.jpg'
import { msg } from '@lingui/macro'

const speakersList: Speaker[] = [
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img3
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img3
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img3
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img1
  },
  {
    name: msg`بلقيس الصولان`,
    country: msg`السعودية`,
    avatar: img2
  }
]

export default speakersList
