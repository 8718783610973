import { FC, useState, ChangeEvent } from 'react'
import { Textarea, TextAreaProps } from '@nextui-org/react'
import Typography from 'components/typography/typography'

type TextareaFieldProps = TextAreaProps & {
  label?: string
  helperText?: React.ReactNode
  error?: string | null
  maxLength?: number
  maxRows?: number
}

const TextareaField: FC<TextareaFieldProps> = ({
  label,
  helperText,
  error,
  maxLength = 200, // Set a default maxLength
  maxRows = 5, // Default to 5 rows
  ...other
}) => {
  const [currentLength, setCurrentLength] = useState(0)

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueLength = e.target.value.length
    setCurrentLength(valueLength)
    if (other.onChange) {
      other.onChange(e as any) // Ensure the onChange function passed as a prop is called
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <Typography align="right" weight="medium" size="sm">
          {label}
        </Typography>
      )}
      <Textarea
        aria-label="textarea component"
        color="primary"
        variant="bordered"
        fullWidth
        className={`${error ? 'border-red-500' : ''}`}
        helperText={error || helperText}
        maxLength={maxLength}
        maxRows={maxRows}
        {...other}
        onChange={handleChange} // Use the new handleChange function
      />
      <div className="flex justify-end items-center gap-4 mt-1">
        <Typography
          className={`text-sm ${currentLength >= maxLength ? 'text-red-500' : 'text-gray-500'}`}
        >
          {`${currentLength} / ${maxLength}`}
        </Typography>
        {error && <Typography className="text-red-500 text-sm ml-4">{error}</Typography>}
      </div>
    </div>
  )
}

export default TextareaField
