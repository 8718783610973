import { Award } from 'pages/landing/models'
import img1 from 'assets/images/awards-bg-1.png'
import img2 from 'assets/images/awards-bg-2.png'
import img3 from 'assets/images/awards-bg-3.png'
import img4 from 'assets/images/awards-bg-4.png'
import img5 from 'assets/images/awards-bg-5.png'
import img6 from 'assets/images/awards-bg-6.png'
import img7 from 'assets/images/awards-bg-7.png'
import { msg } from '@lingui/macro'

const awardsList: Award[] = [
  {
    id: 1,
    title: msg`قارئ العام`,
    bgImage: img1,
    description: msg`مسابقة سنوية تستهدف طلّاب وطالبات التعليم العام (الابتدائية العليا والمتوسطة والثانوية والجامعية) في العالم العربي لاختيار قارئـ/ة العام من خلال مسابقة تنافسية تعمل على تطوير مهارات وقدرات المشاركين من ناحية البحث والقراءة والكتابة والنشر والتحرير والإلقاء.`
  },
  {
    id: 2,
    title: msg`نص العام`,
    bgImage: img2,
    description: msg`مسابقة سنوية تستهدف طلّاب وطالبات التعليم العام (الابتدائية العليا والمتوسطة والثانوية والجامعية) في العالم العربي لاختيار قارئـ/ة العام من خلال مسابقة تنافسية تعمل على تطوير مهارات وقدرات المشاركين من ناحية البحث والقراءة والكتابة والنشر والتحرير والإلقاء.`
  },
  {
    id: 3,
    title: msg`قارئ الجمهور`,
    bgImage: img3,
    description: msg`مسابقة سنوية تستهدف طلّاب وطالبات التعليم العام (الابتدائية العليا والمتوسطة والثانوية والجامعية) في العالم العربي لاختيار قارئـ/ة العام من خلال مسابقة تنافسية تعمل على تطوير مهارات وقدرات المشاركين من ناحية البحث والقراءة والكتابة والنشر والتحرير والإلقاء.`
  },
  {
    id: 4,
    title: msg`القارئ الواعد`,
    bgImage: img4,
    description: msg`مسابقة سنوية تستهدف طلّاب وطالبات التعليم العام (الابتدائية العليا والمتوسطة والثانوية والجامعية) في العالم العربي لاختيار قارئـ/ة العام من خلال مسابقة تنافسية تعمل على تطوير مهارات وقدرات المشاركين من ناحية البحث والقراءة والكتابة والنشر والتحرير والإلقاء.`
  },
  {
    id: 5,
    title: msg`مناظر العام`,
    bgImage: img5,
    description: msg`مسابقة سنوية تستهدف طلّاب وطالبات التعليم العام (الابتدائية العليا والمتوسطة والثانوية والجامعية) في العالم العربي لاختيار قارئـ/ة العام من خلال مسابقة تنافسية تعمل على تطوير مهارات وقدرات المشاركين من ناحية البحث والقراءة والكتابة والنشر والتحرير والإلقاء.`
  },
  {
    id: 6,
    title: msg`سفراء القراءة`,
    bgImage: img6,
    description: msg`مسابقة المُخصّصة لمعلمي ومعلمات مراحل التعليم العام، والتي تهدف إلى تعزيز دورهم في نشر ثقافة القراءة بين الطلّاب والطالبات من خلال تعيينهم سفراء للقراءة، والاحتفاء بجهودهم المبذولة عبر منحهم عددًا من النقاط لكل مشارك في مسار قارئ العام، وتكريمهم بحسب مجموع النقاط المتحصّلة.`
  },
  {
    id: 7,
    title: msg`المدرسة / الجامعة القارئة`,
    bgImage: img7,
    description: msg`مسابقة لتكريم أعلى المدارس أو الجامعات في عدد المشاركين في المسابقة من طلابها، تهدف إلى إبراز دور المدارس والجامعات في نشر ثقافة القراءة بين الطلاب والطالبات. لا يتطلب الدخول في المنافسة تسجيل المدرسة أو الجامعة، حيث يتم إحصاء نقاط المدارس والجامعات تلقائيًا وفق بيانات الطلاب المشاركين في المسابقة ضمن مسار قارئ العام.`
  }
]

export default awardsList
