const EyeOffIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M15.4019 16.0802C13.9362 17.0098 12.2358 17.5021 10.5002 17.4993C6.00687 17.4993 2.26854 14.266 1.48438 9.99932C1.84273 8.05825 2.81907 6.28507 4.26771 4.94432L1.66021 2.33932L2.83937 1.16016L19.3385 17.6602L18.1594 18.8385L15.401 16.0802H15.4019ZM5.44604 6.12432C4.31355 7.15398 3.52464 8.50664 3.18604 9.99932C3.44629 11.138 3.96876 12.2003 4.71188 13.1015C5.45499 14.0027 6.39823 14.718 7.4665 15.1904C8.53477 15.6628 9.69858 15.8794 10.8653 15.8228C12.032 15.7662 13.1694 15.438 14.1869 14.8643L12.4969 13.1743C11.7774 13.6275 10.9254 13.8227 10.0804 13.728C9.23544 13.6333 8.44774 13.2542 7.84652 12.653C7.24529 12.0518 6.86623 11.2641 6.77151 10.4191C6.67679 9.57416 6.87203 8.72208 7.32521 8.00266L5.44604 6.12432ZM11.2619 11.9393L8.56021 9.23766C8.41193 9.6151 8.37703 10.0276 8.4598 10.4246C8.54256 10.8216 8.73939 11.1858 9.02614 11.4726C9.31289 11.7593 9.6771 11.9561 10.0741 12.0389C10.4711 12.1217 10.8836 12.0868 11.261 11.9385L11.2619 11.9393ZM17.8394 13.826L16.6469 12.6343C17.2039 11.8404 17.6005 10.9453 17.8144 9.99932C17.5879 9.00743 17.1621 8.07194 16.5628 7.24973C15.9636 6.42753 15.2034 5.73576 14.3284 5.21649C13.4535 4.69722 12.4821 4.3613 11.4733 4.22913C10.4645 4.09696 9.43938 4.17131 8.46021 4.44766L7.14521 3.13266C8.18437 2.72432 9.31687 2.49932 10.5002 2.49932C14.9935 2.49932 18.7319 5.73266 19.516 9.99932C19.2607 11.3874 18.6868 12.6973 17.8394 13.826ZM10.2694 6.25599C10.7998 6.22321 11.3311 6.30354 11.8282 6.49167C12.3252 6.6798 12.7765 6.97142 13.1523 7.3472C13.5281 7.72298 13.8197 8.17435 14.0079 8.67137C14.196 9.1684 14.2763 9.69973 14.2435 10.2302L10.2685 6.25599H10.2694Z"
          fill="#3F3F46"
        />
      </g>
    </svg>
  )
}

export default EyeOffIcon
