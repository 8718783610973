import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ActivateRequestDetailResponse } from 'api/types'

interface RegisterState {
  activateRequestDetail: ActivateRequestDetailResponse | null
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  activateRequestDetail: null,
  isLoading: false,
  error: null
}

export const fetchActivateRequestDetail = createAsyncThunk(
  'activateRequestDetail',
  async ({ id }: { id: string }) => {
    const response = await axiosInstance.get<ActivateRequestDetailResponse>(
      `/management/coordinators/${id}`
    )
    return response.data
  }
)

const activateRequestDetailSlice = createSlice({
  name: 'activateRequestDetail',
  initialState,
  reducers: {
    setActivateRequestDetail: (state, action: PayloadAction<ActivateRequestDetailResponse>) => {
      const activateRequestDetail = action.payload ?? null
      state.activateRequestDetail = activateRequestDetail
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActivateRequestDetail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchActivateRequestDetail.fulfilled, (state, action) => {
        state.isLoading = false
        const activateRequestDetail = action.payload ?? null
        state.activateRequestDetail = activateRequestDetail
      })
      .addCase(fetchActivateRequestDetail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setActivateRequestDetail } = activateRequestDetailSlice.actions

export default activateRequestDetailSlice
