import { FC } from 'react'
import SearchIcon from 'assets/icons/SearchIcon'
import { Input } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { i18n } from '@lingui/core'

interface SearchInputProps {
  placeholder: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  width?: string
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, value, onChange, className, width }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)
  return (
    <div className={`relative w-full ${width ? width : 'min-w-[300px]'} ${className}`}>
      <Input
        aria-label="search input"
        color="primary"
        variant="bordered"
        type="text"
        radius="sm"
        fullWidth
        value={value}
        classNames={{
          input: ['px-8']
        }}
        onChange={onChange}
        placeholder={i18n._(placeholder || '')}
        endContent={
          <div
            className={`absolute ${direction === 'rtl' ? 'right-2' : 'left-2'} w-6 h-6 text-gray-900`}
          >
            <SearchIcon fill="#3F3F46" />
          </div>
        }
      />
    </div>
  )
}

export default SearchInput
