import useLanguage from 'hooks/useLanguage'
import { useNationalities } from './get-nationalties'
import { getLocalizedName } from '../utils'

export const useNationalityOptions = () => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { nationalities, isLoading } = useNationalities()

  // Create options for the select field
  const options = nationalities.map((nationality: any) => ({
    key: String(nationality.id), // Nationality ID for key
    label: getLocalizedName(nationality.name, language) // Localized name based on language
  }))

  return { options, isLoading }
}
