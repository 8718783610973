import React from 'react'
import { useDevice } from '../../context/device-context'
import { Stage } from 'pages/landing/models'
import { Trans } from '@lingui/react'

interface StageCardProps {
  stage: Stage
  isActive: boolean
  handleSetActive: (stage: Stage) => void
}

const StageCard: React.FC<StageCardProps> = ({ stage, isActive, handleSetActive }) => {
  const isMobile = useDevice()

  const handleMouseEnter = () => {
    if (!isMobile) {
      handleSetActive(stage)
    }
  }

  const handleClick = () => {
    if (isMobile) {
      handleSetActive(stage)
    }
  }

  return (
    <div
      className={`w-full h-full shadow-lg border border-gray-50 px-9 pt-3 pb-6 rounded-xl flex *:mx-2 items-center transition-all duration-300 ease-in-out ${
        isActive ? 'bg-customTeal' : 'bg-customWhite md:w-[90%]'
      }`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      <p
        className={`text-8xl font-bold transition-colors duration-300 ${
          isActive ? 'text-customWhite' : 'text-customTeal'
        }`}
      >
        {stage.id}
      </p>
      <div>
        <div className={`${isActive ? 'flex flex-col-reverse' : ''}`}>
          <h4
            className={`text-customTeal text-2xl font-bold transition-colors duration-300 ${
              isActive ? 'text-customWhite' : 'text-customTeal'
            }`}
          >
            <Trans id={stage.title.id} />
          </h4>
          <h4
            className={`text-sm font-helveticaNeueArabic font-bold transition-colors duration-300 ${
              isActive ? 'text-customWhite' : 'text-naturalLv1'
            }`}
          >
            <Trans id={stage.date.id} />
          </h4>
        </div>

        {isActive && (
          <p
            className={`text-xs md:text-sm font-helveticaNeueArabic font-bold transition-colors duration-300 ${
              isActive ? 'text-customWhite' : 'text-customTeal'
            }`}
          >
            <Trans id={stage.description.id} />
          </p>
        )}
      </div>
    </div>
  )
}

export default StageCard
