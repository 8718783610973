import React from 'react'

interface IconProps {
  fill?: string
}

const HomeIcon: React.FC<IconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_5140_94954)">
        <path
          d="M21 19.9983C21 20.2635 20.8946 20.5179 20.7071 20.7054C20.5196 20.893 20.2652 20.9983 20 20.9983H4C3.73478 20.9983 3.48043 20.893 3.29289 20.7054C3.10536 20.5179 3 20.2635 3 19.9983V9.48832C2.99989 9.33593 3.03462 9.18554 3.10152 9.04862C3.16841 8.9117 3.26572 8.79188 3.386 8.69832L11.386 2.47632C11.5615 2.33976 11.7776 2.26562 12 2.26562C12.2224 2.26562 12.4385 2.33976 12.614 2.47632L20.614 8.69832C20.7343 8.79188 20.8316 8.9117 20.8985 9.04862C20.9654 9.18554 21.0001 9.33593 21 9.48832V19.9983Z"
          fill="#3F3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_5140_94954">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HomeIcon
