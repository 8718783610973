import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { PendingApplicationIcon } from 'assets/icons/PendingApplicationIcon'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import Typography from 'components/typography/typography'

function PendingApplicationModule() {
  const { _ } = useLingui()
  return (
    <ModalLayout
      showBackButton={false}
      appStyle
      backdrop="opaque"
      header={
        <ModalHeader
          subheaderText={
            <>
              <div className="flex flex-col gap-2">
                <div className="mb-4 flex w-full justify-center">
                  <PendingApplicationIcon />
                </div>
                <Typography weight="bold" size="xl" align="center">
                  {_(msg`في انتظار مراجعة طلبك`)}
                </Typography>
                <Typography align="center">
                  {_(msg`عند تفعيل حسابك سوف تصلك رسالة على البريد الإلكتروني`)}
                </Typography>
              </div>
            </>
          }
        />
      }
    />
  )
}

export default PendingApplicationModule
