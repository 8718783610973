import CardSlider from 'components/base/card-slider'
import SectionTitle from 'components/base/section-title'
import React from 'react'
import SpeakerCard from './speaker-card'
import speakersList from './speakers-list'
import { msg } from '@lingui/macro'

const SpeakersSection: React.FC = () => {
  return (
    <div className="py-7 flex flex-col space-y-14">
      <div className="w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <SectionTitle
          title={msg`المتحدثين`}
          sectionDescription={msg`فصلٌ من الحكاية هنا تعرفوا عليها`}
        />
      </div>

      <CardSlider>
        {speakersList.map((speaker, index) => (
          <SpeakerCard key={index} speaker={speaker} />
        ))}
      </CardSlider>
    </div>
  )
}

export default SpeakersSection
