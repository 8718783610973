import img from 'assets/images/reviews.png'
import avatar from 'assets/images/reviews-avatar.png'
import { Review } from 'pages/landing/models'
import { msg } from '@lingui/macro'

const reviewsList: Review[] = [
  {
    id: 1,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 2,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 3,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 4,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 5,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 6,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 7,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  },
  {
    id: 8,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    name: msg`سارة محمد`,
    avatar: avatar,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img
  }
]

export default reviewsList
