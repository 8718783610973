import { ReactNode } from 'react'
import Footer from './components/footer'
import Navbar from 'components/navbar/Navbar'

interface LayoutProps {
  children: ReactNode
  navbar?: boolean
  footer?: boolean
  isScrolled?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, navbar, footer, isScrolled }) => {
  return (
    <div className="relative">
      {navbar && <Navbar isScrolled={isScrolled} />}
      <main className="relative">{children}</main>
      {footer && <Footer />}
    </div>
  )
}

export default Layout
