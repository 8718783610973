import { FC } from 'react'
import { Checkbox as NextUICheckbox } from '@nextui-org/react'
import { Trans } from '@lingui/react'

interface CheckboxFieldProps {
  label?: string
  labelWithLink?: {
    text: string
    linkText: string
    url?: string
    onClick?: () => void
  }
  checked: boolean
  onChange: () => void
  className?: string
  size?: 'sm' | 'md' | 'lg'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'default' | 'danger' | undefined
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  labelWithLink,
  checked,
  onChange,
  className,
  size = 'md',
  color = 'primary'
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <NextUICheckbox size={size} color={color} isSelected={checked} onChange={onChange}>
        {labelWithLink ? (
          <p className='font-sub-heading-h6-ar text-natural_icon_normal'>
            <Trans id={labelWithLink.text} />{' '}
            <span className='cursor-pointer'>
              <a
                href={labelWithLink.url || '#'}
                onClick={(e) => {
                  e.preventDefault()
                  if (labelWithLink.onClick) {
                    labelWithLink.onClick()
                  }
                }}
              >
                <Trans id={labelWithLink.linkText} />
              </a>
            </span>
          </p>
        ) : (
          label
        )}
      </NextUICheckbox>
    </div>
  )
}

export default CheckboxField
