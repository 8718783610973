import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { UpdateProfileResponse } from 'api/types'

interface UpdateProfileData {
  first_name?: string
  middle_name?: string
  last_name?: string
  gender?: string
  phone_number?: string
  date_of_birth?: string | undefined // ISO date string or undefined
  country?: string | undefined | null
  nationality?: string | undefined | null
  region_id?: string | undefined | null
  education_stage?: string | undefined | null
  school_university_name?: string
  education_administration_id?: string | undefined | null
  education_level?: string | undefined | null
  school_id?: string | undefined | null
  university_id?: string | undefined | null
}

interface UpdateProfileState {
  isLoading: boolean
  error: string | null
}

const initialState: UpdateProfileState = {
  isLoading: false,
  error: null
}

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (data: UpdateProfileData, thunkAPI) => {
    try {
      const response = await axiosInstance.post<UpdateProfileResponse>(
        `/auth/profile/general-data/update`,
        data
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const updateProfileSlice = createSlice({
  name: 'updateProfileSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default updateProfileSlice
