import { AboutCard } from 'pages/landing/models'
import { msg } from '@lingui/macro'

const contentCards: AboutCard[] = [
  {
    id: 1,
    title: msg`الرؤية`,
    description: [msg`أن تكون البرنامج منارة في إذكاء شغف القراءة في العالم العربي`]
  },
  {
    id: 2,
    title: msg`الرسالة`,
    description: [
      msg`خلق بيئة محفزة على القراءة، وملهمة للفضول المعرفي، ومشجعة على التواصل الثقافي`
    ]
  },
  {
    id: 3,
    title: msg`الأهداف`,
    description: [
      msg`• تحسين مرتبة العالم العربي في المؤشرات العالمية لمعدلات القراءة`,
      msg`• رفع الوعي بأهمية القراءة لدى الأفراد والمؤسسات والحكومات`,
      msg`• اكتشاف المواهب وتطوير مهاراتهم المعرفية واللغوية وصناعة القدوات`,
      msg`• توفير منصة للتواصل الثقافي والمعرفي بين القراء والكتّاب`
    ]
  }
]

export default contentCards
