import Users from 'pages/Users'
import UserDetail from 'pages/UserDetail'
import ActivateRequests from 'pages/ActivateRequests'
import ReviewRequest from 'pages/ActivateRequests/ReviewRequest'
import { CompetitionYear } from 'pages/CompetitionYear/CompetitionYear'
import CompetitionDetails from 'pages/CompetitionDetails'
import AccountProfile from 'pages/AccountProfile'
import TeacherPoints from 'pages/TeacherPoints'
import Home from 'pages/auth/Home'
import { msg } from '@lingui/macro'

export interface RouteConfig {
  path: string
  component: React.FC
  roles?: string[]
  showInSidebar?: boolean
  showInBreadcrumb?: boolean
  name: string
  icon?: string
  title?: string
  subtitles?: string
  label: string
  children?: RouteConfig[]
}

const routesConfig: RouteConfig[] = [
  {
    path: 'home',
    component: Home,
    roles: ['student', 'teacher', 'coordinator', 'ithraa'],
    showInSidebar: true,
    name: 'ROUTES.HOME',
    icon: 'home-icon',
    label: msg`الصفحة الرئيسية`.id
  },
  {
    path: 'competition',
    component: CompetitionYear,
    roles: ['admin', 'ithraa'],
    showInSidebar: true,
    name: 'ROUTES.COMPETITION',
    icon: 'competition-icon',
    label: msg`سنوات المسابقة`.id
  },
  {
    path: 'competition/competition-details/:id',
    component: CompetitionDetails,
    roles: ['admin', 'ithraa'],
    showInSidebar: false,
    name: 'ROUTES.COMPETITION_DETAILS',
    label: msg`تفاصيل المسابقة`.id
  },
  {
    path: 'users',
    component: Users,
    roles: ['admin'],
    showInSidebar: true,
    name: 'ROUTES:USERS',
    label: msg`المستخدمون`.id
  },
  {
    path: 'users/user-detail/:id',
    component: UserDetail,
    roles: ['admin'],
    showInSidebar: false,
    name: 'ROUTES:USER_DETAIL',
    label: msg`تفاصيل المستخدم`.id
  },
  {
    path: 'activate-requests',
    component: ActivateRequests,
    roles: ['ithraa'],
    showInSidebar: true,
    name: 'ROUTES.ACTIVATE_REQUESTS',
    label: msg`طلبات التفعيل`.id
  },
  {
    path: 'activate-requests/review-request/:id',
    component: ReviewRequest,
    roles: ['ithraa'],
    showInSidebar: false,
    name: 'ROUTES.REVIEW_REQUEST',
    label: msg`سير المشاركات`.id
  },
  {
    path: 'account-profile',
    component: AccountProfile,
    roles: ['student', 'teacher', 'coordinator', 'ithraa', 'admin', 'judge'],
    showInSidebar: true,
    name: 'ROUTES.ACCOUNT_PROFILE',
    icon: 'person-icon',
    label: msg`الملف الشخصي`.id
  },
  {
    path: 'points',
    component: TeacherPoints,
    roles: ['teacher'],
    showInSidebar: true,
    name: 'ROUTES.POINTS',
    icon: 'medal-icon',
    label: msg`النقاط و الجوائز`.id
  }
]

export default routesConfig
