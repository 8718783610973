import { useEffect, useState } from 'react'
import { ReviewRequestSchemaType } from './ReviewRequestSchemaType'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import ReviewRequestForm from './ReviewRequestForm'
import { ActivateRequestDetailResponse } from 'api/types'
import { toast } from 'react-toastify'
import useLanguage from 'hooks/useLanguage'
import { getEducationStageLabel, getEducationStageValue } from 'constants/educationStageOptions'
import {
  getGenderOfEducationLabel,
  getGenderOfEducationValue
} from 'constants/genderOfEducationOptions'
import { getNameByLanguage } from 'utils/getNameByLanguage'
import { useParams } from 'react-router-dom'
import { fetchActivateRequestDetail } from 'store/slices/activateRequestDetailSlice'
import ActivateRequestsTabs from './ActivateRequestsTabs'
import CoordinatorDetail from './CoordinatorDetail'
import RequestActivites from './RequestActivites'

export default function ReviewRequest() {
  const dispatch = useDispatch<AppDispatch>()
  const language = useLanguage()
  const { activeTab } = useSelector((state: RootState) => state.activateRequestsTabs)
  const [reviewRequestValues, setReviewRequestValues] = useState<
    ReviewRequestSchemaType | undefined
  >(undefined)
  const [countryCode, setCountryCode] = useState<string>('')
  const [regionLabel, setRegionLabel] = useState<{ ar: string; en: string } | undefined>(undefined)
  const { activateRequestDetail, error, isLoading } = useSelector(
    (state: RootState) => state.activateRequestDetail
  )
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(fetchActivateRequestDetail({ id: id }))
        .then(async (response: any) => {
          const activateRequestDetailResponse: ActivateRequestDetailResponse = response?.payload
          if (activateRequestDetailResponse) {
            if (activateRequestDetailResponse.status === 'success') {
              setReviewRequestValues(undefined)
              const data = activateRequestDetailResponse?.data
              if (data) {
                const countryCode = data?.country?.code?.toString() ?? ''
                setCountryCode(countryCode)

                const regionLabelData = data?.country?.region_label
                if (
                  regionLabelData &&
                  typeof regionLabelData === 'object' &&
                  !Array.isArray(regionLabelData)
                ) {
                  setRegionLabel({
                    ar: regionLabelData.ar,
                    en: regionLabelData.en
                  })
                } else {
                  setRegionLabel(undefined)
                }

                setReviewRequestValues({
                  region:
                    data?.access?.regions?.length === 0
                      ? data?.region !== null
                        ? [
                            {
                              value: data?.region?.id?.toString(),
                              label: getNameByLanguage(data?.region?.name, language)
                            }
                          ]
                        : []
                      : data?.access?.regions.map((region: any) => {
                          return {
                            value: region?.id?.toString(),
                            label: getNameByLanguage(region?.name, language)
                          }
                        }),
                  educationStage:
                    data?.education_stage !== null
                      ? {
                          value: getEducationStageValue(data?.education_stage),
                          label: getEducationStageLabel(data?.education_stage, language)
                        }
                      : undefined,
                  genderOfEducation:
                    data?.gender_of_education !== null
                      ? {
                          value: getGenderOfEducationValue(data?.gender_of_education),
                          label: getGenderOfEducationLabel(data?.gender_of_education, language)
                        }
                      : undefined,
                  supportingDocument: data?.proof_document ?? undefined,
                  university:
                    data?.access?.universities?.length === 0
                      ? data?.university !== null
                        ? [
                            {
                              value: data?.university?.id?.toString(),
                              label: getNameByLanguage(data?.university?.name, language)
                            }
                          ]
                        : []
                      : data?.access?.universities.map((university: any) => {
                          return {
                            value: university?.id?.toString(),
                            label: getNameByLanguage(university?.name, language)
                          }
                        }),
                  educationalAdministration:
                    data?.access?.education_administrations?.length === 0
                      ? data?.education_administration !== null
                        ? [
                            {
                              value: data?.education_administration?.id?.toString(),
                              label: getNameByLanguage(
                                data?.education_administration?.name,
                                language
                              )
                            }
                          ]
                        : []
                      : data?.access?.education_administrations.map(
                          (educationAdministration: any) => {
                            return {
                              value: educationAdministration?.id?.toString(),
                              label: getNameByLanguage(educationAdministration?.name, language)
                            }
                          }
                        ),
                  school:
                    data?.access?.education_administrations?.length === 0
                      ? data?.school !== null
                        ? [
                            {
                              value: data?.school?.id?.toString(),
                              label: getNameByLanguage(data?.school?.name, language)
                            }
                          ]
                        : []
                      : data?.access?.schools.map((school: any) => {
                          return {
                            value: school?.id?.toString(),
                            label: getNameByLanguage(school?.name, language)
                          }
                        }),
                  schoolOrUniversityName: data?.school_university_name ?? undefined,
                  comment: data?.comments ?? undefined,
                  numberOfStudents: data?.number_of_students ?? 0,
                  reviewResult: {
                    status:
                      data?.status === 'rejected'
                        ? 'rejected'
                        : data?.status === 'approved'
                          ? 'approval'
                          : 'none',
                    reason: data?.rejection_reason ?? ''
                  },
                  schoolOrUniversityNameObject: {
                    schoolOrUniversityNameChecked: false,
                    schoolOrUniversityNameAr: undefined,
                    schoolOrUniversityNameEn: undefined
                  }
                })
              } else {
                // no data
                setReviewRequestValues(undefined)
              }
            }
          } else {
            // no response
          }
        })
        .catch((error: any) => {
          if (error.status === 'error') {
            const message = error.message ?? ''
            toast.error(message)
          }
        })
    }
  }, [dispatch, id])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!isLoading && reviewRequestValues && countryCode) {
    return (
      <div className="flex flex-col w-full">
        <ActivateRequestsTabs />
        <div className=" mt-[24px]" />

        <div className="flex flex-col w-full px-[0px]">
          {activeTab === 'activateRequest' && (
            <ReviewRequestForm
              reviewRequestValues={reviewRequestValues}
              countryCode={countryCode}
              regionLabel={regionLabel}
            />
          )}
          {activeTab === 'coordinatorDetail' && (
            <CoordinatorDetail activateRequestDetail={activateRequestDetail} />
          )}
          {activeTab === 'requestActivites' && (
            <RequestActivites activateRequestDetail={activateRequestDetail} />
          )}
        </div>
      </div>
    )
  }

  return null
}
