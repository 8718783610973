import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LayoutState {
  direction: 'ltr' | 'rtl'
}

const getInitialDirection = (): 'ltr' | 'rtl' => {
  const storedDirection = localStorage.getItem('layoutDirection')
  if (storedDirection === 'rtl' || storedDirection === 'ltr') {
    return storedDirection
  }
  return 'rtl'
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    direction: getInitialDirection()
  } as LayoutState,
  reducers: {
    setLayoutDirection: (state, action: PayloadAction<'ltr' | 'rtl'>) => {
      state.direction = action.payload
      localStorage.setItem('layoutDirection', action.payload)
    }
  }
})

export const { setLayoutDirection } = layoutSlice.actions
export default layoutSlice
