import { createSlice } from '@reduxjs/toolkit'

interface ModalState {
  isUpdateEmailOTPModalOpen: boolean
}

const initialState: ModalState = {
  isUpdateEmailOTPModalOpen: false
}

export const updateEmailOTPModalSlice = createSlice({
  name: 'updateEmailOTPModal',
  initialState,
  reducers: {
    openUpdateEmailOTPModal: (state) => {
      state.isUpdateEmailOTPModalOpen = true
    },
    closeUpdateEmailOTPModal: (state) => {
      state.isUpdateEmailOTPModalOpen = false
    }
  }
})

export const { openUpdateEmailOTPModal, closeUpdateEmailOTPModal } =
  updateEmailOTPModalSlice.actions

export default updateEmailOTPModalSlice
