import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DateValue, parseDate } from '@internationalized/date'
import DateField from 'components/date-field/date-field'

interface DateInputProps {
  name: string
  label?: string
  parseDateFn?: (value: string | null) => DateValue | null
  helperText?: string
  returnAsDate?: boolean // New prop to control return type
  [key: string]: any // to allow passing other props
}

const DateInput: FC<DateInputProps> = ({
  name,
  label,
  parseDateFn,
  helperText,
  returnAsDate = false, // Default value is false
  ...other
}) => {
  const { control } = useFormContext()

  const defaultParseDateFn = (dateString: string | null): DateValue | null => {
    if (!dateString) return null
    const date = new Date(dateString)
    return !isNaN(date.getTime()) ? parseDate(date.toISOString().split('T')[0]) : null
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateField
          {...other}
          showMonthAndYearPickers
          label={label}
          helperText={helperText}
          error={error ? error.message : null}
          value={parseDateFn ? parseDateFn(field.value) : defaultParseDateFn(field.value)}
          onChange={(value) => {
            if (returnAsDate) {
              const dateObject = value ? new Date(value.year, value.month - 1, value.day) : null
              field.onChange(dateObject)
            } else {
              field.onChange(value?.toString() ?? '')
            }
          }}
        />
      )}
    />
  )
}

export default DateInput
