import { FC, useEffect } from 'react'
import { DropdownItem, DropdownTrigger, Dropdown, DropdownMenu } from '@nextui-org/react'
import CalenderIcon from 'assets/icons/Calendar'
import ArrowDawnIcon from 'assets/icons/ArrowDawnIcon'
import { useWindowSize } from 'usehooks-ts'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYears, setCompetitionYear } from 'store/slices/competitionYearSlice'
import type { CompetitionYear as CompetitionYearType } from 'api/types'
import { Trans } from '@lingui/macro'

const CompetitionYear: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { width = 0 } = useWindowSize()

  const { competitionYears, isLoading, competitionYear } = useSelector(
    (state: RootState) => state.competitionYear
  )
  const isMobile = width <= 430

  useEffect(() => {
    dispatch(fetchCompetitionYears({}))
  }, [dispatch])

  const handleItemClick = (year: CompetitionYearType) => {
    dispatch(setCompetitionYear(year))
  }

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <div className=" flex flex-row justify-start items-center gap-4 cursor-pointer">
          <CalenderIcon />
          <div className="flex flex-col items-start gap-[2px]">
            {competitionYear ? (
              <p className='font-body-h6'>{competitionYear.name}</p>
            ) : (
              <p className='font-body-h6'><Trans>لا توجد سنوات مسابقة متاحة</Trans></p>
            )}
          </div>
          {!isMobile && <ArrowDawnIcon />}
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label="Competition Years" variant="flat">
        {isLoading ? (
          <DropdownItem key="loading">Loading...</DropdownItem>
        ) : competitionYears && competitionYears.length > 0 ? (
          competitionYears.map((year) => (
            <DropdownItem key={year.id} onClick={() => handleItemClick(year)}>
              <div className="flex flex-row items-center gap-2">
                <CalenderIcon />
                <p className="font-medium">{year.name}</p>
              </div>
            </DropdownItem>
          ))
        ) : (
          <DropdownItem key="no-data"><p><Trans>لا توجد سنوات مسابقة متاحة</Trans></p></DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default CompetitionYear
