// hooks/useNationalities.ts

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchNationalities } from 'store/slices/nationalitiesSlice'

export const useNationalities = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { nationalities, isLoading } = useSelector((state: RootState) => state.nationalities)

  useEffect(() => {
    dispatch(fetchNationalities())
  }, [dispatch])

  return { nationalities, isLoading }
}
