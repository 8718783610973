import Button from 'components/base/button'
import SectionTitle from 'components/base/section-title'
import React from 'react'
import DebateCard from './debate-card'
import { msg } from '@lingui/macro'

const DebatesSection: React.FC = () => {
  return (
    <div className="border-b border-gray-300">
      <div className="py-12 flex flex-col space-y-7 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <div className="flex justify-between">
          <SectionTitle title={msg`المناظرات`} sectionDescription={msg`هذا النص قابل للتعديل`} />
          <div>
            <Button
              placeholder={msg`المزيد`}
              btnStyle="secondary"
              btnSize="Default"
              trailingIcon={true}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <DebateCard />
          <DebateCard />
          <DebateCard />
        </div>
      </div>
    </div>
  )
}

export default DebatesSection
