import { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store' // Adjust this import according to your project structure

interface HorizontalScrollProps {
  containerWidth?: boolean
}

export const useHorizontalScroll = ({ containerWidth }: HorizontalScrollProps = {}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  // Get the direction from Redux
  const direction = useSelector((state: RootState) => state.layout.direction)

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth } = containerRef.current
      const clientWidth = containerWidth ? containerRef.current.clientWidth : window.innerWidth

      if (direction === 'rtl') {
        setCanScrollRight(scrollLeft < 0)
        setCanScrollLeft(Math.abs(scrollLeft) + clientWidth + 5 < scrollWidth)
      }
      if (direction === 'ltr') {
        setCanScrollRight(scrollLeft > 0)
        setCanScrollLeft(clientWidth + Math.abs(scrollLeft) < scrollWidth)
      }
    }
  }

  const scrollLeft = () => {
    if (containerRef.current) {
      const scrollAmount = containerWidth ? containerRef.current.clientWidth : window.innerWidth
      containerRef.current.scrollBy({
        left: direction === 'rtl' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  const scrollRight = () => {
    if (containerRef.current) {
      const scrollAmount = containerWidth ? containerRef.current.clientWidth : window.innerWidth
      containerRef.current.scrollBy({
        left: direction === 'rtl' ? scrollAmount : -scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    checkScrollPosition()
    containerRef.current?.addEventListener('scroll', checkScrollPosition)
    window.addEventListener('resize', checkScrollPosition)

    return () => {
      containerRef.current?.removeEventListener('scroll', checkScrollPosition)
      window.removeEventListener('resize', checkScrollPosition)
    }
  }, [direction]) // Reacts to changes in direction

  return {
    containerRef,
    scrollLeft,
    scrollRight,
    canScrollLeft,
    canScrollRight
  }
}
