import React from 'react'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'
import Points from 'pages/TeacherPoints/points/PointsTab'
import CalculationMethod from 'pages/TeacherPoints/calculationMethod/CalculationMethodTab'

const TeacherPoints = () => {
  const { _ } = useLingui()
  const tabItems = [
    {
      key: 'points',
      title: _(msg`النقاط`),
      content: <Points />
    },
    {
      key: 'calculation-methods',
      title: _(msg` طريقة حساب النقاط و الكود الخاص بك `),
      content: <CalculationMethod />
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <TabsNavigator variant="underlined" color="success" items={tabItems} />
    </div>
  )
}

export default TeacherPoints
