import React from "react";

interface ChevronIconProps {
  fill: string;
  width: number | string;
  height: number | string;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_7050_18953)">
        <path
          d="M11.9968 11.328L7.04681 16.278L5.63281 14.864L11.9968 8.5L18.3608 14.864L16.9468 16.278L11.9968 11.328Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7050_18953">
          <rect
            width={width}
            height={height}
            fill={fill}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronIcon;
