import React from 'react'

interface YouTubeVideoProps {
  videoId?: string
  fallbackImg: string
  altText: string
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = ({ videoId, fallbackImg, altText }) => {
  return (
    <>
      {videoId ? (
        <iframe
          width="100%"
          height="auto"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded-lg"
        ></iframe>
      ) : (
        <div className="relative transform transition-transform duration-300 hover:scale-105">
          <img src={fallbackImg} alt={altText} className="rounded-lg" />
        </div>
      )}
    </>
  )
}

export default YouTubeVideo
