import React from 'react'
import { Trans } from '@lingui/react'
import awardsList from './awards-list'
import AwardCard from './award-card'

interface AwardsContentProps {
  activeCard: number
  handleSetActive: (id: number) => void
}

const AwardsContent: React.FC<AwardsContentProps> = ({ activeCard, handleSetActive }) => {
  const activeAward = awardsList.find((award) => award.id === activeCard)

  return (
    <div className="flex flex-col lg:flex-row-reverse lg:justify-between items-center space-y-9 lg:space-y-0">
      <div className="flex flex-col space-y-9 md:space-y-24 lg:w-1/2 p-5">
        <div className="font-bold text-customWhite text-5xl md:text-7xl mb-4 text-center">
          <p>
            <Trans id="total_awards">مجموع الجوائز</Trans>
          </p>
          <p>
            <Trans id="total_prize">500,000 ريال</Trans>
          </p>
        </div>
        <div className="font-bold text-customWhite text-base md:text-2xl text-center">
          {activeAward && (
            <p className="whitespace-normal">
              <Trans id={activeAward.description.id} />
            </p>
          )}
        </div>
      </div>

      <div className="lg:w-1/2 px-6 lg:px-12 py-16 bg-white bg-opacity-20 rounded-[46px]">
        <div className="grid grid-cols-3 grid-rows-3 gap-2 items-center justify-center">
          {awardsList.map((award, index) => (
            <AwardCard
              key={index}
              award={award}
              isActive={award.id === activeCard}
              handleSetActive={() => handleSetActive(award.id)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AwardsContent
