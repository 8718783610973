import React, { useState } from 'react'
import aboutBackGround from 'assets/images/comma.png'
import AboutCard from './about-card'
import contentCards from './about-list'
import { useSelector } from 'react-redux'
import { useHorizontalScroll } from 'hooks/useHorizontalScroll'
import { Trans } from '@lingui/macro'
import { RootState } from 'store'

const AboutSection: React.FC = () => {
  const direction = useSelector((state: RootState) => state.layout.direction)
  const [isActive, setIsActive] = useState<number | null>(null)

  const { containerRef } = useHorizontalScroll({})

  return (
    <div className="overflow-hidden">
      <div className="pt-3 pb-3 md:pb-32 mx-3.5 md:px-0">
        <div className="w-full flex justify-end">
          <div className="w-full lg:w-11/12 md:w-10/12 lg:mx-0 sm:mx-auto flex flex-col space-y-8 lg:flex-row lg:space-y-0 justify-between items-center">
            <p className="text-naturalLv2 w-full lg:max-w-[617px] font-helveticaNeueArabic font-bold text-lg md:text-2xl text-justify">
              <Trans>
                {' '}
                أقرأ مسابقة سنوية للقراءة أطلقها مركز الملك عبدالعزيز الثقافي العالمي (إثراء) –
                مبادرة أرامكو السعودية في عام 2013م من المملكة العربية السعودية بهدف نشر ثقافة
                القراءة وتقدير المعرفة في المجتمع وغرس مفاهيم الاطلاع والقراءة والإنتاج الثقافي
                المكتوب باللغة العربية إيمانًا بأهمية القراءة بوصفها واحدة من أهم وسائل الإثراء
                المعرفي للأجيال القادمة.{' '}
              </Trans>
            </p>
            <img
              src={aboutBackGround}
              alt="fasela"
              className={`${
                direction === 'rtl' ? 'mr-5 lg:-ml-16 -ml-0' : 'ml-5 lg:-mr-16 -mr-0'
              } `}
            />
          </div>
        </div>

        <div className="md:-mt-40 md:w-10/12 mx-auto">
          <div
            ref={containerRef}
            className="flex flex-row overflow-x-auto md:w-full md:overflow-hidden scrollbar-hide items-center md:place-content-center pb-4"
          >
            {contentCards.map((card, index) => (
              <AboutCard key={card.id} card={card} isActive={isActive} setIsActive={setIsActive} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
