import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'
import SupplementaryHero from './components/hero/supplementary-hero'
import SupplementaryNavbar from './components/navbars/supplementary-navbar'
import FilesSection from './components/supplementary-files/files-section'
import { useState } from 'react'

const SupplementaryFiles: React.FC = () => {
  useDocumentTitle(msg`الملفات المساعدة`)
  const [selectedCategory, setSelectedCategory] = useState('-1')
  return (
    <div
      className=""
      style={{
        background:
          'radial-gradient(55.13% 22.7% at 75.63% 22.72%, rgba(43, 173, 173, 0.1) 0%, rgba(43, 173, 173, 0.03) 100%), radial-gradient(44.53% 18.34% at 13.43% 20.37%, rgba(249, 250, 251, 0.2) , rgba(249, 250, 251, 0) )'
      }}
    >
      <section id="">
        <SupplementaryHero />
      </section>
      <section id="" className="">
        <SupplementaryNavbar setSelectedCategory={setSelectedCategory} />
      </section>
      <section id="" className="">
        <FilesSection selectedCategory={selectedCategory} />
      </section>
    </div>
  )
}

export default SupplementaryFiles
