import { DateValue, useDisclosure } from '@nextui-org/react'
import { FC } from 'react'
import Competitions from './Competitions'
import CreateCompetition from './CreateCompetition'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  fetchCompetitionYears,
  setEndDate,
  setSearch,
  setStartDate,
  setStatusFilter
} from 'store/slices/competitionYearSlice'
import CheckboxVariant from 'components/checkbox/checkbox'
import DateField from 'components/date-field/date-field'
import { CalendarDate, parseDate } from '@internationalized/date'
import FilterLayout from 'components/layouts/filter-layout/filter-layout'
import { t } from 'i18next'

const parseDateToCalendarDate = (dateString: string | null): CalendarDate | undefined => {
  if (!dateString) return undefined
  const date = new Date(dateString)
  return parseDate(date.toISOString().split('T')[0])
}

export const CompetitionYear: FC = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const dispatch = useDispatch<AppDispatch>()
  const { search, start_date, end_date, status, auto_start, page, per_page } = useSelector(
    (state: RootState) => state.competitionYear
  )

  const handleFilterApply = () => {
    dispatch(
      fetchCompetitionYears({
        search,
        start_date,
        end_date,
        auto_start,
        page,
        per_page,
        status
      })
    )
  }

  const handleFilterReset = async () => {
    dispatch(setSearch(''))
    dispatch(setStartDate(undefined))
    dispatch(setEndDate(undefined))
    dispatch(setStatusFilter(null))
    dispatch(
      fetchCompetitionYears({
        search: '',
        start_date: undefined,
        end_date: undefined,
        auto_start: undefined,
        page: 1,
        per_page: 8,
        status: null
      })
    )
  }

  const filterFields = [
    {
      name: 'search',
      component: <></>, // Search is handled outside popover
      action: (value: string) => {
        dispatch(setSearch(value))
        dispatch(
          fetchCompetitionYears({
            search,
            start_date,
            end_date,
            auto_start,
            page,
            per_page,
            status
          })
        )
      },
      selector: (state: RootState) => state.competitionYear.search
    },
    {
      name: 'not_started',
      component: (
        <CheckboxVariant
          label={t('not_started')}
          checked={status === 'not_started'}
          onChange={() =>
            dispatch(setStatusFilter(status === 'not_started' ? null : 'not_started'))
          }
        />
      ),
      action: (value: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived') =>
        dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'started',
      component: (
        <CheckboxVariant
          label={t('started')}
          checked={status === 'started'}
          onChange={() => dispatch(setStatusFilter(status === 'started' ? null : 'started'))}
        />
      ),
      action: (value: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived') =>
        dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'ended',
      component: (
        <CheckboxVariant
          label={t('ended')}
          checked={status === 'ended'}
          onChange={() => dispatch(setStatusFilter(status === 'ended' ? null : 'ended'))}
        />
      ),
      action: (value: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived') =>
        dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'terminated',
      component: (
        <CheckboxVariant
          label={t('terminated')}
          checked={status === 'terminated'}
          onChange={() => dispatch(setStatusFilter(status === 'terminated' ? null : 'terminated'))}
        />
      ),
      action: (value: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived') =>
        dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'archived',
      component: (
        <CheckboxVariant
          label={t('archived')}
          checked={status === 'archived'}
          onChange={() => dispatch(setStatusFilter(status === 'archived' ? null : 'archived'))}
        />
      ),
      action: (value: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived') =>
        dispatch(setStatusFilter(value)),
      selector: (state: RootState) => state.competitionYear.status
    },
    {
      name: 'start_date',
      component: (
        <DateField
          label={t('Start Date')}
          value={start_date ? parseDateToCalendarDate(start_date) : undefined}
          onChange={(date) => dispatch(setStartDate(date?.toString()))}
        />
      ),
      action: (value: DateValue | null) =>
        dispatch(setStartDate(value ? value.toString() : undefined)),
      selector: (state: RootState) => state.competitionYear.start_date
    },
    {
      name: 'end_date',
      component: (
        <DateField
          label={t('End Date')}
          value={end_date ? parseDateToCalendarDate(end_date) : undefined}
          onChange={(date) => dispatch(setEndDate(date?.toString()))}
        />
      ),
      action: (value: DateValue | null) =>
        dispatch(setEndDate(value ? value.toString() : undefined)),
      selector: (state: RootState) => state.competitionYear.end_date
    }
  ]

  return (
    <div className="py-6 px-10">
      <FilterLayout
        filterFields={filterFields}
        onFilterApply={handleFilterApply}
        onFilterReset={handleFilterReset}
        buttonText="Add a new version"
        onButtonPress={onOpen}
        showButton={true}
      />
      <Competitions />
      <CreateCompetition isOpen={isOpen} onOpenChange={onOpenChange} />
    </div>
  )
}
