import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { UpdateEmailResponse } from 'api/types'

interface UpdateEmailState {
  isLoading: boolean
  error: string | null
}

const initialState: UpdateEmailState = {
  isLoading: false,
  error: null
}

export const updateEmail = createAsyncThunk('updateEmail', async (data: any, thunkAPI) => {
  try {
    const response = await axiosInstance.post<UpdateEmailResponse>(
      `/auth/profile/email/update`,
      data
    )
    return response.data
  } catch (error: any) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else if (error.request) {
      return thunkAPI.rejectWithValue(error.request)
    } else {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
})

const updateEmailSlice = createSlice({
  name: 'updateEmailSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default updateEmailSlice
