import React from "react";

interface EarthIconProps {
  fill: string;
}

const EarthIcon: React.FC<EarthIconProps> = ({ fill }) => {
  return (
    <svg
    width="24" height="24" viewBox="0 0 24 24" fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
<g clipPath="url(#clip0_8_1572)">
<path d="M6.235 6.453C4.96004 7.77629 4.1795 9.49815 4.02457 11.3292C3.86964 13.1602 4.34977 14.9887 5.38426 16.5074C6.41874 18.0261 7.94451 19.1424 9.70511 19.6686C11.4657 20.1948 13.3538 20.0989 15.052 19.397C15.167 18.647 14.915 17.927 14.812 17.675C14.582 17.115 13.824 16.158 12.559 14.831C12.221 14.476 12.243 14.203 12.364 13.394L12.377 13.303C12.459 12.749 12.597 12.421 14.462 12.125C15.41 11.975 15.659 12.353 16.004 12.878L16.12 13.05C16.448 13.53 16.691 13.64 17.058 13.806C17.223 13.881 17.428 13.976 17.703 14.131C18.355 14.504 18.355 14.925 18.355 15.847V15.952C18.355 16.343 18.317 16.687 18.257 16.986C19.0244 16.0229 19.5591 14.8957 19.8196 13.6922C20.0801 12.4886 20.0593 11.2412 19.7588 10.047C19.4584 8.85284 18.8863 7.7441 18.0873 6.80714C17.2883 5.87017 16.2838 5.13026 15.152 4.645C14.599 5.018 13.84 5.547 13.575 5.91C13.44 6.095 13.248 7.042 12.625 7.12C12.463 7.14 12.244 7.126 12.012 7.111C11.39 7.071 10.54 7.016 10.268 7.755C10.095 8.223 10.065 9.495 10.624 10.155C10.714 10.26 10.731 10.455 10.67 10.674C10.59 10.961 10.429 11.136 10.378 11.172C10.282 11.116 10.09 10.893 9.959 10.742C9.646 10.377 9.254 9.922 8.748 9.782C8.564 9.731 8.362 9.689 8.165 9.647C7.616 9.532 6.995 9.401 6.85 9.093C6.744 8.867 6.745 8.556 6.745 8.228C6.745 7.811 6.745 7.34 6.541 6.883C6.47008 6.71991 6.36585 6.57344 6.235 6.453V6.453ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z" fill={fill}/>
</g>
<defs>
<clipPath id="clip0_8_1572">
<rect width="24" height="24" fill={fill}/>
</clipPath>
</defs>
    </svg>
  );
};

export default EarthIcon;