import React from 'react'
import DialogueCard from './dialogue-card'
import backgroundImage from 'assets/images/dialogue.png'
import SectionTitle from 'components/base/section-title'
import Button from 'components/base/button'
import { msg } from '@lingui/macro'

const DialoguesSection: React.FC = () => {
  return (
    <div className="border-b border-gray-300">
      <div className="py-12 flex flex-col space-y-7 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <div className="flex justify-between">
          <SectionTitle title={msg`الحوارات`} sectionDescription={msg`هذا النص قابل للتعديل`} />
          <div>
            <Button
              placeholder={msg`المزيد`}
              btnStyle="secondary"
              btnSize="Default"
              trailingIcon={true}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-8 lg:space-y-0 items-center lg:flex-row lg:justify-between">
          <div className="md:w-2/3 flex flex-col space-y-8">
            <DialogueCard />
            <DialogueCard />
          </div>
          <div className="hidden w-full md:flex flex-wrap justify-center lg:justify-end">
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
            <img
              src={backgroundImage}
              alt="Marathon"
              className="rounded-lg p-2.5 transform transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DialoguesSection
