import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CheckboxVariant from 'components/checkbox/checkbox'
import CheckboxField from 'components/checkbox-field/checkbox-field'
import { Trans } from '@lingui/react'

interface CheckboxInputProps {
  name: string
  label: string
  className?: string
  layout?: 'first' | 'second'
  labelWithLink?: {
    text: string
    linkText: string
    url?: string
    onClick?: () => void
  }
  size?: 'sm' | 'md' | 'lg'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'default' | 'danger' | undefined
  removeBorder?: boolean
  reverse?: boolean
  rounded?: boolean
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  name,
  label,
  className,
  layout = 'first',
  labelWithLink,
  size = 'md',
  color = 'primary',
  removeBorder,
  reverse,
  rounded,
  ...other
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <div className="flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        rules={{ required: 'You must agree to the terms and conditions' }}
        render={({ field }) =>
          layout === 'first' ? (
            <CheckboxVariant
              rounded={rounded}
              reverse
              removeBorder
              label={label}
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
              className={className}
            />
          ) : (
            <CheckboxField
              label={label}
              labelWithLink={labelWithLink}
              checked={field.value}
              onChange={() => field.onChange(!field.value)}
              className={className}
              size={size}
              color={color}
            />
          )
        }
      />
      {errors[name] && (
        <p className="text-danger_default_lv1_text_normal_active_hover_pressed font-label-sm-ar">
          {/* {errors[name]?.message?.toString()} */}
          {/* @ts-ignore */}
          <Trans id={errors[name]?.message} />
        </p>
      )}
    </div>
  )
}

export default CheckboxInput
