import { getRequestStatusLabel } from 'constants/requestStatus'
import useLanguage from 'hooks/useLanguage'
import React from 'react'

interface RequestStatusProps {
  status: 'draft' | 'pending' | 'approved' | 'rejected' | ''
}

const RequestStatus: React.FC<RequestStatusProps> = ({ status }) => {
  const language = useLanguage()

  const getStatusStyles = (status: 'draft' | 'pending' | 'approved' | 'rejected' | '') => {
    switch (status) {
      case 'draft':
        return 'text-gray-700 bg-gray-200'
      case 'pending':
        return 'text-yellow-700 bg-yellow-200'
      case 'approved':
        return 'text-green-700 bg-green-200'
      case 'rejected':
        return 'text-red-700 bg-red-200'
      default:
        return 'text-gray-700 bg-transparent'
    }
  }

  const classes = getStatusStyles(status)

  const displayText = getRequestStatusLabel(status, language)

  return <span className={`inline-block px-2 py-1 rounded-lg ${classes}`}>{displayText}</span>
}

export default RequestStatus
