interface RequestStatus {
  value: string
  labelEn: string
  labelAr: string
}

export const requestStatus: RequestStatus[] = [
  { value: 'draft', labelEn: 'Draft', labelAr: 'مسودة' },
  { value: 'approved', labelEn: 'Approved', labelAr: 'موافق عليه' },
  { value: 'pending', labelEn: 'Pending', labelAr: 'قيد الانتظار' },
  { value: 'rejected', labelEn: 'Rejected', labelAr: 'مرفوض' }
]

type Language = 'en' | 'ar'

export const getRequestStatus = (lang: Language) => {
  return requestStatus.map((option) => ({
    value: option.value,
    label: lang === 'en' ? option.labelEn : option.labelAr
  }))
}

export const getRequestStatusLabel = (value: string, lang: Language) => {
  const option = requestStatus.find((option) => option.value === value)
  return option ? (lang === 'en' ? option.labelEn : option.labelAr) : ''
}

export const getRequestStatusValue = (value: string) => {
  const option = requestStatus.find((option) => option.value === value)
  return option?.value ?? ''
}
