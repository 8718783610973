import React, { useState } from 'react'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { toast } from 'react-toastify'
import { forgotPassword } from 'store/slices/forgotPasswordSlice'
import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'

const ForgotPassword: React.FC<{ handleConfirmationModalDisplay: (shouldShow: boolean) => void }> = ({ handleConfirmationModalDisplay }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>()

  const defaultValues = { email: '' }

  const handleSubmitSuccess = (result: any) => {
    if (result.status === 'success') {
      toast.success(i18n._(result.message) || i18n._(msg`تم إرسال رابط إعادة تعيين كلمة المرور بنجاح!`))
      handleConfirmationModalDisplay(true)
    } else {
      throw new Error(result.message || i18n._(msg`فشل إرسال رابط إعادة تعيين كلمة المرور.`))
    }
  }

  const onSubmit = async (data: any) => {
    setErrorMsg('')
    setIsLoading(true)
    // console.log('Submitted Data:', data)
    try {
      const result = await dispatch(
        forgotPassword({
          email: data.email
        })
      ).unwrap()

      handleSubmitSuccess(result)
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message) || i18n._(msg`حدث خطأ أثناء إرسال رابط إعادة تعيين كلمة المرور.`))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}
      <TextInput
        name="email"
        label={msg`البريد الإلكتروني`.id}
        type="email"
        placeholder={msg`example@gmail.com`.id}
        helperText="أدخل عنوان بريدك الإلكتروني"
      />
      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
          isLoading={isLoading}
          disabled={isLoading}
        >
          <p><Trans>استرجاع كلمة المرور</Trans></p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default ForgotPassword
