import {
  Button,
  DateRangePicker,
  DatePicker,
  DateValue,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem
} from '@nextui-org/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { RangeValue } from '@react-types/shared'
import { parseDate } from '@internationalized/date'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { createCompetition } from 'store/slices/createCompoetitionSlice'
import { toast } from 'react-toastify'
import { fetchCompetitionYears } from 'store/slices/competitionYearSlice'
import i18n from 'locales/i18n'

type CreateCompetitionType = {
  isOpen: boolean
  onOpenChange: () => void
}

export const useStatusOptions = () => {
  const { t } = useTranslation()
  return [
    { key: 'not_started', label: t('not_started') },
    { key: 'started', label: t('started') },
    { key: 'ended', label: t('ended') },
    { key: 'terminated', label: t('terminated') },
    { key: 'archived', label: t('archived') }
  ]
}

export const CreateCompetition: FC<CreateCompetitionType> = (props) => {
  const { isOpen, onOpenChange } = props
  const { t } = useTranslation()
  const isLoading = useSelector((state: RootState) => state.createCompetition.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const statusOptions = useStatusOptions()

  const CreateCompetitionSchema = z.object({
    name: z.string().nonempty({ message: t('Name is required') }),
    status: z.string().nonempty({ message: t('Status is required') }),
    dateRange: z
      .array(z.string())
      .length(2, { message: t('Please select a date range') })
      .refine((dates) => new Date(dates[0]) < new Date(dates[1]), {
        message: t('Start date must be before end date')
      }),
    closed_at: z.string().nonempty({ message: t('Closing date is required') })
  })

  type CreateCompetitionSchemaType = z.infer<typeof CreateCompetitionSchema>

  const {
    handleSubmit,
    clearErrors,
    trigger,
    reset,
    control,
    formState: { errors, isSubmitted }
  } = useForm<CreateCompetitionSchemaType>({
    resolver: zodResolver(CreateCompetitionSchema),
    defaultValues: {
      name: '',
      status: '',
      dateRange: ['', ''],
      closed_at: ''
    }
  })

  const onSubmit = async (data: CreateCompetitionSchemaType) => {
    try {
      await dispatch(
        createCompetition({
          name: data.name,
          startDate: data.dateRange[0],
          endDate: data.dateRange[1],
          closedAt: data.closed_at,
          status: data.status as 'not_started' | 'started' | 'ended' | 'terminated' | 'archived'
        })
      ).unwrap()
      await dispatch(fetchCompetitionYears({})).unwrap()
      reset()
      onOpenChange()
    } catch (error: any) {
      if (error.status === 'error') {
        const message = error.message ?? ''
        toast.dismiss()
        toast.error(t(message))
      }
    }
  }

  const parseStringToDateValue = (dateString: string | null): DateValue | null => {
    if (!dateString) return null
    const date = new Date(dateString)
    return !isNaN(date.getTime()) ? parseDate(date.toISOString().split('T')[0]) : null
  }

  useEffect(() => {
    const handleLanguageChange = () => {
      clearErrors()
      if (isSubmitted) {
        trigger()
      }
    }

    // Listen to language change and handle accordingly
    i18n.on('languageChanged', handleLanguageChange)

    // Cleanup listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [clearErrors, trigger, isSubmitted])

  return (
    <Modal
      hideCloseButton={true}
      size="xl"
      placement="center"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent className="min-h-[60vh] pt-12">
        <ModalHeader className="flex flex-col items-center gap-1 mb-8">
          <p className="font-bold text-2xl">{t('Add a new version')}</p>
          <p className="font-normal text-sm">
            {t('Please enter the required information for the new version to be added')}
          </p>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    label={t('Name')}
                    {...field}
                    className={errors.name ? 'border-red-500' : ''}
                  />
                )}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="lg"
                    items={statusOptions}
                    placeholder={t('Status')}
                    className="w-full"
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {(status) => <SelectItem key={status.key}>{status.label}</SelectItem>}
                  </Select>
                )}
              />
              {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="dateRange"
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    label={t('Date of version duration')}
                    className="w-full"
                    value={
                      {
                        start: parseStringToDateValue(field.value[0]) ?? null,
                        end: parseStringToDateValue(field.value[1]) ?? null
                      } as RangeValue<DateValue>
                    }
                    onChange={(value) => {
                      const newValue = [value.start?.toString() ?? '', value.end?.toString() ?? '']
                      field.onChange(newValue)
                    }}
                  />
                )}
              />
              {errors.dateRange && (
                <p className="text-red-500 text-sm">{errors.dateRange.message}</p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="closed_at"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t('Closing Date')}
                    className="w-full"
                    value={parseStringToDateValue(field.value) ?? null}
                    onChange={(value) => {
                      const newValue = value?.toString() ?? ''
                      field.onChange(newValue)
                    }}
                  />
                )}
              />
              {errors.closed_at && (
                <p className="text-red-500 text-sm">{errors.closed_at.message}</p>
              )}
            </div>
            <div className="flex w-full justify-center">
              <Button
                type="submit"
                size="lg"
                className="bg-[#2AACAD] text-white hover:bg-[#279a99] border rounded-full border-transparent"
                disabled={isLoading}
                isLoading={isLoading}
              >
                {t('Add')}
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateCompetition
