import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useScrollContext } from '../../context/scroll-context'

import { Trans } from '@lingui/macro'

const BlogNavbar = () => {
  const [, setScrolled] = useState(false)

  const location = useLocation()
  const scrollToSection = useScrollContext()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleNavigation = (section: string) => {
    if (location.pathname === '/blog' && scrollToSection) {
      if (scrollToSection) {
        scrollToSection(section)
      }
    }
  }

  const textClass = 'text-lg font-bold text-customTeal hover:text-customGreen px-1.5'
  return (
    <div className="py-6 border-b border-gray-300">
      <div className="w-fit mx-auto">
        <ul className="flex flex-row flex-wrap justify-center items-center">
          <button onClick={() => handleNavigation('podcast')}>
            <li className={textClass}>
              <Trans>بودكاست</Trans>
            </li>
          </button>
          <button onClick={() => handleNavigation('dialogues')}>
            <li className={textClass}>
              <Trans>الحوارات</Trans>
            </li>
          </button>
          <button onClick={() => handleNavigation('debates')}>
            <li className={textClass}>
              <Trans>المناظرات</Trans>
            </li>
          </button>
          <button onClick={() => handleNavigation('offers')}>
            <li className={textClass}>
              <Trans>العروض الختامية</Trans>
            </li>
          </button>
          <button onClick={() => handleNavigation('reviews')}>
            <li className={textClass}>
              <Trans>مراجعات الكتب</Trans>
            </li>
          </button>
        </ul>
      </div>
    </div>
  )
}

export default BlogNavbar
