import { Chip } from '@nextui-org/react'
import CardLayout from 'components/card/card-layout'
import DataCards from 'components/data-cards/data-cards'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { fetchCompetitionYears, setPage } from 'store/slices/competitionYearSlice'

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'not_started':
      return 'bg-gray-400 border border-gray-500 text-gray-700'
    case 'started':
      return 'bg-[#E6FAF2] border border-[#9DF2D0] text-[#08A66D]'
    case 'ended':
      return 'bg-red-200 border border-red-300 text-red-700'
    case 'terminated':
      return 'bg-orange-200 border border-orange-300 text-orange-700'
    case 'archived':
      return 'bg-yellow-200 border border-yellow-300 text-yellow-700'
    default:
      return ''
  }
}

export const Competitions: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { competitionYears, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.competitionYear
  )

  useEffect(() => {
    dispatch(fetchCompetitionYears({ page, per_page }))
  }, [dispatch, page, per_page])

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
  }

  const renderCard = (year: any) => (
    <CardLayout onClick={() => navigate(`competition-details/${year.id}`)}>
      <Chip className={`${getStatusColor(year.status)}`}>
        <p className="font-bold">{t(year.status)}</p>
      </Chip>
      <p className="text-xl font-bold">{year.name}</p>
      <p className="font-medium text-textGray">
        {formatDate(year.start_date)} - {formatDate(year.end_date)}
      </p>
    </CardLayout>
  )

  return (
    <DataCards
      data={competitionYears || []}
      isLoading={isLoading}
      page={page}
      per_page={per_page}
      total_pages={total_pages}
      onPageChange={handlePageChange}
      renderCard={renderCard}
    />
  )
}

export default Competitions
