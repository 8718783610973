import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface SidebarRouteProps {
  route: {
    path: string
    name: string
    icon?: string
    label: string
  }
  basePath: string
  isCollapsed: boolean
  iconMap: { [key: string]: React.FC<{ fill?: string }> }
}

const SidebarRoute: React.FC<SidebarRouteProps> = ({ route, basePath, isCollapsed, iconMap }) => {
  const location = useLocation()
  const fullPath = `${basePath}/${route.path}`
  const isActive = location.pathname === fullPath
  const IconComponent = route.icon ? iconMap[route.icon] : null

  return (
    <Link
      to={fullPath}
      className={`p-3 gap-3 rounded-md flex items-center transition-all duration-300 
    ${isActive ? 'bg-customTeal' : 'bg-white'} 
    ${isCollapsed ? 'justify-center' : ''} 
    hover:bg-customTeal10 hover:text-white`}
    >
      {IconComponent && (
        <div className={`w-5 h-5 flex items-center justify-center`}>
          <IconComponent fill={'#3F3F46'} />
        </div>
      )}
      {!isCollapsed && (
        <span
          className={`text-natural_icon_normal font-label-h5-radix transition-opacity duration-300`}
        >
          <Trans id={route.label} />
        </span>
      )}
    </Link>
  )
}

export default SidebarRoute
