import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { IthraUsersResponse, IthraUser } from 'api/types'

interface RegisterState {
  ithraUsers: IthraUser[]
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  ithraUsers: [],
  isLoading: false,
  error: null
}

export const fetchIthraUsers = createAsyncThunk('ithraUsers', async () => {
  const response = await axiosInstance.get<IthraUsersResponse>('/management/users', {
    params: {
      type: 'ithraa'
    }
  })
  return response.data
})

const ithraUsersSlice = createSlice({
  name: 'ithraUsers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchIthraUsers.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchIthraUsers.fulfilled, (state, action) => {
        state.isLoading = false
        const ithraUsers = action.payload.data ?? []
        state.ithraUsers = ithraUsers
      })
      .addCase(fetchIthraUsers.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default ithraUsersSlice
