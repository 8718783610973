import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

// Define a custom file validation function for PDFs with size limit
const pdfFileValidation = (file?: File) => {
  if (!file) return true // If file is not provided, validation passes since it's optional

  // Check if the file is a PDF or JPEG
  const validTypes = ['application/pdf', 'image/jpeg']
  if (!validTypes.includes(file.type)) {
    return false
  }

  // Check file size (3MB limit)
  const maxSizeMB = 3
  if (file.size > maxSizeMB * 1024 * 1024) {
    return false
  }

  return true
}

// Schema with conditional validation
export const useCoordinatorActiveAccountSchema = (isLocatedInSA: boolean) => {
  const { _ } = useLingui()
  return z
    .object({
      action: z.string(),
      regions: z.array(z.string()).optional(),
      education_stage: z.string().optional(),
      education_administration: z.array(z.string()).optional(),
      all_regions: z.boolean().optional(),
      all_education_stages: z.boolean().optional(),
      all_education_administrations: z.boolean().optional(),
      university: z.string().optional(),
      gender_of_education: z.string().optional(),
      school_university_name: z.string().optional(),
      number_of_students: z.string().optional(),
      proof_document: z
        .instanceof(File, {
          message: _(msg`الملف غير صالح يجب ان يكون PDF او JPEG`)
        })
        .optional()
        .refine(pdfFileValidation, {
          message: _(msg`ملف غير صالح. يرجى تحميل ملف PDF او JPEG بحجم أقل من 3 ميجا بايت`)
        }),
      comment: z.string().optional()
    })
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        // Check if regions are required
        if (data.all_regions === false && (!data.regions || data.regions.length === 0)) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['regions']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        // Check if education_stage is required
        if (data.all_education_stages === false && !data.education_stage) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['education_stage']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        // Check if education_administration is required
        if (
          data.all_education_administrations === false &&
          isLocatedInSA &&
          data.education_stage === 'general' &&
          (!data.education_administration || data.education_administration.length === 0)
        ) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['education_administration']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        // Check if school_university_name is required
        if (data.university === 'other' && !data.school_university_name) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['school_university_name']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        if (!data.university && data.education_stage === 'university') {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['university']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        if (!data.number_of_students) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['number_of_students']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        if (!data.gender_of_education) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['gender_of_education']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        if (!data.proof_document) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['proof_document']
      }
    )
    .refine(
      (data) => {
        if (data.action !== 'submit') return true // Skip validation if not submitting

        if (!data.comment) {
          return false
        }
        return true
      },
      {
        message: _(msg`هذا الحقل مطلوب`),
        path: ['comment']
      }
    )
}
