import Actions from './Actions'
import LocalizedNameTableCell from './LocalizedNameTableCell'
import { ActivateRequest } from 'api/types'
import RequestStatus from './RequestStatus'
import dayjs from 'dayjs'

interface Props {
  requestItem: ActivateRequest
  columnKey: any
}

export const RenderCell = ({ requestItem, columnKey }: Props) => {
  // @ts-ignore
  const cellValue = requestItem[columnKey]

  switch (columnKey) {
    case 'coordinator_information':
      return (
        <div className=" flex flex-col gap-0.5">
          <span>{requestItem.name}</span>
          <span>{requestItem.email}</span>
          <span>{requestItem.phone}</span>
        </div>
      )
    case 'coordinator_country':
      return (
        <LocalizedNameTableCell localizedName={requestItem?.coordinator?.region?.name ?? null} />
      )
    case 'region':
      return (
        <LocalizedNameTableCell localizedName={requestItem?.coordinator?.region?.name ?? null} />
      )
    case 'submission_date':
      return (
        <span>
          {requestItem.coordinator?.submission_at
            ? dayjs(new Date(requestItem.coordinator?.submission_at)).format('DD MMM YYYY hh:mm A')
            : '-'}
        </span>
      )
    case 'request_status':
      return <RequestStatus status={requestItem.coordinator?.status ?? ''} />
    case 'actions':
      return <Actions requestId={requestItem.id} />
    default:
      return cellValue
  }
}
