import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import validationSchema from './validation-schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { toast } from 'react-toastify'
import { verifyEmail } from 'store/slices/verifyEmailSlice'
import { resendVerificationEmail } from 'store/slices/resendVerificationEmailSlice'
import { setToken, setVerifiedAt } from 'store/slices/authSlice'
import OtpInput from 'components/hook-form/otp-input'
import { useNavigate } from 'react-router-dom'
import Countdown from './countdown'
import { i18n } from '@lingui/core'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'
import { verifyNewEmail } from 'store/slices/verifyNewEmailSlice'
import { resendVerificationNewEmail } from 'store/slices/resendVerificationNewEmailSlice'

interface OTPVerificationProps {
  handleOTPModalDisplay?: (shouldShow: boolean) => void
  handleEmailModalDisplay?: (shouldShow: boolean) => void
  onClose?: () => void
  onSuccessCallback?: () => void
  isLoginFlow: boolean
  newEmail?: boolean
}

const OTPVerification: React.FC<OTPVerificationProps> = ({
  handleOTPModalDisplay,
  handleEmailModalDisplay,
  onClose,
  onSuccessCallback,
  isLoginFlow,
  newEmail
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCountdownComplete, setIsCountdownComplete] = useState(false)
  const [email, setEmail] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const storedEmail = localStorage.getItem('email')
    setEmail(storedEmail)
  }, [])

  const defaultValues = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: ''
  }

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues
  })

  const name = useSelector((state: RootState) => {
    const data = state.me?.me?.data
    return typeof data?.first_name === 'string' ? data.first_name : ''
  })

  const handleOtpSubmitSuccess = (result: any) => {
    if (result.status === 'success') {
      if (onSuccessCallback) {
        onSuccessCallback()
        toast.success(result.message)
      } else {
        dispatch(setToken(result.data.accessToken))
        dispatch(setVerifiedAt(result.data.user.email_verified_at))
        navigate('/app/home')

        toast.success(i18n._(msg`مرحبا `) + ' ' + name)
      }
    } else {
      throw new Error(result.message || i18n._(msg`فشل التحقق من OTP.`))
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')
    setIsLoading(true)
    try {
      const otp = `${data.otp1}${data.otp2}${data.otp3}${data.otp4}`

      const result = newEmail
        ? await dispatch(verifyNewEmail({ code: otp })).unwrap()
        : await dispatch(verifyEmail({ code: otp })).unwrap()
      handleOtpSubmitSuccess(result)
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message) || i18n._(msg`حدث خطأ أثناء التحقق من OTP.`))
    } finally {
      setIsLoading(false)
    }
  }

  const handleResendOTP = async () => {
    if (isCountdownComplete) {
      try {
        setIsLoading(true)
        const result = newEmail
          ? await dispatch(resendVerificationNewEmail({ email: email ?? '' })).unwrap()
          : await dispatch(resendVerificationEmail()).unwrap()

        if (result.status === 'success') {
          toast.success(i18n._(result.message) || i18n._(msg`تم إرسال OTP بنجاح!`))
          setIsCountdownComplete(false)
          methods.reset(defaultValues)
        } else {
          throw new Error(result.message || i18n._(msg`فشل إعادة إرسال OTP.`))
        }
      } catch (error: any) {
        toast.error(i18n._(error.message) || i18n._(msg`حدث خطأ أثناء إرسال OTP.`))
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleCountdownComplete = () => {
    setIsCountdownComplete(true)
  }

  const handelChangeEmail = () => {
    handleOTPModalDisplay && handleOTPModalDisplay(false)
    handleEmailModalDisplay && handleEmailModalDisplay(true)
    onClose && onClose()
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-6" noValidate>
        {errorMsg && <ErrorMessage msgstr={errorMsg} />}
        <OtpInput name="otp" />
        {!isCountdownComplete && <Countdown minutes={5} onComplete={handleCountdownComplete} />}
        <ButtonVariant
          variant="clear"
          size="lg"
          onClick={handleResendOTP}
          disabled={!isCountdownComplete || isLoading}
          hidden={!isCountdownComplete || isLoading}
        >
          <p>
            <Trans>لم يصلك الرمز ؟ إعادة إرسال</Trans>
          </p>
        </ButtonVariant>
        <div className="flex gap-1 w-full justify-center space-x-4 ">
          {!isLoginFlow && (
            <ButtonVariant variant="bordered" size="lg" onClick={handelChangeEmail}>
              <p>
                <Trans>تغير البريد الإلكتروني</Trans>
              </p>
            </ButtonVariant>
          )}
          <ButtonVariant
            variant="secondary"
            type="submit"
            size="lg"
            iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
            iconRotate
            isLoading={isLoading}
            disabled={isLoading || isCountdownComplete}
          >
            <p>
              <Trans>تأكيد</Trans>
            </p>
          </ButtonVariant>
        </div>
      </form>
    </FormProvider>
  )
}

export default OTPVerification
