import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'

interface CountdownProps {
  minutes: number
  onComplete: () => void
}

const Countdown: React.FC<CountdownProps> = ({ minutes, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer)
          onComplete()
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [onComplete])

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`
  }

  return (
    <div className="countdown-timer mx-auto">
      <p>
        <Trans>بإمكانك إعادة إرسال الرمز في</Trans>
        {' '}
        <span className="text-secondary_lv1_text_normal_2">{formatTime(timeLeft)}</span>
      </p>
    </div>
  )
}

export default Countdown
