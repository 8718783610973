import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchEducationAdministrations } from 'store/slices/educationAdministrationsSlice'

interface UseEducationAdministrationsProps {
  countryId?: string
  regionId?: string // Array of region IDs
}

export const useEducationAdministrations = ({
  countryId,
  regionId
}: UseEducationAdministrationsProps = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { educationAdministrations, isLoading } = useSelector(
    (state: RootState) => state.educationAdministrations
  )

  const memoizedRegionId = useMemo(() => regionId, [regionId])

  useEffect(() => {
    dispatch(
      fetchEducationAdministrations({
        countryId,
        regionsIds: memoizedRegionId ? [memoizedRegionId] : [] // Pass regions array and countryId
      })
    )
  }, [dispatch, countryId, memoizedRegionId]) // Watch memoizedRegions instead of regions directly

  return { educationAdministrations, isLoading }
}
