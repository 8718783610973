import React from 'react'
import img from 'assets/images/login-bg.png'
import Layout from 'pages/AccountPages/Layout'
import StaticBackground from 'pages/AccountPages/components/StaticBackground'
import ResetPassModal from 'pages/AccountPages/components/modals/ResetPassModal'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'

const ResetPassword = () => {
  useDocumentTitle(msg`اعادة تعين كلمة المرور`)
  return (
    <Layout>
      <StaticBackground img={img} />
      <ResetPassModal />
    </Layout>
  )
}

export default ResetPassword
