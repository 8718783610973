import React from 'react'

interface IconProps {
  fill?: string
}

const PersonIcon: React.FC<IconProps> = ({ fill = '#13100D' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_192_17254)">
        <path
          d="M3 4.995C3 3.893 3.893 3 4.995 3H19.005C20.107 3 21 3.893 21 4.995V19.005C21 19.5341 20.7898 20.0415 20.4157 20.4157C20.0415 20.7898 19.5341 21 19.005 21H4.995C4.46589 21 3.95846 20.7898 3.58432 20.4157C3.21019 20.0415 3 19.5341 3 19.005V4.995ZM5 5V19H19V5H5ZM7.972 18.18C7.35698 17.9136 6.77036 17.586 6.221 17.202C6.85527 16.219 7.72596 15.4108 8.75339 14.8514C9.78082 14.292 10.9322 13.9993 12.102 14C14.502 14 16.619 15.207 17.88 17.047C17.3412 17.4456 16.7636 17.789 16.156 18.072C15.6929 17.43 15.0838 16.9073 14.3789 16.547C13.674 16.1868 12.8936 15.9993 12.102 16C10.387 16 8.872 16.864 7.972 18.18ZM12 13C11.5404 13 11.0852 12.9095 10.6606 12.7336C10.236 12.5577 9.85013 12.2999 9.52513 11.9749C9.20012 11.6499 8.94231 11.264 8.76642 10.8394C8.59053 10.4148 8.5 9.95963 8.5 9.5C8.5 9.04037 8.59053 8.58525 8.76642 8.16061C8.94231 7.73597 9.20012 7.35013 9.52513 7.02513C9.85013 6.70012 10.236 6.44231 10.6606 6.26642C11.0852 6.09053 11.5404 6 12 6C12.9283 6 13.8185 6.36875 14.4749 7.02513C15.1313 7.6815 15.5 8.57174 15.5 9.5C15.5 10.4283 15.1313 11.3185 14.4749 11.9749C13.8185 12.6313 12.9283 13 12 13ZM12 11C12.3978 11 12.7794 10.842 13.0607 10.5607C13.342 10.2794 13.5 9.89782 13.5 9.5C13.5 9.10218 13.342 8.72064 13.0607 8.43934C12.7794 8.15804 12.3978 8 12 8C11.6022 8 11.2206 8.15804 10.9393 8.43934C10.658 8.72064 10.5 9.10218 10.5 9.5C10.5 9.89782 10.658 10.2794 10.9393 10.5607C11.2206 10.842 11.6022 11 12 11Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_192_17254">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PersonIcon
