import { ChangeEvent, FC, ReactNode } from 'react'
import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import FilterIcon from 'assets/icons/FilterIcon'
import SearchInput from 'components/search-field/search-field'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ButtonVariant from 'components/button-variant/button'

interface FilterFieldProps {
  name: string
  component: ReactNode
  action: (value: any) => void
  selector: (state: RootState) => any
}

export interface FilterComponentProps {
  buttonContent?: ReactNode
  filterFields: FilterFieldProps[]
  onFilterApply: () => void
  onFilterReset: () => void
}

export const FilterComponent: FC<FilterComponentProps> = ({
  buttonContent,
  filterFields,
  onFilterApply,
  onFilterReset
}) => {
  const { t } = useTranslation()
  const state = useSelector((state: RootState) => state)

  // Find the search field definition
  const searchField = filterFields.find((field) => field.name === 'search')

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    if (searchField) {
      searchField.action(searchValue)
      if (searchValue === '') {
        onFilterReset() // Reset the data when the search is cleared
      } else {
        onFilterApply() // Apply filters if search is not empty
      }
    }
  }

  const handleApply = () => {
    filterFields.forEach((field) => {
      if (field.name !== 'search') {
        const value = field.selector(state)
        field.action(value)
      }
    })
    onFilterApply()
  }

  return (
    <div className="flex flex-row gap-4">
      {searchField && (
        <SearchInput
          placeholder={t('Search')}
          value={searchField.selector(state)}
          onChange={handleSearchChange}
        />
      )}
      <Popover placement="bottom-end" showArrow={true}>
        <PopoverTrigger>
          <Button
            className="bg-white border border-black text-black hover:border-[#2AACAD]"
            size="lg"
            isIconOnly={!buttonContent}
          >
            {buttonContent || <FilterIcon />}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <div className="min-w-[300px]">
            <div className="px-2 py-6 flex flex-col gap-4 w-full">
              {filterFields
                .filter((field) => field.name !== 'search')
                .map((field, index) => (
                  <div key={index}>{field.component}</div>
                ))}
            </div>
            <div className="py-4 px-2 w-full flex gap-2 justify-start bg-[#F9FAFB] border-t border-[#E0E0E0]">
              <ButtonVariant size="sm" color="primary" onClick={handleApply}>
                {t('Confirm')}
              </ButtonVariant>
              <Button
                size="sm"
                className="border border-black text-black bg-transparent rounded-full font-bold"
                onClick={onFilterReset}
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default FilterComponent
