import RequestsTable from './RequestsTable'
import { useTranslation } from 'react-i18next'
import StatusFilter from './StatusFilter'
import SubmissionDateFilter from './SubmissionDateFilter'
import ResponseDateFilter from './ResponseDateFilter'
import IthraUsersFilter from './IthraUsersFilter'
import NumberOfStudentsFromFilter from './NumberOfStudentsFromFilter'
import NumberOfStudentsToFilter from './NumberOfStudentsToFilter'

export default function ActivateRequests() {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col justify-start items-start h-full w-full">
      <div className=" flex flex-row justify-start items-start">
        <p>{t('ACTIVATE_REQUESTS')}</p>
      </div>
      <div className=" mt-3" />

      <div className="flex justify-between gap-4 items-center w-full">
        <div className="flex items-center gap-3 w-full flex-wrap">
          {/* <div className="w-1/4"><SearchFilter /></div> */}
          <div className="w-1/4">
            <SubmissionDateFilter />
          </div>
          <div className="w-1/4">
            <ResponseDateFilter />
          </div>
          <div className="w-1/4">
            <StatusFilter />
          </div>
          <div className="w-1/4">
            <IthraUsersFilter />
          </div>
          <div className="w-1/4">
            <NumberOfStudentsFromFilter />
          </div>
          <div className="w-1/4">
            <NumberOfStudentsToFilter />
          </div>
        </div>
      </div>

      <div className=" mt-3" />
      <RequestsTable />
    </div>
  )
}
