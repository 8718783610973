import React, { useState } from 'react'
import Layout from 'pages/AccountPages/Layout'
import img from 'assets/images/login-bg.png'
import StaticBackground from 'pages/AccountPages/components/StaticBackground'
import ConfirmationModal from 'pages/AccountPages/components/modals/ConfirmationModal'
import ForgotPassModal from 'pages/AccountPages/components/modals/ForgotPassModal'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'

const ForgotPassword = () => {
  useDocumentTitle(msg`نسيت كلمة المرور`)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleConfirmationModalDisplay = (shouldShow: boolean) => {
    setShowConfirmationModal(shouldShow)
  }

  return (
    <Layout>
      <StaticBackground img={img} />
      {showConfirmationModal ? (
        <ConfirmationModal />
      ) : (
        <ForgotPassModal handleConfirmationModalDisplay={handleConfirmationModalDisplay} />
      )}
    </Layout>
  )
}

export default ForgotPassword
