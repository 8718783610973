import React from 'react'
import { useParams } from 'react-router-dom'

const UserDetail: React.FC = () => {
  const { id } = useParams()

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">User Detail</h2>
      <p>Details for user with ID: {id}</p>
    </div>
  )
}

export default UserDetail
