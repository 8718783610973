import { SupplementaryFileList } from 'pages/landing/models'
import { msg } from '@lingui/macro'
import { FaRegFile, FaFileSignature } from 'react-icons/fa6'
import { BsFileEarmarkPlay, BsFileImage } from 'react-icons/bs'

const SupplementaryFilesData: SupplementaryFileList[] = [
  {
    category: {
      id: 1,
      name: msg`المستندات`,
      icon: FaRegFile
    },
    files: [
      {
        id: 1,
        name: msg`دليل المستخدم`,
        description: msg`دليل شامل للمستخدمين الجدد حول كيفية التنقل واستخدام الموقع.`,
        file: 'user-guide.pdf'
      },
      {
        id: 2,
        name: msg`دليل المتسابق`,
        description: msg`إرشادات تفصيلية للمشاركين في المسابقة حول كيفية التسجيل وتقديم الطلبات.`,
        file: 'contestant-guide.pdf'
      },
      {
        id: 3,
        name: msg`سياسة الخصوصية`,
        description: msg`وثيقة توضح كيفية حماية بيانات المستخدمين خلال الفعالية.`,
        file: 'privacy-policy.pdf'
      },
      {
        id: 4,
        name: msg`قوانين الفعالية`,
        description: msg`الشروط والقوانين التي يجب على المشاركين اتباعها لضمان المنافسة العادلة.`,
        file: 'event-rules.pdf'
      },
      {
        id: 16,
        name: msg`برامج أقرأ`,
        description: msg`مجموعة من البرامج التعليمية والمبادرات الثقافية التي أطلقت تحت مظلة فعالية إقرأ، تهدف لتعزيز مهارات القراءة لدى الطلاب والمعلمين.`,
        file: 'iread-programs.pdf'
      }
    ]
  },
  {
    category: {
      id: 2,
      name: msg`الوسائط`,
      icon: BsFileImage
    },
    files: [
      {
        id: 5,
        name: msg`صور الفائزين`,
        description: msg`ألبوم صور الفائزين بالمسابقة في الحفل الختامي.`,
        file: 'winners-images.zip'
      },
      {
        id: 6,
        name: msg`صور المشاركين`,
        description: msg`مجموعة صور للمشاركين في الفعالية والتحديات المختلفة.`,
        file: 'participants-images.zip'
      },
      {
        id: 7,
        name: msg`فيديو تعريفي بالفعالية`,
        description: msg`فيديو تعريفي يشرح أهداف وتاريخ فعالية إقرأ السنوية.`,
        file: 'introductory-video.mp4'
      },
      {
        id: 8,
        name: msg`مقتطفات من الفعاليات`,
        description: msg`مقاطع فيديو قصيرة تعرض أبرز لحظات الفعاليات والتحديات.`,
        file: 'highlights.mp4'
      }
    ]
  },
  {
    category: {
      id: 3,
      name: msg`الرسومات`,
      icon: FaFileSignature
    },
    files: [
      {
        id: 9,
        name: msg`شعار فعالية إقرأ`,
        description: msg`الشعار الرسمي لفعالية إقرأ، يعكس روح القراءة والمعرفة في تصميم مبتكر.`,
        file: 'iread-logo.pdf'
      },
      {
        id: 10,
        name: msg`ملصق مراحل مسابقة أقرأ`,
        description: msg`ملصق يوضح جميع مراحل مسابقة أقرأ من التسجيل وحتى الإعلان عن الفائزين.`,
        file: 'iread-stages-poster.pdf'
      },
      {
        id: 11,
        name: msg`بطاقات إرشادية للمعلمين والطلاب`,
        description: msg`بطاقات مخصصة للمعلمين والطلاب، تحتوي على نصائح وإرشادات للمشاركة الفعّالة في مسابقة أقرأ.`,
        file: 'guidance-cards-teachers-students.pdf'
      },
      {
        id: 12,
        name: msg`شعارات للفعالية`,
        description: msg`مجموعة من الشعارات الخاصة بفعاليات إقرأ يمكن استخدامها في التصاميم المختلفة.`,
        file: 'event-logos.zip'
      }
    ]
  },
  {
    category: {
      id: 4,
      name: msg`العروض التقديمية`,
      icon: BsFileEarmarkPlay
    },
    files: [
      {
        id: 13,
        name: msg`عرض تقديمي عن تاريخ إقرأ`,
        description: msg`عرض تقديمي يعرض مسيرة فعالية إقرأ منذ انطلاقها وأهم الإنجازات.`,
        file: 'iread-history-presentation.pdf'
      },
      {
        id: 14,
        name: msg`عرض تقديمي للفعاليات النهائية`,
        description: msg`عرض تقديمي خاص بالفعالية النهائية للحدث وجميع الأنشطة المرافقة.`,
        file: 'final-event-presentation.pdf'
      },
      {
        id: 15,
        name: msg`عرض تقديمي عن تحديات القراءة`,
        description: msg`عرض تقديمي يشرح مختلف التحديات والمنافسات ضمن فعالية إقرأ.`,
        file: 'reading-challenges-presentation.pdf'
      }
    ]
  }
]

export default SupplementaryFilesData
