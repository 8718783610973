import React, { useState } from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import img from 'assets/images/login-bg.png'
import Layout from 'pages/AccountPages/Layout'
import StaticBackground from 'pages/AccountPages/components/StaticBackground'
import LoginModal from 'pages/AccountPages/components/modals/LoginModal'
import OTPModal from 'pages/AccountPages/components/modals/OTPModal'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'

const Login: React.FC = () => {
  useDocumentTitle(msg`تسجيل الدخول`)
  const isVerified = useSelector((state: RootState) =>
    typeof state.auth.verifiedAt === 'string' ? state.auth.verifiedAt : null
  )

  const [showOTPModal, setShowOTPModal] = useState(!!isVerified)

  const handleOTPModalDisplay = (shouldShow: boolean) => {
    setShowOTPModal(shouldShow)
  }

  return (
    <Layout>
      <StaticBackground img={img} />
      {showOTPModal ? (
        <OTPModal handleOTPModalDisplay={handleOTPModalDisplay} isLoginFlow={true} />
      ) : (
        <LoginModal handleOTPModalDisplay={handleOTPModalDisplay} />
      )}
    </Layout>
  )
}

export default Login
