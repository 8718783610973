export const RejectApplicationIcon = () => {
  return (
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5136_206397)">
        <path
          d="M24.0137 0.796875C23.1699 1.32812 7.87305 16.7969 7.76367 17.2188C7.7168 17.4375 7.70117 29.875 7.7168 44.875L7.76367 72.1406L8.13867 72.7344C8.3418 73.0625 8.79492 73.5156 9.13867 73.75L9.7793 74.1719L24.3418 74.2188L38.9043 74.25L38.9668 75.625C39.0762 77.8125 39.873 79.1562 41.5605 79.9844L42.4512 80.4219H56.123H69.7949L70.6855 79.9844C71.6855 79.4844 72.4199 78.7344 72.9043 77.7031C73.2324 76.9844 73.2324 76.8906 73.2324 63.3125V49.6406L72.7949 48.75C71.9668 47.0625 70.623 46.2656 68.4355 46.1562L67.0605 46.0938L67.0293 24.3438L66.9824 2.59375L66.5605 1.95312C66.3262 1.60938 65.873 1.15625 65.5449 0.953125L64.9512 0.578125L44.7168 0.53125C25.0605 0.5 24.4668 0.5 24.0137 0.796875ZM63.9355 24.875V46.1094L53.2012 46.1562C42.6543 46.2031 42.4355 46.2031 41.7324 46.5312C40.7012 47.0156 39.9512 47.75 39.4512 48.75L39.0137 49.6406L38.9668 60.375L38.9199 71.125H24.873H10.8105V45.1875V19.2656L17.5762 19.2188L24.3418 19.1719L24.9824 18.75C25.3262 18.5156 25.7793 18.0625 25.9824 17.7344L26.3574 17.1406L26.4043 10.375L26.4512 3.625H45.1855H63.9355V24.875ZM23.3105 11.0469V16.125H18.2012H13.0762L18.1543 11.0469C20.9512 8.25 23.248 5.96875 23.2637 5.96875C23.2949 5.96875 23.3105 8.25 23.3105 11.0469ZM69.3262 49.4375C70.2168 49.8438 70.1855 49.3906 70.1855 63.2969C70.1855 72.9062 70.1387 76.2188 69.998 76.5156C69.5918 77.4062 70.0449 77.375 56.123 77.375C42.2012 77.375 42.6543 77.4062 42.248 76.5156C41.9668 75.8906 41.998 50.5625 42.2793 50.0469C42.7324 49.2344 42.2949 49.2656 56.0762 49.25C65.7168 49.25 69.0293 49.2969 69.3262 49.4375Z"
          fill="#2AACAD"
        />
        <path
          d="M17.8598 23.375C16.8129 23.9531 16.8441 25.6094 17.9223 26.0937C18.2348 26.2344 22.9379 26.2812 37.391 26.2812C53.5785 26.2812 56.516 26.25 56.891 26.0625C57.9223 25.5156 57.9223 23.9219 56.891 23.375C56.266 23.0625 18.4379 23.0469 17.8598 23.375Z"
          fill="#2AACAD"
        />
        <path
          d="M17.8576 30.4062C16.4982 31.1562 17.0764 33.3125 18.6232 33.3125C19.4201 33.3125 20.1857 32.5469 20.1857 31.7656C20.1857 30.5938 18.8889 29.8281 17.8576 30.4062Z"
          fill="#2AACAD"
        />
        <path
          d="M24.891 30.4062C23.8441 30.9844 23.8754 32.6406 24.9535 33.125C25.2504 33.2656 29.1879 33.3125 40.9066 33.3125C54.0629 33.3125 56.516 33.2812 56.891 33.0938C57.9223 32.5469 57.9223 30.9531 56.891 30.4062C56.266 30.0938 25.4691 30.0938 24.891 30.4062Z"
          fill="#2AACAD"
        />
        <path
          d="M17.8598 37.4375C16.8129 38.0156 16.8441 39.6719 17.9223 40.1562C18.2348 40.2969 22.9379 40.3438 37.391 40.3438C53.5785 40.3438 56.516 40.3125 56.891 40.125C57.9223 39.5781 57.9223 37.9844 56.891 37.4375C56.266 37.125 18.4379 37.1094 17.8598 37.4375Z"
          fill="#2AACAD"
        />
        <path
          d="M17.8598 44.4688C16.8129 45.0469 16.8441 46.7031 17.9223 47.1875C18.5004 47.4531 34.3754 47.4531 34.9535 47.1875C35.4066 46.9844 35.8129 46.3281 35.8129 45.8125C35.8129 45.2969 35.4066 44.6406 34.9535 44.4375C34.3285 44.1562 18.3754 44.1875 17.8598 44.4688Z"
          fill="#2AACAD"
        />
        <path
          d="M17.8581 51.5C17.1237 51.9062 16.8737 52.8125 17.28 53.6094C17.6862 54.375 17.9362 54.4062 23.3269 54.4062C28.7487 54.4062 28.9987 54.375 29.3737 53.5469C29.7331 52.75 29.4831 51.875 28.7644 51.5C28.155 51.1875 18.4206 51.1875 17.8581 51.5Z"
          fill="#2AACAD"
        />
        <path
          d="M49.9675 54.5638C48.8894 54.9857 47.7644 56.142 47.3581 57.2201C47.1081 57.892 47.0456 58.3138 47.0925 59.1732C47.1863 60.5013 47.5769 61.3295 48.6706 62.4857L49.4519 63.3138L48.6706 64.142C47.6081 65.267 47.1863 66.1263 47.0925 67.392C46.9988 68.8451 47.3581 69.8763 48.3581 70.9232C49.3894 72.017 50.4519 72.4388 51.9831 72.3451C53.3113 72.2513 54.1394 71.8607 55.2956 70.767L56.1237 69.9857L56.9519 70.767C58.0769 71.8295 58.9362 72.2513 60.2019 72.3451C61.7175 72.4545 62.6863 72.0795 63.7956 70.9857C64.8894 69.8763 65.2644 68.9076 65.155 67.392C65.0613 66.1263 64.6394 65.267 63.5769 64.142L62.7956 63.3138L63.5769 62.4857C64.6706 61.3295 65.0613 60.5013 65.155 59.1732C65.2488 57.642 64.8269 56.5795 63.7331 55.5482C62.6863 54.5482 61.655 54.1888 60.2019 54.2826C58.9362 54.3763 58.0769 54.7982 56.9519 55.8607L56.1237 56.642L55.2956 55.8607C54.0925 54.7201 53.3113 54.3607 51.905 54.2982C50.9988 54.267 50.5612 54.3138 49.9675 54.5638ZM53.9519 58.892C55.3894 60.2826 55.5769 60.4232 56.1237 60.4232C56.6706 60.4232 56.8581 60.2826 58.2956 58.892C59.7644 57.4701 59.905 57.3763 60.4987 57.3763C61.405 57.3763 62.0612 58.0326 62.0612 58.9388C62.0612 59.5326 61.9675 59.6732 60.5456 61.142C59.155 62.5795 59.0144 62.767 59.0144 63.3138C59.0144 63.8607 59.155 64.0482 60.5456 65.4857C61.9675 66.9545 62.0612 67.0951 62.0612 67.6888C62.0612 68.2045 61.9675 68.4388 61.6081 68.7982C61.2487 69.1576 61.0144 69.2513 60.4987 69.2513C59.905 69.2513 59.7644 69.1576 58.2956 67.7357C56.8581 66.3451 56.6706 66.2045 56.1237 66.2045C55.5769 66.2045 55.3894 66.3451 53.9519 67.7357C52.4831 69.1576 52.3425 69.2513 51.7488 69.2513C50.8425 69.2513 50.1862 68.5951 50.1862 67.6888C50.1862 67.0951 50.28 66.9545 51.7019 65.4857C53.0925 64.0482 53.2331 63.8607 53.2331 63.3138C53.2331 62.767 53.0925 62.5795 51.7019 61.142C50.28 59.6732 50.1862 59.5326 50.1862 58.9388C50.1862 58.0326 50.8425 57.3763 51.7488 57.3763C52.3425 57.3763 52.4831 57.4701 53.9519 58.892Z"
          fill="#2AACAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_5136_206397">
          <rect width="80" height="80" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
