import React from 'react'
import stagesList from './stages-list'

interface StageImageProps {
  activeCard: number
  prevActiveCard: number | null
}

const StageImage: React.FC<StageImageProps> = ({ activeCard, prevActiveCard }) => {
  return (
    <div className="w-full h-full left-0 top-0 -z-10">
      {prevActiveCard && prevActiveCard !== activeCard && (
        <img
          key={prevActiveCard}
          className="object-cover w-full h-full transition-opacity duration-1000 ease-in-out opacity-0"
          src={stagesList[prevActiveCard - 1].bgImage}
          alt={stagesList[prevActiveCard - 1].title.id}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
      <img
        key={activeCard}
        className="object-cover w-full h-full transition-opacity duration-1000 ease-in-out opacity-100"
        src={stagesList[activeCard - 1].bgImage}
        alt={stagesList[activeCard - 1].title.id}
      />
      <div className="absolute inset-0 bg-gradient-to-tr from-black from-2% animate-fade-in -z-20" />
    </div>
  )
}

export default StageImage
