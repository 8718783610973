import React from 'react'
import { useDevice } from '../../context/device-context'
import { Award } from 'pages/landing/models'
import { Trans } from '@lingui/react'

interface AwardCardProps {
  award: Award
  isActive: boolean
  handleSetActive: (award: Award) => void
}

const AwardCard: React.FC<AwardCardProps> = ({ award, isActive, handleSetActive }) => {
  const isMobile = useDevice()

  const handleMouseEnter = () => {
    if (!isMobile) {
      handleSetActive(award)
    }
  }

  const handleClick = () => {
    if (isMobile) {
      handleSetActive(award)
    }
  }

  return (
    <div className={award.id === 1 ? 'col-span-3' : ''}>
      <div
        className={`h-[90px] md:h-[154px] flex items-center justify-center transition-all ease-in-out duration-500
            ${award.id === 1 ? '' : 'lg:w-full lg:px-2'} 
            ${isActive ? 'bg-customGreen' : 'bg-transparent'}
            border-4 border-customWhite rounded-[30px]`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        <h4
          className={`${
            award.id === 1 ? 'text-2xl md:text-5xl' : 'text-base md:text-2xl'
          } text-customWhite font-bold text-center break-all`}
        >
          <Trans id={award.title.id} />
        </h4>
      </div>
    </div>
  )
}

export default AwardCard
