import React from 'react'
import { useNavigate } from 'react-router-dom'
import { msg } from '@lingui/macro'
import Button from 'components/base/button'
import LanguageButton from 'components/base/language-button'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import MenuToggleButton from './buttons/MenuToggleButton'
import { MdOutlineAccountCircle } from 'react-icons/md'

interface NavbarBtnsProps {
  borderClass: string
  fillColor: string
  textClass: string
  toggleMenu: () => void
  menuOpen: boolean
}
const isMobile = () => window.innerWidth <= 768

const NavbarBtns: React.FC<NavbarBtnsProps> = ({
  borderClass,
  fillColor,
  textClass,
  toggleMenu,
  menuOpen
}) => {
  const navigate = useNavigate()

  const { token, verifiedAt } = useSelector((state: RootState) => state.auth)
  const isLoggedIn = !!token && !!verifiedAt
  const effectiveBorderClass = !isMobile() ? borderClass : 'border-naturalLv2'
  const effectiveTextClass = !isMobile() ? textClass : 'text-naturalLv2'
  const effectiveFillColor = !isMobile() ? fillColor : 'rgba(63, 63, 70, 1)'
  const placeholder = isLoggedIn ? msg`حسابي` : msg`تسجيل الدخول`

  const handleClick = () => {
    if (isLoggedIn) {
      navigate('/app/home')
    } else {
      navigate('/login')
    }
  }

  return (
    <div className="flex items-center *:mx-2 md:mb-4">
      <div className="flex items-center">
        <Button
          placeholder={placeholder}
          btnStyle="primary"
          className="w-32 hidden sm:block"
          onClick={handleClick}
        />
        <MdOutlineAccountCircle
          size={27}
          className="w-12 sm:hidden cursor-pointer text-gray-700 hover:text-primary hover:bg-gray-100 hover:rounded-full"
          onClick={handleClick}
        />

        <span className={` md:block border-r h-8 w-1 md:mx-2 ${effectiveBorderClass}`}></span>
        <LanguageButton fillColor={effectiveFillColor} textClass={effectiveTextClass} />
      </div>
      <div className={`lg:hidden flex items-center md:mx-0 ${effectiveTextClass}`}>
        <MenuToggleButton toggleMenu={toggleMenu} />
      </div>
    </div>
  )
}

export default NavbarBtns
