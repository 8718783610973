import { FaqCategory } from 'pages/landing/models'

import { msg } from '@lingui/macro'

const faqData: FaqCategory[] = [
  {
    category: msg`الاسئلة العامه`,
    questions: [
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      }
    ]
  },
  {
    category: msg`الملتقى الاثرائي`,
    questions: [
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      }
    ]
  },
  {
    category: msg`مواعيد مسار القارئ العام`,
    questions: [
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      }
    ]
  },
  {
    category: msg`تكاليف مسار القارئ العام`,
    questions: [
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      }
    ]
  },
  {
    category: msg`الترشيح لمسار القارئ العام`,
    questions: [
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      },
      {
        question: msg`كيف يمكنني المشاركة؟`,
        answer: msg`يمكنك المشاركة من خلال اختيار أيقونة "شارك الآن" تحت مسار قارئ العام في الموقع الإلكتروني للمسابقة  (iReadAward.com) بعد إنشاء حساب في الموقع.`
      }
    ]
  }
]

export default faqData
