import React from 'react'
import { Trans } from '@lingui/macro'

const IntroSection = () => {

  return (
    <div className="h-full bg-[#F9FAFB] flex flex-col text-center md:text-start justify-center ps-20 pe-8">
      <p className="text-[#52525B] font-ithra text-25 md:text-34 font-400"><Trans>مسابقة أقرأ</Trans></p>
      <p className="text-[#18181B] font-ithra text-20 md:text-40 font-400"><Trans>'انضم إلينا، وانطلق في رحلة نحو بحر من العلم والثقافة يملؤه الإثراء</Trans></p>
    </div>
  )
}

export default IntroSection
