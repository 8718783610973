import React from 'react'

interface StaticBackgroundProps {
    img: string;
}

const StaticBackground: React.FC<StaticBackgroundProps> = ({ img }) => {
  return (
    <div className="relative h-full flex flex-col md:flex-row">
    <div className={`w-full h-full`}>
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
    </div>
  </div>
  )
}

export default StaticBackground