import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { MeResponse } from 'api/types'

interface RegisterState {
  me: MeResponse | null
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  me: null,
  isLoading: false,
  error: null
}

export const fetchMe = createAsyncThunk('me', async () => {
  const response = await axiosInstance.get<MeResponse>('/auth/me')
  return response.data
})

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe: (state, action: PayloadAction<MeResponse>) => {
      const me = action.payload ?? null
      state.me = me
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMe.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.isLoading = false
        const me = action.payload ?? null
        state.me = me
      })
      .addCase(fetchMe.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setMe } = meSlice.actions

export default meSlice
