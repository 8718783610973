import React from 'react'
import { useLingui } from '@lingui/react'

interface ErrorMessageProps {
    msgstr: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ msgstr }) => {
    const { i18n } = useLingui()
  return (
    <div className='w-full h-14 px-4 flex items-center border rounded-xl bg-danger_inverted_fill_normal border-danger_inverted_stroke_normal'>
        <p className='font-sub-heading-h6-ar text-danger_default_lv1_text_normal_active_hover_pressed'>{i18n._(msgstr)}</p>
    </div>
  )
}

export default ErrorMessage