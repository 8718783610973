import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CreateCompetitionResponse } from 'api/types'

interface CreateCompetitionState {
  isLoading: boolean
  error: string | null
}

const initialState: CreateCompetitionState = {
  isLoading: false,
  error: null
}

export const createCompetition = createAsyncThunk(
  'createCompetition',
  async (
    {
      name,
      startDate,
      endDate,
      closedAt,
      status
    }: {
      name: string
      startDate: string
      endDate: string
      closedAt: string
      status: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived'
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<CreateCompetitionResponse>(
        '/management/competition-years',
        {
          name,
          start_date: startDate,
          end_date: endDate,
          close_at: closedAt,
          status,
          notification_enable: false,
          auto_start: true
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const createCompetitionSlice = createSlice({
  name: 'createCompetition',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createCompetition.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(createCompetition.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createCompetition.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default createCompetitionSlice
