import { i18n } from '@lingui/core'
import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/macro'
import { useEffect } from 'react'

const useDocumentTitle = (title?: MessageDescriptor): void => {
  useEffect(() => {
    const defaultTitle = `IRead | أقرأ`
    const fullTitle = title ? `${i18n._(msg`أقرأ`)} | ${i18n._(title)}` : defaultTitle
    document.title = fullTitle
  }, [title])
}

export default useDocumentTitle
