import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { WorkflowDetailResponse, WorkflowDetail } from 'api/types'

interface WorkflowDetailState {
  workflowDetail: WorkflowDetail | null
  isLoading: boolean
  error: string | null
}

const initialState: WorkflowDetailState = {
  workflowDetail: null,
  isLoading: false,
  error: null
}

export const fetchWorkflowDetail = createAsyncThunk(
  'workflowDetail/fetch',
  async ({ competitionId, workflowId }: { competitionId: number; workflowId: number }) => {
    const response = await axiosInstance.get<WorkflowDetailResponse>(
      `/management/competition-years/${competitionId}/workflows/${workflowId}`
    )
    return response.data
  }
)

const workflowDetailSlice = createSlice({
  name: 'workflowDetail',
  initialState,
  reducers: {
    setWorkflowDetail: (state, action: PayloadAction<WorkflowDetail>) => {
      state.workflowDetail = action.payload ?? null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflowDetail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchWorkflowDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.workflowDetail = action.payload.data ?? null
      })
      .addCase(fetchWorkflowDetail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setWorkflowDetail } = workflowDetailSlice.actions

export default workflowDetailSlice
