import {
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react'
import { useEffect } from 'react'
import { columns, useTranslatedTableHeaderColumns } from './columns'
import { RenderCell } from './RenderCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchActivateRequests, setPage } from 'store/slices/activateRequestsSlice'
import useLanguage from 'hooks/useLanguage'

const RequestsTable = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const language = useLanguage()

  const {
    requests,
    isLoading,
    // search,
    status,
    // sortDescriptor,
    page,
    rowsPerPage,
    fromSubmissionDate,
    toSubmissionDate,
    fromResponseDate,
    toResponseDate,
    ithraUsersIds,
    studentsNumberFrom,
    studentsNumberTo,
    pagination
  } = useSelector((state: RootState) => state.activateRequests)

  useEffect(() => {
    if (
      studentsNumberFrom === null ||
      studentsNumberTo === null ||
      (studentsNumberFrom !== null &&
        studentsNumberTo !== null &&
        studentsNumberTo > studentsNumberFrom)
    ) {
      dispatch(
        fetchActivateRequests({
          // search,
          status,
          // sortDescriptor,
          page,
          rowsPerPage,
          fromSubmissionDate,
          toSubmissionDate,
          fromResponseDate,
          toResponseDate,
          ithraUsersIds,
          studentsNumberFrom,
          studentsNumberTo
        })
      )
    }
  }, [
    dispatch,
    // search,
    status,
    // sortDescriptor,
    page,
    rowsPerPage,
    fromSubmissionDate,
    toSubmissionDate,
    fromResponseDate,
    toResponseDate,
    ithraUsersIds,
    studentsNumberFrom,
    studentsNumberTo
  ])

  const onChagePage = (page: number) => {
    dispatch(setPage(page))
  }

  const translatedTableHeaderColumns = useTranslatedTableHeaderColumns({ columns, language })
  const items = requests ?? []

  return (
    <div className="w-full flex flex-col gap-1">
      <Table
        aria-label="Example table with custom cells"
        bottomContent={
          pagination && (
            <Pagination
              showControls
              total={pagination?.total_pages}
              page={pagination?.current_page}
              onChange={onChagePage}
            />
          )
        }
      >
        <TableHeader columns={translatedTableHeaderColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              hideHeader={column.uid === 'actions'}
              align={column.uid === 'actions' ? 'center' : 'start'}
              dir={language === 'ar' ? 'rtl' : 'ltr'}
              className={
                language === 'ar'
                  ? 'text-right last:rounded-none first:rounded-none'
                  : 'text-left first:rounded-none last:rounded-none'
              }
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={items}
          emptyContent={t('No Requests!')}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow>
              {(columnKey) => (
                <TableCell>{RenderCell({ requestItem: item, columnKey: columnKey })}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mt-4" />
    </div>
  )
}

export default RequestsTable
