import React, { ReactNode } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { ZodSchema } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

interface FormProps {
  schema: ZodSchema
  onSubmit: (data: any) => Promise<void>
  defaultValues: any
  children: ReactNode
  className?: string
}

const Form: React.FC<FormProps> = ({ schema, onSubmit, defaultValues, children, className }) => {
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues
  })

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={`flex flex-col gap-6 ${className}`}
      >
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
