import Button from 'components/base/button'
import { Winner } from 'pages/landing/models'
import React, { useState } from 'react'
import { msg } from '@lingui/macro'
import { Trans } from '@lingui/react'

interface WinnerCardProps {
  winner: Winner
}

const WinnerCard: React.FC<WinnerCardProps> = ({ winner }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(42, 172, 173, 0), rgba(43, 173, 173, 1)), url(${winner.avatar})`,
    backgroundPosition: 'center',
    // backgroundSize: "cover",
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    transition: 'transform 2s ease-in-out',
    transform: isHovered ? 'scale(1.2)' : 'scale(1)'
  }

  return (
    <div className="group h-[440px] px-2.5">
      <div
        className="relative flex flex-col justify-end h-full w-[66vw] md:w-[323px] pb-12 rounded-xl transition-all ease-in-out duration-800 overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="absolute top-0 left-0 -z-10" style={backgroundStyle}></div>
        <div className=" flex flex-col space-y-4 text-center items-center px-4">
          <p className="text-3xl text-customWhite font-bold">
            <Trans id={winner.name.id} />
          </p>
          <p className="text-sm text-customWhite font-medium">
            <Trans id={winner.quote.id} />
          </p>
          <div className="block md:hidden group-hover:block transition-all ease-in-out duration-800">
            <Button placeholder={msg`مشاهدة التفاصيل`} btnStyle="primary" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WinnerCard
