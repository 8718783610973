import React, { ReactNode } from 'react'

interface MetricCardprops {
  children: ReactNode
}

const MetricCard: React.FC<MetricCardprops> = ({ children }) => {

  return (
    <div className="relative p-7 w-[280px] h-[185px] flex flex-col justify-center rounded-xl overflow-hidden bg-natural_fill_normal_hover_pressed_active">
      <div className="absolute top-0 left-0 h-[5.5px] w-full bg-natural_lv1_text_normal"/>
      {children}
    </div>
  )
}

export default MetricCard
