import { WinnersList } from 'pages/landing/models'
import img1 from 'assets/images/winner-1.png'
import img2 from 'assets/images/winner-2.png'
import img3 from 'assets/images/winner-avtar-female.png'
import img4 from 'assets/images/winner-avtar-male.png'
import { msg } from '@lingui/macro'

const winnersList: WinnersList = {
  2024: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2023: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2022: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2021: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2020: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2019: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2018: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2017: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2016: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2015: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2014: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img1
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img2
    }
  ],
  2013: [
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img3
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img4
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img3
    },
    {
      name: msg`راما السباعي`,
      quote: msg`أقرأ لأعي، لأفهم نفسي والعالم أكثر`,
      avatar: img4
    }
  ]
}
export default winnersList
