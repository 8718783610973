import React, { useState, useEffect } from 'react'
import FileCard from './file-card'
import SupplementaryFilesData from './category-list'
import CustomPagination from './CustomPagination'

interface FilesSectionProps {
  selectedCategory: string
}

const FilesSection: React.FC<FilesSectionProps> = ({ selectedCategory }) => {
  const itemsPerPage = 8
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredFiles, setFilteredFiles] = useState<any[]>([])
  const [paginatedFiles, setPaginatedFiles] = useState<any[]>([])
  const [isFading, setIsFading] = useState(false)

  useEffect(() => {
    setIsFading(true)
    const timer = setTimeout(() => {
      const filesToDisplay = SupplementaryFilesData.flatMap(({ category, files }) =>
        selectedCategory === '-1' || String(category?.id) === selectedCategory
          ? files.map(({ id, name, description, file }) => ({
              id,
              name: name.id,
              description: description.id,
              file,
              icon: category?.icon
            }))
          : []
      )
      setFilteredFiles(filesToDisplay)
      setCurrentPage(1)
      setIsFading(false)
    }, 300)
    return () => clearTimeout(timer)
  }, [selectedCategory])

  useEffect(() => {
    const startIdx = (currentPage - 1) * itemsPerPage
    const endIdx = startIdx + itemsPerPage
    setPaginatedFiles(filteredFiles.slice(startIdx, endIdx))
    const navbarElement = document.getElementById('file-navbar')
    if (navbarElement) {
      navbarElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentPage, filteredFiles])

  const totalPages = Math.ceil(filteredFiles.length / itemsPerPage)

  return (
    <div className="pb-12 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center transition-opacity duration-300 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        {paginatedFiles.map(({ id, name, description, file, icon }) => (
          <FileCard
            key={id}
            title={name}
            icon={icon}
            description={description}
            fileLink={file}
            className="mx-auto"
          />
        ))}
      </div>

      {totalPages > 1 && (
        <div className="mt-6 flex justify-center lg:justify-end">
          <CustomPagination
            total={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  )
}

export default FilesSection
