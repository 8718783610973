import React, { createContext, useContext, ReactNode } from 'react'

type ScrollContextType = (section: string) => void

const ScrollContext = createContext<ScrollContextType | undefined>(undefined)

const SCROLL_OFFSET = 128

export const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext)
  if (!context) {
    throw new Error('useScrollContext must be used within a ScrollProvider')
  }
  return context
}

interface ScrollProviderProps {
  children: ReactNode
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
  const scrollToSection = (section: string) => {
    const sectionElement = document.getElementById(section)
    if (sectionElement) {
      const elementPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset
      const offsetPosition = elementPosition - SCROLL_OFFSET

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }

  return <ScrollContext.Provider value={scrollToSection}>{children}</ScrollContext.Provider>
}
