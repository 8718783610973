import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchUniversities } from 'store/slices/universitiesSlice'

interface UseUniversitiesProps {
  countryId?: string
  regionId?: string // Single region ID
}

export const useUniversities = ({ countryId, regionId }: UseUniversitiesProps = {}) => {
  const dispatch = useDispatch<AppDispatch>()
  const { universities, isLoading } = useSelector((state: RootState) => state.universities)

  // Memoize the countryId and regionId to prevent unnecessary re-renders
  const memoizedCountryId = useMemo(() => countryId, [countryId])
  const memoizedRegionId = useMemo(() => regionId, [regionId])

  useEffect(() => {
    // Prevent unnecessary API calls if neither countryId nor regionId is set
    if (memoizedCountryId || memoizedRegionId) {
      dispatch(
        fetchUniversities({
          countryId: memoizedCountryId,
          regionsIds: memoizedRegionId ? [memoizedRegionId] : [] // Convert regionId to array
        })
      )
    }
  }, [dispatch, memoizedCountryId, memoizedRegionId])

  return { universities, isLoading }
}
