import LocalizedNameText, { LocalizedName } from 'components/LocalizedNameText'
import useLanguage from 'hooks/useLanguage'

export default function LocalizedNameTableCell({
  localizedName
}: {
  localizedName: LocalizedName | null
}) {
  const language = useLanguage()

  return <LocalizedNameText localizedName={localizedName} language={language} />
}
