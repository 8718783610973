import useLanguage from 'hooks/useLanguage'
import { useEducationAdministrations } from './get-education-adminstrations'
import { getLocalizedName } from '../utils'

interface UseEducationAdministrationOptionsProps {
  countryId?: string
  regionId?: string // Array of region IDs
}

export const useEducationAdministrationOptions = ({
  countryId,
  regionId
}: UseEducationAdministrationOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { educationAdministrations, isLoading } = useEducationAdministrations({
    countryId,
    regionId
  }) // Fetch based on countryId and regions

  // Create options for the select field
  const options =
    educationAdministrations.length === 0
      ? [
          {
            key: '', // Empty value for the placeholder option
            label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
          }
        ]
      : educationAdministrations.map((school: any) => ({
          key: String(school.id),
          label: getLocalizedName(school.name, language)
        }))

  return { options, isLoading }
}
