import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CompleteCoordinatorResponse } from 'api/types'

interface CompleteCoordinatorState {
  isLoading: boolean
  error: string | null
}

const initialState: CompleteCoordinatorState = {
  isLoading: false,
  error: null
}

export const completeCoordinator = createAsyncThunk(
  'completeCoordinator',
  async (formData: any, thunkAPI) => {
    try {
      const response = await axiosInstance.post<CompleteCoordinatorResponse>(
        '/auth/register/complete-coordinator',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const completeCoordinatorSlice = createSlice({
  name: 'completeCoordinator',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(completeCoordinator.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(completeCoordinator.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(completeCoordinator.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default completeCoordinatorSlice
