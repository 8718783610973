import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CreateCompetitionResponse } from 'api/types'

interface UpdateCompetitionState {
  isLoading: boolean
  error: string | null
}

const initialState: UpdateCompetitionState = {
  isLoading: false,
  error: null
}

export const updateCompetition = createAsyncThunk(
  'updateCompetition',
  async (
    {
      id,
      name,
      startDate,
      endDate,
      status,
      notification_enable,
      notification_days,
      notification_message,
      auto_start,
      close_at
    }: {
      id: number
      name: string
      startDate: string
      endDate: string
      status: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived'
      notification_enable: boolean
      notification_days: number
      notification_message: string
      auto_start: boolean
      close_at: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.put<CreateCompetitionResponse>(
        `/management/competition-years/${id}`,
        {
          name,
          start_date: startDate,
          end_date: endDate,
          status,
          notification_enable,
          notification_days,
          notification_message,
          auto_start,
          close_at
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const updateCompetitionSlice = createSlice({
  name: 'updateCompetition',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateCompetition.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateCompetition.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateCompetition.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default updateCompetitionSlice
