import React from 'react';

interface DynamicBackgroundProps {
  img: string;
  overlay: string;
  isActive: boolean;
  children: React.ReactNode;
}

const DynamicBackground: React.FC<DynamicBackgroundProps> = ({ img, overlay, isActive, children }) => {
  return (
    <div className="relative w-full h-full flex md:items-end justify-center">
      <div
        className="absolute inset-0 bg-top bg-cover"
        style={{
          backgroundImage: `url(${img})`,
        }}
      />
      <div
        className="absolute inset-0"
        style={{ backgroundColor: overlay }}
      />
      {!isActive && (
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity ease-in-out duration-700" />
      )}
      {children}
    </div>
  );
};

export default DynamicBackground;
