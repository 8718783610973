import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ResetPasswordResponse } from 'api/types'

interface ResetPasswordState {
  isLoading: boolean
  error: string | null
}

const initialState: ResetPasswordState = {
  isLoading: false,
  error: null
}

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (
    {
      email,
      token,
      password,
      password_confirmation
    }: {
      email: string
      token: string
      password: string
      password_confirmation: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<ResetPasswordResponse>('/auth/reset-password', {
        email: email,
        token: token,
        password: password,
        password_confirmation: password_confirmation,
        guard: 'users'
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default resetPasswordSlice
