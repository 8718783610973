import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const usePersonalInfoSchema = (
  accountType: 'student' | 'teacher' | 'coordinator' | 'ithraa' | 'admin' | 'judge' | null,
  educationStage: string | null,
  isLocatedInSA: boolean,
  school: string | null,
  university: string | null
) => {
  const { _ } = useLingui()

  return z.object({
    first_name: z
      .string()
      .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
      .regex(/^[A-Za-z\s\u0600-\u06FF]+$/, { message: _(msg`يجب أن يحتوي الاسم على حروف فقط`) }), // Updated regex

    middle_name: z
      .string()
      .regex(/^[A-Za-z\s\u0600-\u06FF]*$/, {
        message: _(msg`يجب أن يحتوي الاسم الأوسط على حروف فقط`)
      }) // Updated regex
      .optional(),

    last_name: z
      .string()
      .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
      .regex(/^[A-Za-z\s\u0600-\u06FF]+$/, { message: _(msg`يجب أن يحتوي الاسم على حروف فقط`) }), // Updated regex

    // Conditional fields based on accountType
    gender:
      accountType === 'student' || accountType === 'teacher' || accountType === 'coordinator'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
        : z.string().optional(),

    phone_number:
      accountType === 'student' || accountType === 'teacher' || accountType === 'coordinator'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
        : z.string().optional(),

    date_of_birth:
      accountType === 'student' || accountType === 'teacher' || accountType === 'coordinator'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .refine((value) => !isNaN(Date.parse(value)), { message: _(msg`ادخل تاريخ صحيحا`) })
        : z.string().optional(),

    country:
      accountType === 'student' || accountType === 'teacher' || accountType === 'coordinator'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    nationality:
      accountType === 'student' || accountType === 'teacher' || accountType === 'coordinator'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    // Region is required for student or teacher
    region_id:
      accountType === 'student' || accountType === 'teacher'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    // Education stage is required for student or teacher
    education_stage:
      accountType === 'student' || accountType === 'teacher'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    // Education level is required for students if educationStage is "general"
    education_level:
      accountType === 'student' && educationStage === 'general'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    // Education administration is required for students and teachers in Saudi Arabia with educationStage "general"
    education_administration_id:
      isLocatedInSA && educationStage === 'general'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    school_id:
      educationStage === 'general'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    university_id:
      educationStage === 'university'
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
            .nullable()
        : z.string().optional(),

    // School or university name is required when educationStage is "general" and school is "other", or educationStage is "university" and university is "other"
    school_university_name:
      (educationStage === 'general' && school === 'other') ||
      (educationStage === 'university' && university === 'other')
        ? z
            .string({ message: _(msg`هذا الحقل مطلوب`) })
            .nonempty({ message: _(msg`هذا الحقل مطلوب`) })
        : z.string().optional()
  })
}
