import { User } from 'pages/AccountPages/types';
import Student from 'assets/images/Student.png'
import Teacher from 'assets/images/Teacher.png'
import Coordinator from 'assets/images/Coordinator.png'
import { msg } from '@lingui/macro'

export const userList: User[] = [
    {
      id: "MRP_1",
      type: "student",
      label: "الطالب | Student",
      modalTitle: msg`إنشاء حساب لطالب`,
      description: msg`خاص بالطلاب والطالبات في المدارس والجامعات الراغبين بالمشاركة في مسار قارئ العام`,
      background: {
        img: Student,
        overlay: "rgba(43,173,173,0.56)",
      },
    },
    {
      id: "MRP_2",
      type: "teacher",
      label: "المحاضر | Teacher",
      modalTitle: msg`إنشاء حساب للمعلم`,
      description: msg`خاص بالمعلمين والمعلمات وأعضاء هيئة التدريس الراغبين بالمشاركة في مسار سفراء القراءة`,
      background: {
        img: Teacher,
        overlay: "rgba(114,91,167,0.56)",
      },
    },
    {
      id: "MRP_3",
      type: "coordinator",
      label: "المنسق | Coordinator",
      modalTitle: msg`إنشاء حساب للمنسق`,
      description: msg`خاص بالمشرفين الذين تعينهم وزارة التعليم أو الجامعات لتمثيل إداراتهم التعليمية في المدارس أو الجامعات ومتابعة الطلاب المشاركين في المسابقة`,
      background: {
        img: Coordinator,
        overlay: "rgba(126,205,51,0.50)",
      },
    },
  ];