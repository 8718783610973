import React from 'react'
import backgroundImage from 'assets/images/podcast.png'
import YouTubeVideo from 'components/base/youtube-video'
import { Trans } from '@lingui/macro'

const PodcastCard: React.FC = () => {
  return (
    <div className="flex flex-row md:flex-col items-start p-3 md:p-4 w-full rounded-xl hover:bg-customTeal10">
      <div className="relative w-1/2  md:w-full">
        <YouTubeVideo videoId="" fallbackImg={backgroundImage} altText="Marathon" />
      </div>
      <div className="md:mt-4 mx-4 md:mx-0">
        <h3 className="text-teal-600 text-lg font-semibold">
          <Trans>ماراثون أقرأ ب'إثراء'</Trans>
        </h3>
        <p className="text-naturalLv2 mt-2">
          <Trans>في مسابقة أقرأ من إثراء 2024 Mar 22</Trans>
        </p>
      </div>
    </div>
  )
}

export default PodcastCard
