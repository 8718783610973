interface EducationStageOption {
  value: string
  labelEn: string
  labelAr: string
}

export const educationStageOptions: EducationStageOption[] = [
  { value: 'general', labelEn: 'General', labelAr: 'عام' },
  { value: 'university', labelEn: 'University', labelAr: 'جامعي' }
]

type Language = 'en' | 'ar'

export const getEducationStageOptions = (lang: Language) => {
  return educationStageOptions.map((option) => ({
    key: option.value,
    label: lang === 'en' ? option.labelEn : option.labelAr
  }))
}

export const getEducationStageLabel = (value: string, lang: Language) => {
  const option = educationStageOptions.find((option) => option.value === value)
  return option ? (lang === 'en' ? option.labelEn : option.labelAr) : ''
}

export const getEducationStageValue = (value: string) => {
  const option = educationStageOptions.find((option) => option.value === value)
  return option?.value ?? ''
}
