import React from 'react'
// import { Trans } from '@lingui/macro'

import femaleStudentAvatar from 'assets/images/femaleStudent.png'
import maleStudentAvatar from 'assets/images/maleStudent.png'
import femaleTeacherAvatar from 'assets/images/Female.png'
import maleTeacherAvatar from 'assets/images/male.png'
import femaleCoordinatorAvatar from 'assets/images/Female.png'
import maleCoordinatorAvatar from 'assets/images/male.png'
import ithraaAvatar from 'assets/images/ithraaUser.png'
import adminAvatar from 'assets/images/ithraaUser.png'
import judgeAvatar from 'assets/images/ithraaUser.png'

interface UserAvatarProps {
  isColumnLayout?: boolean
  isCollapsed?: boolean
  name?: string
  account_type?: 'student' | 'teacher' | 'coordinator' | 'ithraa' | 'admin' | 'judge' | string | null
  gender?: 'male' | 'female' | string
}

const UserAvatar: React.FC<UserAvatarProps> = ({ isColumnLayout, isCollapsed, name, account_type, gender }) => {
  const avatarMap: Record<string, Record<string, string>> = {
    student: {
      male: maleStudentAvatar,
      female: femaleStudentAvatar
    },
    teacher: {
      male: maleTeacherAvatar,
      female: femaleTeacherAvatar
    },
    coordinator: {
      male: maleCoordinatorAvatar,
      female: femaleCoordinatorAvatar
    },
    ithraa: {
      default: ithraaAvatar
    },
    admin: {
      default: adminAvatar
    },
    judge: {
      default: judgeAvatar
    }
  }

  const validAccountType = account_type && avatarMap[account_type] ? account_type : undefined

  const avatarImage =
    validAccountType && gender && avatarMap[validAccountType as keyof typeof avatarMap]?.[gender]
      ? avatarMap[validAccountType as keyof typeof avatarMap][gender]
      : avatarMap[validAccountType as keyof typeof avatarMap]?.default

  return (
    <div className={`w-full flex items-center gap-4 ${isColumnLayout? 'flex-col' : 'flex-row'}`}>
      {avatarImage ? (
        <img
          src={avatarImage}
          alt={`${account_type} avatar`}
          className={`rounded-full transition-all duration-300  w-10 h-10
            ${ !isColumnLayout ? 'w-8 h-8' : '' }
            ${ isColumnLayout && isCollapsed? 'w-10 h-10' : '' }
            ${ isColumnLayout && !isCollapsed? 'w-28 h-28' : '' }
            `}
        />
      ) : (
        <div
          className={`rounded-full bg-[#2AACAD14] transition-all duration-300 ${
            isCollapsed ? 'w-10 h-10' : 'w-28 h-28'
          }`}
        ></div>
      )}
      {!isCollapsed && (
        <div className="flex items-center flex-col">
          <p className={`${ isColumnLayout ? 'font-heading-h5-ar' : 'font-body-h6' } text-natural_icon_normal capitalize`}>{name}</p>
          {account_type && (
            <p className={`${ isColumnLayout ? 'font-heading-h6-ar' : 'font-body-h6' } text-natural_icon_normal capitalize`}>
              {account_type}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default UserAvatar
