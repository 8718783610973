import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { fetchMe } from 'store/slices/meSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import Header from './header'
import Sidebar from './sidebar'
import routesConfig from './routesConfig'
import Breadcrumb from './components/Breadcrumb'

interface LayoutProps {
  userRole: string
}

const Layout: React.FC<LayoutProps> = ({ userRole }) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch])

  return (
    <div className="h-screen">
      <div className="size-full bg-natural_fill_normal_hover_pressed_active flex flex-col">
        <Header />

        <div className="flex-grow flex flex-row mx-2 pb-2 md:pb-5 md:mx-10 gap-[26px]">
          <Sidebar userRole={userRole} routes={routesConfig} />

          <div className="h-full w-full flex flex-col">
            <Breadcrumb breadcrumbs={routesConfig} />
            <main className="flex-grow p-6 md:px-10 md:py-12 rounded-[22px] shadow-xl bg-white">
                <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
