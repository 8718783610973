import { Card, CardFooter } from '@nextui-org/react'
import { FiDownload } from 'react-icons/fi'
import { msg } from '@lingui/macro'
import Button from 'components/base/button'
import { IconType } from 'react-icons'
import { Trans } from '@lingui/react'

interface SupplementaryFileCardProps {
  title: string
  description: string
  fileLink: string
  icon?: IconType
  className?: string
}

const SupplementaryFileCard: React.FC<SupplementaryFileCardProps> = ({
  title,
  description,
  fileLink,
  icon: Icon,
  className = ''
}) => {
  return (
    <Card
      className={`relative w-full max-w-[314px] min-h-[172px] rounded-lg shadow-md border border-gray-200 overflow-hidden ${className}`}
    >
      <div className="flex items-center p-0">
        <div className="flex-1 flex flex-row items-start justify-between pt-2">
          <div className="p-4 flex-grow pb-16">
            <h2 className="text-natural_lv2_text_normal font-label-h5">
              <Trans id={title} />
            </h2>
            <p className="text-natural_lv2_text_normal font-body-sm">
              <Trans id={description} />
            </p>
          </div>

          <div className="w-[55px] h-[40px] flex-shrink-0 flex items-center justify-center bg-tertiary_normal ltr:rounded-l-lg rtl:rounded-r-lg mt-3">
            {Icon && <Icon size={24} color={'white'} />}
          </div>
        </div>
      </div>

      <CardFooter className="absolute bottom-0 left-0 right-0 p-4 justify-end">
        <Button
          placeholder={msg`تنزيل الملف`}
          btnStyle="default"
          onClick={() => console.log('')}
          customIcon={<FiDownload size={18} />}
        />
      </CardFooter>
    </Card>
  )
}

export default SupplementaryFileCard
