import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Coordinator } from 'api/types'

export type userTypeEnum =
  | 'student'
  | 'teacher'
  | 'coordinator'
  | 'ithraa'
  | 'admin'
  | 'judge'
  | string

export interface AuthState {
  userType: userTypeEnum | null
  email: string | null
  verifiedAt: string | null
  token: string | null
  isCoordinatorApproved: string | boolean
  locatedInId: string | null

  coordinator: string | null
}

const initialState: AuthState = {
  userType: (localStorage.getItem('userType') as userTypeEnum) || null,
  token: (localStorage.getItem('token') as string) || null,
  verifiedAt: (localStorage.getItem('verifiedAt') as string) || null,
  email: (localStorage.getItem('email') as string) || null,
  isCoordinatorApproved: (localStorage.getItem('isCoordinatorApproved') as string) || false,
  locatedInId: (localStorage.getItem('locatedInId') as string) || null,
  coordinator: (localStorage.getItem('coordinator') as string) || null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserType: (state, action: PayloadAction<userTypeEnum | null>) => {
      state.userType = action.payload
      if (action.payload) {
        localStorage.setItem('userType', action.payload)
      }
    },
    setToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem('token', action.payload)
      state.token = action.payload
    },
    setVerifiedAt: (state, action: PayloadAction<string | null>) => {
      state.verifiedAt = action.payload
      if (action.payload) {
        localStorage.setItem('verifiedAt', action.payload)
      }
    },
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload
      if (action.payload) {
        localStorage.setItem('email', action.payload)
      }
    },
    setIsCoordinatorApproved: (state, action: PayloadAction<string | boolean>) => {
      state.isCoordinatorApproved = action.payload
      if (action.payload) {
        localStorage.setItem('isCoordinatorApproved', action.payload.toString())
      }
    },
    setLocatedInId: (state, action: PayloadAction<string | null>) => {
      state.locatedInId = action.payload
      if (action.payload) {
        localStorage.setItem('locatedInId', action.payload.toString())
      }
    },
    setCoordinator: (state, action: PayloadAction<Coordinator | boolean>) => {
      if (action.payload) {
        state.coordinator = JSON.stringify(action.payload)
        localStorage.setItem('coordinator', JSON.stringify(action.payload))
      }
    },
    resetAuth: (state) => {
      state.userType = null
      state.token = null
      state.verifiedAt = null
      state.email = null
      state.isCoordinatorApproved = false
      state.locatedInId = null
      state.coordinator = null
      // Optionally remove items from local storage
      localStorage.removeItem('userType')
      localStorage.removeItem('token')
      localStorage.removeItem('verifiedAt')
      localStorage.removeItem('email')
      localStorage.removeItem('isCoordinatorApproved')
      localStorage.removeItem('locatedInId')
      localStorage.removeItem('coordinator')
    }
  }
})

export const {
  setUserType,
  setToken,
  setVerifiedAt,
  setEmail,
  setIsCoordinatorApproved,
  setLocatedInId,
  setCoordinator,
  resetAuth
} = authSlice.actions

export default authSlice
