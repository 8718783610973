import img1 from 'assets/images/marathon-1.png'
import img2 from 'assets/images/marathon-2.png'
import img3 from 'assets/images/marathon-2.png'
import { Event } from '../../models'
import { msg } from '@lingui/macro'

const events: Event[] = [
  {
    id: 1,
    title: msg`ماراثون أقرأ بـ”إثراء“ أشعل روح المنافسة داخل الكينونة الإنسانية العميقة`,
    date: msg`2024 Mar 22`,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img1
  },
  {
    id: 2,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    date: msg`2024 Mar 22`,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img2
  },
  {
    id: 3,
    title: msg`ماراثون أقرأ بـ”إثراء“`,
    date: msg`2024 Mar 22`,
    description: msg`ساعات متواصلة عبر ثلاثة أيام متتالية شهد ماراثون أقرأ الذي أقامه مركز الملك عبد العزيز الثقافي العالمي (إثراء) تفاعلاً لافتاً من شتى أنحاء المملكة العربية السعودية.`,
    image: img3
  }
]

export default events
