import React from 'react'
import TwitterIcon from 'assets/icons/twitter-icon.svg'
import YouTubeIcon from 'assets/icons/youtube-icon.svg'
import FacebookIcon from 'assets/icons/facebook-icon.svg'
import LinkedInIcon from 'assets/icons/linkedin-icon.svg'
import sheildIcon from 'assets/icons/sheild-icon.svg'
import Link from 'components/base/link'
import { msg, Trans } from '@lingui/macro'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear() // Get the current year
  const linkClass: string = 'text-naturalLv1 font-medium'
  const headerClass: string = 'text-naturalLv1 font-bold text-xl'
  const sectionClass: string =
    'w-full md:w-1/4 text-start md:text-center md:text-start flex flex-col space-y-4'

  return (
    <footer className="border-t border-customTeal py-8">
      <div className="relative w-10/12 mx-auto flex flex-wrap justify-between space-y-4 lg:space-y-0">
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>مسابقة أقرأ</Trans>
          </h4>
          <ul className="space-y-1">
            <li>
              <Link placeholder={msg`عن أقرأ`} className={linkClass} />
            </li>
            <li>
              <Link placeholder={msg`مسارات المسابقة`} className={linkClass} />
            </li>
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>روابط هامة</Trans>
          </h4>
          <ul className="space-y-1">
            <li>
              <Link placeholder={msg`الملفات المساعدة`} className={linkClass} />
            </li>

            {/* <li>
              <Link placeholder={msg`الشروط والأحكام العامة`} className={linkClass} />
            </li> */}
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>تواصل معنا</Trans>
          </h4>
          <ul className="space-y-1">
            <li>
              <Link placeholder={msg`اتصل بنا`} className={linkClass} />
            </li>
            <li>
              <Link placeholder={msg`info@ireadaward.com`} className={linkClass} />
            </li>
          </ul>
        </div>
        <div className={sectionClass}>
          <h4 className={headerClass}>
            <Trans>التواصل الاجتماعي</Trans>
          </h4>
          <div className="flex *:mx-2">
            <a href="https://twitter.com">
              <img src={TwitterIcon} alt="Twitter" />
            </a>
            <a href="https://youtube.com">
              <img src={YouTubeIcon} alt="YouTube" />
            </a>
            <a href="https://facebook.com">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            <a href="https://linkedin.com">
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>
      <div className="absolute right-4 md:right-20 lg:right-40 p-4">
        <img src={sheildIcon} alt="Shield" />
      </div>
      <div className="mt-8 border-t border-customTeal pt-4 text-center text-sm">
        <div className="w-10/12 mx-auto flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center justify-between">
          <div className="md:mt-2 md:*:mx-1 flex flex-col md:flex-row md:items-center">
            <div className="flex *:mx-1 items-center h-8">
              <span className="md:hidden mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`سياسة الخصوصية`}
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
            <div className="flex *:mx-1 items-center h-8">
              <span className="mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`شروط الاستخدام`}
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
            <div className="flex *:mx-1 items-center h-8">
              <span className="mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`إخلاء مسؤولية عن التطوير`}
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
            <div className="flex *:mx-1 items-center h-8">
              <span className="mb-8 text-5xl text-customTeal leading-none flex items-center">
                ،
              </span>
              <Link
                placeholder={msg`سياسة ملفات التعريف`}
                className="text-naturalLv1 font-bold text-sm"
              />
            </div>
          </div>
          <p className="text-naturalLv2">
            © {currentYear}{' '}
            <Trans>
              مركز الملك عبدالعزيز الثقافي العالمي، شركة أرامكو السعودية. جميع الحقوق محفوظة
            </Trans>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
