import { FC } from 'react'
import { DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, Divider } from '@nextui-org/react'
import ArrowDawnIcon from 'assets/icons/ArrowDawnIcon'
import { useWindowSize } from 'usehooks-ts'
import PersonSecondIcon from 'assets/icons/PersonSecondIcon'
import LogoutIcon from 'assets/icons/LogoutIcon'
import { logout } from 'store/slices/logoutSlice'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UserAvatar from 'pages/auth/components/UserAvatar'
import { Trans } from '@lingui/macro'

interface UserDropDownProps {
  name: string
  account_type: 'student' | 'teacher' | 'coordinator' | 'ithraa' | 'admin' | 'judge' | null
}

const UserDropDown: FC<UserDropDownProps> = (props) => {
  const data = useSelector((state: RootState) => state.me?.me?.data)

  // const { name = 'Guest', account_type = 'guest' } = props
  const { width = 0 } = useWindowSize()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const isMobile = width <= 430

  const handleLogout = async () => {
    await dispatch(logout())
    navigate('/login')
  }
  const handleProfile = async () => {
    navigate('/app/account-profile')
  }
  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <div className=" flex flex-row justify-start items-center gap-4 cursor-pointer">
          
          <UserAvatar name={data?.name} account_type={data?.account_type} gender={data?.gender} />

          {!isMobile && <ArrowDawnIcon />}
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label="" variant="flat">
        <DropdownItem onClick={handleProfile}>
          <div className="flex flex-row items-center gap-2">
            <PersonSecondIcon />
            <p className="text-normal font-normal"><Trans>حسابي</Trans></p>
          </div>
        </DropdownItem>
        <DropdownItem className="pointer-events-none hover:bg-transparent focus:bg-transparent">
          <Divider />
        </DropdownItem>
        <DropdownItem onClick={handleLogout}>
          <div className="flex flex-row items-center gap-2">
            <LogoutIcon />
            <p className="text-normal font-normal"><Trans>تسجيل الخروج</Trans></p>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default UserDropDown
