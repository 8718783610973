import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type activateRequestsActiveTabType =
  | 'activateRequest'
  | 'coordinatorDetail'
  | 'requestActivites'

type TabsState = {
  activeTab: activateRequestsActiveTabType
}

const initialState: TabsState = {
  activeTab: 'activateRequest'
}

const activateRequestsTabsSlice = createSlice({
  name: 'activateRequestsTabs',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<activateRequestsActiveTabType>) => {
      state.activeTab = action.payload
    }
  }
})

export const { setActiveTab } = activateRequestsTabsSlice.actions
export default activateRequestsTabsSlice
