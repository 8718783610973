import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'

interface DeleteWorkflowState {
  isLoading: boolean
  error: string | null
}

const initialState: DeleteWorkflowState = {
  isLoading: false,
  error: null
}

// Async thunk to delete a workflow
export const deleteWorkflow = createAsyncThunk<
  any,
  { competitionId: number; workflowId: number },
  { rejectValue: string }
>('workflow/deleteWorkflow', async ({ competitionId, workflowId }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete<any>(
      `/management/competition-years/${competitionId}/workflows/${workflowId}`
    )
    return response.data // response will be of type `any`
  } catch (error: any) {
    // Handle errors and return a rejectWithValue for better error handling
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message)
    }
    return rejectWithValue('Failed to delete workflow')
  }
})

const deleteWorkflowSlice = createSlice({
  name: 'deleteWorkflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteWorkflow.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteWorkflow.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteWorkflow.rejected, (state, action) => {
        state.isLoading = false
        state.error = (action.payload as string) || 'Failed to delete workflow'
      })
  }
})

export default deleteWorkflowSlice
