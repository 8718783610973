import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { toast } from 'react-toastify'
import i18n from 'locales/i18n'
import { fetchWorkflowDetail } from 'store/slices/workflowDetailsSlice'
import { fetchWorkflows } from 'store/slices/workFlowSlice'
import { updateWorkflow } from 'store/slices/updateWorkflowSlice'
import TextInput from 'components/hook-form/text-input'
import SelectInput from 'components/hook-form/select-input'
import DateInput from 'components/hook-form/date-input'
import ButtonVariant from 'components/button-variant/button'
import { createWorkflow } from 'store/slices/createWorkflowSlice'

type CreateWorkflowType = {
  workflowId?: string
  competitionId: string
  isOpen: boolean
  onOpenChange: () => void
}

export const useStatusOptions = () => {
  const { t } = useTranslation()
  return [
    { key: 'active', label: t('Active') },
    { key: 'archived', label: t('Archived') }
  ]
}

export const CreateAndUpdateWorkflow: FC<CreateWorkflowType> = (props) => {
  const { isOpen, onOpenChange, competitionId, workflowId } = props
  const { t } = useTranslation()
  const isLoading = useSelector((state: RootState) => state.createWorkflow.isLoading)
  const isLoadingUpdate = useSelector((state: RootState) => state.updateWorkflow.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const statusOptions = useStatusOptions()

  const createWorkflowSchema = z.object({
    name: z.string().nonempty({ message: t('Name is required') }),
    description: z.string().nonempty({ message: t('Description is required') }),
    status: z.string().nonempty({ message: t('Status is required') }),
    endDate: z.string().nonempty({ message: t('End date is required') })
  })

  type CreateWorkflowSchemaType = z.infer<typeof createWorkflowSchema>

  const methods = useForm<CreateWorkflowSchemaType>({
    resolver: zodResolver(createWorkflowSchema),
    defaultValues: {
      name: '',
      description: '',
      status: '',
      endDate: ''
    }
  })

  const {
    handleSubmit,
    clearErrors,
    trigger,
    reset,
    setValue,
    formState: { isSubmitted }
  } = methods

  useEffect(() => {
    if (workflowId) {
      // Fetch the workflow details when workflowId exists
      dispatch(
        fetchWorkflowDetail({
          competitionId: Number(competitionId),
          workflowId: Number(workflowId)
        })
      )
        .unwrap()
        .then((data) => {
          if (data) {
            setValue('name', data.data.name)
            setValue('description', data.data.description)
            setValue('status', data.data.status)
            setValue('endDate', data.data.end_date)
          }
        })
        .catch((error) => {
          console.error('Failed to fetch workflow details', error)
        })
    } else {
      reset({
        name: '',
        description: '',
        status: '',
        endDate: ''
      })
    }
  }, [workflowId, dispatch, competitionId, setValue, reset])

  const onCreate = async (data: CreateWorkflowSchemaType) => {
    try {
      console.log(data)
      await dispatch(
        createWorkflow({
          competitionId,
          name: data.name,
          description: data.description,
          status: data.status as 'active' | 'archived',
          end_date: data.endDate
        })
      ).unwrap()
      await dispatch(fetchWorkflows({ competitionId })).unwrap()
      reset()
      onOpenChange()
    } catch (error: any) {
      toast.dismiss()
      toast.error(error.message ?? '')
    }
  }

  const onUpdate = async (data: CreateWorkflowSchemaType) => {
    try {
      await dispatch(
        updateWorkflow({
          competitionId: Number(competitionId),
          workflowId: Number(workflowId),
          name: data.name,
          description: data.description,
          end_date: data.endDate, // Mapping endDate to end_date
          status: data.status as 'active' | 'archived' // Ensure status is correctly typed
        })
      ).unwrap()
      await dispatch(fetchWorkflows({ competitionId })).unwrap()
      reset()
      onOpenChange()
    } catch (error: any) {
      toast.dismiss()
      toast.error(error.message ?? '')
    }
  }

  const onSubmit = (data: CreateWorkflowSchemaType) => {
    if (workflowId) {
      onUpdate(data)
    } else {
      onCreate(data)
    }
  }

  useEffect(() => {
    const handleLanguageChange = () => {
      clearErrors()
      if (isSubmitted) {
        trigger()
      }
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [clearErrors, trigger, isSubmitted])

  return (
    <Modal
      hideCloseButton={true}
      size="xl"
      placement="center"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent className="min-h-[60vh] pt-12">
        <ModalHeader className="flex flex-col items-center gap-1 mb-8">
          <p className="font-bold text-2xl">{t(workflowId ? 'Edit Workflow' : 'Add Workflow')}</p>
          <p className="font-normal text-sm">
            {t('Please enter the required information to share to add it')}
          </p>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <TextInput name="name" label={t('Name')} size="lg" />
              <TextInput name="description" label={t('Description')} size="lg" />
              <SelectInput
                name="status"
                size="lg"
                label={t('Status')}
                options={statusOptions}
                selectionMode="single" // or "multiple"
                placeholder={t('Select status')}
              />
              <DateInput size="lg" name="endDate" label={t('End Date')} className="w-full" />
              {/* New UploadInput field */}
              <div className="flex w-full justify-center">
                <ButtonVariant
                  color="primary"
                  type="submit"
                  isLoading={isLoading || isLoadingUpdate}
                  isDisabled={isLoading || isLoadingUpdate}
                  size="lg"
                >
                  {t(workflowId ? 'Update' : 'Add')}
                </ButtonVariant>
              </div>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
