import { FC } from 'react'
import {
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownSection
} from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import NotificationIcon from 'assets/icons/NotificationIcon'

const NotificationDropdown: FC = () => {
  const { t } = useTranslation()
  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <div className="flex items-center cursor-pointer">
          <NotificationIcon />
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label="Notifications" variant="flat">
        <DropdownSection title={t('Notification')}>
          <DropdownItem>Notification 1</DropdownItem>
          <DropdownItem>Notification 2</DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}

export default NotificationDropdown
