import React from 'react'
import Button from './button'
import { useHorizontalScroll } from 'hooks/useHorizontalScroll'

const CardSlider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { containerRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } =
    useHorizontalScroll()

  return (
    <div className="relative flex flex-col space-y-16 scrollbar-hide">
      <div className="flex flex-row overflow-x-auto scrollbar-hide" ref={containerRef}>
        {children}
      </div>

      <div className={`hidden flex-row md:flex justify-between items-center w-11/12 mx-auto`}>
        <div className="hidden md:block border-b border-gray-300 w-full mx-2.5"></div>
        <div className="mx-2.5">
          <Button
            trailingIcon={true}
            btnStyle="primary"
            onClick={scrollRight}
            className={`w-11 rotate-180`}
            isDisabled={!canScrollRight}
          />
        </div>
        <div className="mx-2.5">
          <Button
            trailingIcon={true}
            btnStyle="primary"
            onClick={scrollLeft}
            className={`w-11`}
            isDisabled={!canScrollLeft}
          />
        </div>
      </div>
    </div>
  )
}

export default CardSlider
