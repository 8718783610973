import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CompetitionYearDetailsResponse, CompetitionYearDetails } from 'api/types'

interface CompetitionYearDetailsState {
  competitionYearDetails: CompetitionYearDetails | null
  isLoading: boolean
  error: string | null
}

const initialState: CompetitionYearDetailsState = {
  competitionYearDetails: null,
  isLoading: false,
  error: null
}

export const fetchCompetitionYearDetails = createAsyncThunk(
  'competitionYearDetails/fetch',
  async ({ id }: { id: string }) => {
    const response = await axiosInstance.get<CompetitionYearDetailsResponse>(
      `/management/competition-years/${id}`
    )
    return response.data
  }
)

const competitionYearDetailsSlice = createSlice({
  name: 'competitionYearDetails',
  initialState,
  reducers: {
    setCompetitionYearDetails: (state, action: PayloadAction<CompetitionYearDetails>) => {
      state.competitionYearDetails = action.payload ?? null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitionYearDetails.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitionYearDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.competitionYearDetails = action.payload.data ?? null
      })
      .addCase(fetchCompetitionYearDetails.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setCompetitionYearDetails } = competitionYearDetailsSlice.actions

export default competitionYearDetailsSlice
