import { FC, ReactNode } from 'react'
import FilterComponent, { FilterComponentProps } from 'components/filter/filter-component'
import { useTranslation } from 'react-i18next'
import ButtonVariant from 'components/button-variant/button'

interface FilterLayoutProps extends FilterComponentProps {
  buttonContent?: ReactNode
  buttonText?: string
  onButtonPress?: () => void
  showButton?: boolean
}

export const FilterLayout: FC<FilterLayoutProps> = ({
  filterFields,
  onFilterApply,
  onFilterReset,
  buttonContent,
  buttonText,
  onButtonPress,
  showButton = true
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row justify-between w-full mb-12">
      <FilterComponent
        filterFields={filterFields}
        onFilterApply={onFilterApply}
        onFilterReset={onFilterReset}
      />
      {showButton && (
        <div className="block-inline">
          <ButtonVariant onPress={onButtonPress} size="lg" color="primary">
            {buttonContent || t(buttonText as string) || t('Add something')}
          </ButtonVariant>
        </div>
      )}
    </div>
  )
}

export default FilterLayout
