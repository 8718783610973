import React from 'react'

export interface LocalizedName {
  ar: string
  en: string
}

interface LocalizedNameTextProps {
  localizedName: LocalizedName | null
  language: 'ar' | 'en'
}

const LocalizedNameText: React.FC<LocalizedNameTextProps> = ({ localizedName, language }) => {
  const getNameByLanguage = (name: LocalizedName | null, lang: 'ar' | 'en'): string => {
    if (!name) {
      return '-'
    }

    const primaryName = name[lang]
    const fallbackLang = lang === 'ar' ? 'en' : 'ar'
    const fallbackName = name[fallbackLang]

    if (primaryName && primaryName.trim()) {
      return primaryName
    } else if (fallbackName && fallbackName.trim()) {
      return fallbackName
    } else {
      return '-'
    }
  }

  const text = getNameByLanguage(localizedName, language)

  return <span>{text}</span>
}

export default LocalizedNameText
