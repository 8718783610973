import useLanguage from 'hooks/useLanguage'
import { useRegions } from './get-regions'
import { getLocalizedName } from '../utils'

export const useRegionOptions = (countryId?: string) => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { regions, isLoading } = useRegions({ countryId }) // Fetch regions based on countryId

  // Create options based on the fetched regions, only if they are available
  const options = regions.length
    ? regions.map((region: any) => ({
        key: String(region.id), // Region ID for key
        label: getLocalizedName(region.name, language) // Localized name based on language
      }))
    : [
        {
          key: '',
          label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available' // Default message when there are no regions
        }
      ]

  return { options, isLoading }
}
