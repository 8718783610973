import React from 'react'
import MetricCardLayout from './components/MetricCardLayout'
import CodeCard from './components/CodeCard'
import PointsCard from './components/PointsCard'

const TeacherAccount = () => {
  return (
    <>
    <div className='w-full flex justify-center gap-6'>
      <MetricCardLayout><PointsCard points={500} /></MetricCardLayout>
      <MetricCardLayout><CodeCard code="78GHLK0TYB" /></MetricCardLayout>
    </div>
    
    </>
  )
}

export default TeacherAccount
