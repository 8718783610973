import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { SignInResponse } from 'api/types'

interface SignInState {
  isLoading: boolean
  error: string | null
}

const initialState: SignInState = {
  isLoading: false,
  error: null
}

export const signIn = createAsyncThunk(
  'signIn',
  async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axiosInstance.post<SignInResponse>('auth/login', {
        email: email,
        password: password
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(signIn.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(signIn.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default signInSlice
