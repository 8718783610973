import { Button, ButtonProps } from '@nextui-org/react'
import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface ButtonVariantProps extends Omit<ButtonProps, 'children' | 'variant'> {
  fontBold?: boolean
  children?: ReactNode
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'clear'
    | 'danger-default'
    | 'danger-outline'
    | 'bordered'
    | 'ghost'
  size?: 'sm' | 'md' | 'lg'
  active?: boolean
  disabled?: boolean
  iconStart?: ReactNode
  iconEnd?: ReactNode
  iconRotate?: boolean
}

const sizeStyles: Record<string, string> = {
  sm: 'px-4 py-2.5 font-heading-h6-ar',
  md: 'px-4 py-2.5 font-heading-h6-ar',
  lg: 'px-6 py-3.5 font-heading-h6-ar'
}

const variantStyles = (active: boolean, disabled: boolean): Record<string, string> => ({
  primary: `bg-secondary_fill_normal_pressed text-primary_lv1_text_normal hover:bg-secondary_fill_hover focus:bg-secondary_fill_normal_pressed ${active ? 'bg-secondary_fill_active shadow-focus-effect' : ''} ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}`,
  secondary: `bg-primary_fill_normal_pressed text-primary_lv1_text_normal hover:bg-sprimary_fill_hover focus:bg-primary_fill_normal_pressed ${active ? 'bg-secondary_fill_active shadow-focus-effect' : ''} ${disabled ? 'bg-natural_fill_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}`,
  outline: ``,
  bordered: `
  bg-transparent border-2 border-primary_fill_normal_pressed text-primary_stroke_normal
  hover:bg-primary_fill_normal_pressed hover:text-white
  focus:bg-primary_fill_hover focus:border-primary_fill_hover focus:text-white
  ${active ? 'bg-primary_fill_active text-white shadow-focus-effect' : ''}
  ${disabled ? 'border-natural_lv2_text_disabled text-natural_lv2_text_disabled cursor-not-allowed' : ''}
`,
  clear: `bg-transparent rounded-full`,
  'danger-default': ``,
  'danger-outline': ``
})

const ButtonVariant: FC<ButtonVariantProps> = ({
  fontBold,
  children,
  variant = 'primary',
  size = 'md',
  active = false,
  disabled = false,
  iconStart,
  iconEnd,
  iconRotate,
  hidden = false,
  ...other
}) => {
  const styles = variantStyles(active, disabled)
  const direction = useSelector((state: RootState) => state.layout.direction)

  return (
    <Button
      className={clsx(styles[variant], sizeStyles[size], 'rounded-full', { 'font-bold': fontBold })}
      style={{ display: hidden ? 'none' : undefined }}
      disabled={disabled}
      {...other}
    >
      <div
        className={`flex items-center gap-1 ${direction === 'rtl' ? 'flex-row-reverse' : 'flex-row'}`}
      >
        {direction === 'rtl' ? (
          <>
            {iconEnd && <span className={`${iconRotate ? 'rotate-180' : ''}`}>{iconEnd}</span>}
            {children && children}
            {iconStart && <span className={`${iconRotate ? '' : 'rotate-180'}`}>{iconStart}</span>}
          </>
        ) : (
          <>
            {iconStart && <span className={`${iconRotate ? 'rotate-180' : ''}`}>{iconStart}</span>}
            {children && children}
            {iconEnd && <span className={`${iconRotate ? '' : 'rotate-180'}`}>{iconEnd}</span>}
          </>
        )}
      </div>
    </Button>
  )
}

export default ButtonVariant
