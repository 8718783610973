import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useRestAccountPasswordSchema } from './schema'
import { z } from 'zod'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { updatePassword } from 'store/slices/updatePasswordSlice'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Alert from 'components/alert/alert'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'

function RestAccountPassword() {
  const { _ } = useLingui()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const restAccountPassword = useRestAccountPasswordSchema()

  // Infer the type from the schema
  type RestAccountPasswordType = z.infer<typeof restAccountPassword>
  // Initialize the form
  const methods = useForm<RestAccountPasswordType>({
    resolver: zodResolver(restAccountPassword),
    defaultValues: {
      currentPassword: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined
    }
  })

  // Extract methods for form handling
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = methods

  const onSubmit = async (data: RestAccountPasswordType) => {
    try {
      const result = await dispatch(
        updatePassword({
          current_password: data.currentPassword,
          new_password: data.newPassword
        })
      ).unwrap()
      if (result.status === 'success') {
        setShowAlert(true)
        // reset values
        setValue('currentPassword', '')
        setValue('newPassword', '')
        setValue('confirmNewPassword', '')
      }
    } catch (error: any) {
      toast.error(_(msg`${error.message}`) || _(msg`هنالك خطأ`))
    }
  }

  return (
    <FormProvider {...methods}>
      {showAlert && (
        <Alert
          closable
          mainText={_(msg`تم حفظ التحديثات بنجاح`)}
          sizeMainText="md"
          weightMainText="normal"
          onClose={() => setShowAlert(false)}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 w-full"
      >
        <TextInput label={_(msg`كلمة المرور (الحالية)`)} type="password" name="currentPassword" />
        <div className="flex flex-col gap-2">
          <TextInput label={_(msg`كلمة المرور (الجديدة)`)} type="password" name="newPassword" />{' '}
          <div className="flex flex-col gap-1 items-start w-full">
            {!errors.newPassword && (
              <>
                <p className="text-primary_lv2_text_normal font-body-h6">
                  {_(msg`يحتوي على 8 أحرف على الأقل`)}
                </p>
                <p className="text-primary_lv2_text_normal font-body-h6">
                  {_(msg`يحتوي على حرف كبير واحد على الأقل`)}
                </p>
                <p className="text-primary_lv2_text_normal font-body-h6">
                  {_(msg`يحتوي على رقم واحد على الأقل`)}
                </p>
                <p className="text-primary_lv2_text_normal font-body-h6">
                  {_(msg`يحتوي على رمز مميز واحدة على الأقل`)}
                </p>
              </>
            )}
          </div>
        </div>
        <TextInput label={_(msg`تأكيد كلمة المرور`)} type="password" name="confirmNewPassword" />
        <div className="col-span-full flex justify-end">
          <ButtonVariant
            type="submit"
            variant="secondary"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {_(msg`تحديث`)}
          </ButtonVariant>
        </div>
      </form>
    </FormProvider>
  )
}

export default RestAccountPassword
