const CalenderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_5107_129169)">
        <path
          d="M16.1641 4.5026H19.4974C19.7184 4.5026 19.9304 4.5904 20.0867 4.74668C20.2429 4.90296 20.3307 5.11492 20.3307 5.33594V18.6693C20.3307 18.8903 20.2429 19.1022 20.0867 19.2585C19.9304 19.4148 19.7184 19.5026 19.4974 19.5026H4.4974C4.27638 19.5026 4.06442 19.4148 3.90814 19.2585C3.75186 19.1022 3.66406 18.8903 3.66406 18.6693V5.33594C3.66406 5.11492 3.75186 4.90296 3.90814 4.74668C4.06442 4.5904 4.27638 4.5026 4.4974 4.5026H7.83073V2.83594H9.4974V4.5026H14.4974V2.83594H16.1641V4.5026ZM18.6641 9.5026V6.16927H16.1641V7.83594H14.4974V6.16927H9.4974V7.83594H7.83073V6.16927H5.33073V9.5026H18.6641ZM18.6641 11.1693H5.33073V17.8359H18.6641V11.1693ZM6.9974 12.8359H11.1641V16.1693H6.9974V12.8359Z"
          fill="#3F3F46"
        />
      </g>
      <defs>
        <clipPath id="clip0_5107_129169">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CalenderIcon
