import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import Select from 'react-select'
import { setIthraUsersIds } from 'store/slices/activateRequestsSlice'
import { fetchIthraUsers } from 'store/slices/ithraUsersSlice'

const IthraUsersFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const { ithraUsers, isLoading } = useSelector((state: RootState) => state.ithraUsers)
  const { ithraUsersIds } = useSelector((state: RootState) => state.activateRequests)

  useEffect(() => {
    dispatch(fetchIthraUsers())
  }, [])

  const handelIthraUsers = (selectedOptions: any) => {
    // dispatch(setIthraUser(selectedOption ? selectedOption.value : ''))
    dispatch(
      setIthraUsersIds(
        selectedOptions ? selectedOptions.map((selectedOption: any) => selectedOption.value) : []
      )
    )
  }

  const ithraUsersOptions = ithraUsers.map((ithraUser: any) => {
    return { value: ithraUser.id, label: ithraUser.name }
  })

  // const ithraUserValue = ithraUsersOptions.find((option) => option.value === ithraUser)
  const ithraUserValues = ithraUsersIds
    .map((id) => ithraUsersOptions.find((option) => option.value === id))
    .filter(Boolean)

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('PROCESSED_BY_FILTER')}</p>
      <Select
        options={ithraUsersOptions}
        onChange={handelIthraUsers}
        placeholder={t('PROCESSED_BY_FILTER')}
        isLoading={isLoading}
        isSearchable
        value={ithraUserValues}
        isMulti
        isClearable={false}
      />
    </div>
  )
}

export default IthraUsersFilter
