import React, { useState } from 'react'
import awardsList from './awards-list'
import BackgroundImage from './background-image'
import AwardsHeader from './awards-header'
import AwardsContent from './awards-content'

const AwardsSection: React.FC = () => {
  const [activeCard, setActiveCard] = useState<number>(awardsList[0].id)
  const [prevActiveCard, setPrevActiveCard] = useState<number | null>(null)

  const handleSetActive = (id: number) => {
    setPrevActiveCard(activeCard)
    setActiveCard(id)
  }

  return (
    <div className="relative pt-12 pb-16 md:pb-36">
      <BackgroundImage activeCard={activeCard} prevActiveCard={prevActiveCard} />
      <div className="w-10/12 mx-auto flex flex-col space-y-9 md:space-y-20">
        <AwardsHeader />
        <AwardsContent activeCard={activeCard} handleSetActive={handleSetActive} />
      </div>
    </div>
  )
}

export default AwardsSection
