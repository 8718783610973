import React, { useEffect } from 'react'
import { i18n } from '@lingui/core'
import { useDispatch, useSelector } from 'react-redux'
import EarthIcon from 'assets/icons/EarthIcon'
import arMessages from '../../locales2/ar/messages.json'
import enMessages from '../../locales2/en/messages.json'
import { RootState } from 'store'
import { setLayoutDirection } from 'store/slices/layoutSlice'

interface LanguageButtonProps {
  fillColor: string
  textClass: string
}

i18n.load({
  en: enMessages.messages,
  ar: arMessages.messages
})

const LanguageButton: React.FC<LanguageButtonProps> = ({ fillColor, textClass }) => {
  const dispatch = useDispatch()
  const direction = useSelector((state: RootState) => state.layout.direction)
  const language = direction === 'rtl' ? 'ar' : 'en'

  useEffect(() => {
    i18n.activate(language)
    document.documentElement.setAttribute('dir', direction)
    document.documentElement.lang = language
  }, [direction, language])

  const toggleLanguage = () => {
    const newLanguage = language === 'ar' ? 'en' : 'ar'
    const newDirection = newLanguage === 'ar' ? 'rtl' : 'ltr'

    dispatch(setLayoutDirection(newDirection))
  }

  return (
    <div
      className="flex items-center *:mx-0.5 px-3 py-2.5 cursor-pointer hover:bg-gray-100 hover:bg-opacity-50 rounded-full"
      onClick={toggleLanguage}
    >
      <EarthIcon fill={fillColor} />
      <p className={`${textClass} ml-2`}>
        <span className="block sm:hidden">{language === 'ar' ? 'E' : 'ع'}</span>
        <span className="hidden sm:block">{language === 'ar' ? 'English' : 'العربية'}</span>
      </p>
    </div>
  )
}

export default LanguageButton
