import React from 'react'
import { Tabs, Tab } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { AppDispatch } from 'store'
import { useDispatch } from 'react-redux'
import { activateRequestsActiveTabType, setActiveTab } from 'store/slices/activateRequestsTabsSlice'

export default function ActivateRequestsTabs() {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const onTabChange = (tab: any) => {
    dispatch(setActiveTab(tab as activateRequestsActiveTabType))
  }

  return (
    <div className="flex flex-wrap gap-4">
      <Tabs variant="underlined" onSelectionChange={onTabChange}>
        <Tab key="activateRequest" title={t('ACTIVATE_REQUEST_TAB_TITLE')} />
        <Tab key="coordinatorDetail" title={t('COORDINATOR_DETAIL_TAB_TITLE')} />
        <Tab key="requestActivites" title={t('REQUEST_ACTIVITIES_TAB_TITLE')} />
      </Tabs>
    </div>
  )
}
