import React, { ReactNode } from 'react'
import { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/react'

interface ModalHeaderProps {
  headerText?: MessageDescriptor
  subheaderText?: ReactNode
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ headerText, subheaderText }) => {
  return (
    <div className="w-full flex flex-col items-center text-center">
      {headerText && <h2 className="font-body-h3 text-natural_icon_normal capitalize"><Trans id={headerText.id} /></h2>}
      {subheaderText && <p className="font-heading-h6 text-natural_icon_normal">{subheaderText}</p>}
    </div>
  )
}

export default ModalHeader
