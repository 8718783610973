import React, { ReactNode } from 'react'
import { ScrollProvider } from 'pages/landing/context/scroll-context'
import Layout from './layout'
import { DeviceProvider } from './context/device-context'
import { i18n } from '@lingui/core'
import 'react-toastify/dist/ReactToastify.css'
import arMessages from '../../locales2/ar/messages.json'
import enMessages from '../../locales2/en/messages.json'
import { I18nProvider } from '@lingui/react'

i18n.load({
  en: enMessages.messages,
  ar: arMessages.messages
})
i18n.activate('ar')

interface WrapperProps {
  children: ReactNode
  navbar?: boolean
  footer?: boolean
  isScrolled?: boolean
}

const Wrapper: React.FC<WrapperProps> = ({ children, navbar = true, footer = true, isScrolled  }) => {
  return (
    <I18nProvider i18n={i18n}>
      <DeviceProvider>
        <ScrollProvider>
          <Layout navbar={navbar} footer={footer} isScrolled={isScrolled} >{children}</Layout>
        </ScrollProvider>
      </DeviceProvider>
    </I18nProvider>
  )
}

export default Wrapper
