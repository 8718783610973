import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import SelectField from 'components/select-field/select-field'

interface SelectInputProps {
  name: string
  options: { key: string; label: string }[]
  label?: string
  placeholder?: string
  helperText?: string
  selectionMode?: 'single' | 'multiple'
  mode?: 'normal' | 'search'
  isChips?: boolean
  [key: string]: any
}

const SelectInput: FC<SelectInputProps> = ({
  name,
  options,
  label,
  placeholder,
  helperText,
  selectionMode = 'single',
  mode = 'normal',
  isChips,
  ...other
}) => {
  const { control, setValue, watch } = useFormContext()

  const selectedValue = watch(name) ?? (selectionMode === 'multiple' ? [] : '')

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <SelectField
            isChips={isChips}
            label={label}
            placeholder={placeholder}
            {...field}
            value={selectedValue}
            options={options}
            helperText={helperText}
            selectionMode={selectionMode}
            mode={mode}
            error={error ? error.message : undefined}
            onChange={(newValue) => {
              setValue(name, newValue || (selectionMode === 'multiple' ? [] : ''), {
                shouldValidate: true
              })
              field.onChange(newValue || (selectionMode === 'multiple' ? [] : ''))
            }}
            {...other}
          />
        )
      }}
    />
  )
}

export default SelectInput
