import ModalHeader from 'components/modal/modal-header'
import ButtonVariant from 'components/button-variant/button'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'
import ForgotPasswordForm from '../forms/forgot-password'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'

const ResetPassModal: React.FC<{ handleConfirmationModalDisplay: (shouldShow: boolean) => void }> = ({ handleConfirmationModalDisplay }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/login')
  }

  return (
    <ModalLayout
      onBack={handleBack}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`نسيت كلمة المرور؟`}
          subheaderText={<Trans>أدخل البريد الالكتروني المرتبط بحسابك</Trans>}
        />
      }
      body={<ForgotPasswordForm handleConfirmationModalDisplay={handleConfirmationModalDisplay} />}
      footer={
        <ButtonVariant variant="clear" onClick={handleBack}>
          <p><Trans>هل لديك حساب ؟</Trans></p>
        </ButtonVariant>
      }
    />
  )
}

export default ResetPassModal
