import { Button, Select, SelectItem, Switch, Textarea } from '@nextui-org/react'
import { CompetitionYearDetails } from 'api/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompetition } from 'store/slices/updateCompetitionYear'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import { toast } from 'react-toastify'

const useDayOptions = () => {
  const { t } = useTranslation()
  const days = Array.from({ length: 30 }, (_, i) => i + 1) // Generate days from 1 to 30
  return days.map((day) => ({
    key: day.toString(),
    label: t(day === 1 ? `${day} day` : `${day} days`)
  }))
}

type CompetitionSettingsProps = {
  competitionYear: CompetitionYearDetails
}

const schema = z.object({
  auto_start: z.boolean(),
  notification_enable: z.boolean(),
  notification_days: z.string().nonempty({ message: 'Notification days are required' }),
  notification_message: z.string().nonempty({ message: 'Notification message is required' })
})

type CompetitionSettingsSchemaType = z.infer<typeof schema>

export const CompetitionSettings: FC<CompetitionSettingsProps> = (props) => {
  const {
    competitionYear: {
      close_at,
      auto_start,
      notification_message,
      notification_days,
      notification_enable,
      name,
      start_date,
      end_date,
      status,
      id
    }
  } = props

  const dayOptions = useDayOptions()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector((state: RootState) => state.updateCompetitionYear.isLoading)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<CompetitionSettingsSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      auto_start,
      notification_enable,
      notification_days: notification_days ? notification_days.toString() : '', // Handle null case
      notification_message: notification_message || '' // Handle null case
    }
  })

  const [isNotificationEnabled, setIsNotificationEnabled] = useState(notification_enable)
  const [autoYearStart, setAutoYearStart] = useState(auto_start)

  useEffect(() => {
    setValue('auto_start', auto_start)
    setValue('notification_enable', notification_enable)

    // Only set values if notification_enable is true
    if (notification_enable) {
      setValue('notification_days', notification_days ? notification_days.toString() : '')
      setValue('notification_message', notification_message || '')
    } else {
      setValue('notification_days', '') // Set to empty string or handle as you need
      setValue('notification_message', '') // Set to empty string or handle as you need
    }

    setIsNotificationEnabled(notification_enable)
    setAutoYearStart(auto_start)
  }, [auto_start, notification_enable, notification_days, notification_message, setValue])

  const onSubmit = async (data: CompetitionSettingsSchemaType) => {
    try {
      await dispatch(
        updateCompetition({
          id,
          name,
          startDate: start_date,
          endDate: end_date,
          status,
          notification_days: parseInt(data.notification_days),
          notification_enable: data.notification_enable,
          notification_message: data.notification_message,
          auto_start: data.auto_start,
          close_at
        })
      ).unwrap()
      dispatch(fetchCompetitionYearDetails({ id: id.toString() }))
      toast.success(t('Competition settings updated successfully'))
    } catch (error: any) {
      toast.error(error.message ?? t('Failed to update competition settings'))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="w-full p-3 bg-[#F9FAFB] rounded-md">
        <div className="flex flex-row gap-2 mb-4">
          <Controller
            name="auto_start"
            control={control}
            render={({ field }) => (
              <Switch
                size="lg"
                aria-label="Automatic updates"
                checked={field.value}
                onChange={(e) => {
                  setAutoYearStart(e.target.checked)
                  field.onChange(e.target.checked)
                }}
                defaultSelected={field.value}
              />
            )}
          />
          <div className="flex flex-col gap-2">
            <p className="font-medium text-md">{t('Year start')}</p>
            <p className="text-sm">
              {t(
                'By enabling the “Auto Start” option, the contest will start automatically when it is due to start'
              )}
            </p>
          </div>
        </div>
        {/* Just I removed this or update after get the info */}
        {autoYearStart && <div className="block-inline" />}{' '}
      </div>

      <div className="w-full p-3 bg-[#F9FAFB] rounded-md">
        <div className="flex flex-row gap-2 mb-8">
          <Controller
            name="notification_enable"
            control={control}
            render={({ field }) => (
              <Switch
                size="lg"
                aria-label="Notification enable"
                checked={field.value}
                onChange={(e) => {
                  setIsNotificationEnabled(e.target.checked)
                  field.onChange(e.target.checked)
                }}
                defaultSelected={field.value}
              />
            )}
          />
          <div className="flex flex-col gap-2">
            <p className="font-medium text-md">
              {t("Configure settings for sending New Year's Eve notifications")}
            </p>
            <p className="text-sm">
              {t(
                'Activate sending notifications to the target audience via email and website during the days before the start date of the competition'
              )}
            </p>
          </div>
        </div>
        {isNotificationEnabled && (
          <div className="flex flex-row items-start gap-4">
            <Controller
              name="notification_days"
              control={control}
              render={({ field }) => (
                <Select
                  size="lg"
                  items={dayOptions}
                  placeholder={t('Select days')}
                  className="w-1/3"
                  selectedKeys={new Set([field.value])}
                  onSelectionChange={(keys) => field.onChange(Array.from(keys).join(''))}
                >
                  {(day) => <SelectItem key={day.key}>{day.label}</SelectItem>}
                </Select>
              )}
            />
            {errors.notification_days && (
              <p className="text-red-500 text-sm">{errors.notification_days.message}</p>
            )}

            <Controller
              name="notification_message"
              control={control}
              render={({ field }) => (
                <Textarea
                  label={t('Text of the message of the poems')}
                  className="w-1/3"
                  {...field}
                />
              )}
            />
            {errors.notification_message && (
              <p className="text-red-500 text-sm">{errors.notification_message.message}</p>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-row justify-end w-full">
        <Button
          type="submit"
          size="lg"
          className="bg-[#2AACAD] text-white hover:bg-[#279a99] border rounded-full border-transparent"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t('Save Changes')}
        </Button>
      </div>
    </form>
  )
}

export default CompetitionSettings
