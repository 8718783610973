import { Spinner } from '@nextui-org/react'
import { FC, useEffect } from 'react'
import YearInformation from './YearInformation'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { useParams } from 'react-router-dom'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import CompetitionSettings from './Settings'
import HistoryChanges from './HistoryChanges'
import { useTranslation } from 'react-i18next'
import WorkFlows from './WorkFlows'
import TabsNavigator from 'components/tabs-navigator/tabs-navigator'

export const CompetitionDetails: FC = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()

  const { competitionYearDetails, isLoading } = useSelector(
    (state: RootState) => state.competitionYearDetails
  )

  useEffect(() => {
    if (id) {
      dispatch(fetchCompetitionYearDetails({ id }))
    }
  }, [dispatch, id])

  const tabItems = [
    {
      key: 'workflows',
      title: t('Workflows associated'),
      content: isLoading ? <Spinner size="lg" /> : <WorkFlows id={id} />
    },
    {
      key: 'year-information',
      title: t('Year information'),
      content: isLoading ? (
        <Spinner size="lg" />
      ) : (
        <YearInformation id={id} competitionYear={competitionYearDetails!} />
      )
    },
    {
      key: 'settings',
      title: t('Settings'),
      content: isLoading ? (
        <Spinner size="lg" />
      ) : (
        <CompetitionSettings competitionYear={competitionYearDetails!} />
      )
    },
    {
      key: 'history',
      title: t('History of changes'),
      content: isLoading ? (
        <Spinner size="lg" />
      ) : (
        <HistoryChanges activities={competitionYearDetails?.activities!} />
      )
    }
  ]

  return (
    <div className="flex w-full flex-col">
      <TabsNavigator variant="underlined" color="primary" items={tabItems} />
    </div>
  )
}

export default CompetitionDetails
