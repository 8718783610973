import React from 'react'
import ReviewsCard from './reviews-card'
import SectionTitle from 'components/base/section-title'
import Button from 'components/base/button'
import reviewsList from './reviews-list'
import { msg } from '@lingui/macro'

const ReviewsSection: React.FC = () => {
  return (
    <div className="border-b border-gray-300">
      <div className="py-12 flex flex-col space-y-7 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <div className="flex justify-between">
          <SectionTitle
            title={msg`مراجعات الكتب`}
            sectionDescription={msg`هذا النص قابل للتعديل`}
          />
          <div>
            <Button
              placeholder={msg`المزيد`}
              btnStyle="secondary"
              btnSize="Default"
              trailingIcon={true}
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          {reviewsList.map((review, index) => (
            <ReviewsCard key={index} review={review} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReviewsSection
