import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react'

const DeviceContext = createContext(false)

interface DeviceProviderProps {
  children: ReactNode
}

export const DeviceProvider: React.FC<DeviceProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // console.log(navigator.userAgent)
    const checkIfMobile = () => {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) || window.innerWidth <= 768
      )
    }

    setIsMobile(checkIfMobile())

    const handleResize = () => {
      setIsMobile(checkIfMobile())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <DeviceContext.Provider value={isMobile}>{children}</DeviceContext.Provider>
}

export const useDevice = () => useContext(DeviceContext)
