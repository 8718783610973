import React from 'react'

const DoubleChevron: React.FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.52661 9.9993L9.6992 15.1719L10.8777 13.9934L6.88361 9.9993L10.8777 6.00522L9.6992 4.82672L4.52661 9.9993ZM9.23486 9.9993L14.4074 15.1719L15.5859 13.9934L11.5919 9.9993L15.5859 6.00522L14.4074 4.82672L9.23486 9.9993Z"
        fill="#F9FAFB"
      />
    </svg>
  )
}

export default DoubleChevron
