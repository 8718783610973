const TrashIcon = ({ size = '24' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 3.9987H14.6654V5.33203H13.332V13.9987C13.332 14.1755 13.2618 14.3451 13.1368 14.4701C13.0117 14.5951 12.8422 14.6654 12.6654 14.6654H3.33203C3.15522 14.6654 2.98565 14.5951 2.86063 14.4701C2.7356 14.3451 2.66536 14.1755 2.66536 13.9987V5.33203H1.33203V3.9987H4.66536V1.9987C4.66536 1.82189 4.7356 1.65232 4.86063 1.52729C4.98565 1.40227 5.15522 1.33203 5.33203 1.33203H10.6654C10.8422 1.33203 11.0117 1.40227 11.1368 1.52729C11.2618 1.65232 11.332 1.82189 11.332 1.9987V3.9987ZM11.9987 5.33203H3.9987V13.332H11.9987V5.33203ZM5.9987 7.33203H7.33203V11.332H5.9987V7.33203ZM8.66536 7.33203H9.9987V11.332H8.66536V7.33203ZM5.9987 2.66536V3.9987H9.9987V2.66536H5.9987Z"
        fill="#CC3D3D"
      />
    </svg>
  )
}

export default TrashIcon
