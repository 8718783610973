import React, { ReactNode } from 'react'

interface ContainerProps {
  children: ReactNode
  title?: string
  subtitles?: string
}

const Container: React.FC<ContainerProps> = ({ children, title, subtitles }) => {
  return (
    <div className="h-full">
      {title && (
        <div className="mb-4">
          <h1 className="text-2xl font-bold mb-2">{title}</h1>
          {subtitles && <p className="text-gray-600">{subtitles}</p>}
        </div>
      )}
      <div className="overflow-y-auto h-full">{children}</div>
    </div>
  )
}

export default Container
