import { ActivateRequestDetailResponse } from 'api/types'
import { getGenderOfEducationLabel } from 'constants/genderOfEducationOptions'
import useLanguage from 'hooks/useLanguage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getNameByLanguage } from 'utils/getNameByLanguage'

interface RequestActivitesProps {
  activateRequestDetail?: ActivateRequestDetailResponse | null
}

const CoordinatorDetail: React.FC<RequestActivitesProps> = ({ activateRequestDetail }) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const data = activateRequestDetail?.data

  return (
    <div className="flex flex-row justify-start items-start flex-wrap w-full gap-4">
      <div className="flex flex-col justify-start items-start gap-1.5 w-1/3">
        <p className="">{t('NAME')}</p>
        <p className="">{data?.name ?? '-'}</p>
      </div>
      <div className="flex flex-col justify-start items-start gap-1.5 w-1/3">
        <p className="">{t('EMAIL')}</p>
        <p className="">{data?.email ?? '-'}</p>
      </div>
      <div className="flex flex-col justify-start items-start gap-1.5 w-1/3">
        <p className="">{t('PHONE_NUMBER')}</p>
        <p className="">{data?.phone ?? '-'}</p>
      </div>
      <div className="flex flex-col justify-start items-start gap-0.5 w-1/3">
        <p className="">{t('GENDER')}</p>
        <p className="">
          {getGenderOfEducationLabel(data?.gender_of_education ?? '', language) ?? '-'}
        </p>
      </div>
      <div className="flex flex-col justify-start items-start gap-0.5 w-1/3">
        <p className="">{t('COUNTRY')}</p>
        <p className="">{getNameByLanguage(data?.country?.name ?? null, language)}</p>
      </div>
      <div className="flex flex-col justify-start items-start gap-0.5 w-1/3">
        <p className="">{t('REGION')}</p>
        <p className="">{getNameByLanguage(data?.region?.name ?? null, language)}</p>
      </div>
    </div>
  )
}

export default CoordinatorDetail
