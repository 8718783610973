import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { RegisterResponse } from 'api/types'

interface RegisterState {
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  isLoading: false,
  error: null
}

export const register = createAsyncThunk(
  'register',
  async (
    {
      email,
      name,
      phone,
      gender,
      country_id,
      password,
      // password_confirmation,
      account_type,
      terms_agreed
    }: {
      email: string
      name: string
      phone: string
      gender: string
      country_id: number
      password: string
      // password_confirmation: string
      account_type: string
      terms_agreed: boolean
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<RegisterResponse>('/auth/register', {
        email: email,
        name: name,
        phone: phone,
        gender: gender,
        country_id: country_id,
        password: password,
        // password_confirmation: password_confirmation,
        account_type: account_type,
        terms_agreed: terms_agreed
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default registerSlice
