import { Routes as ReactRoutes, Route } from 'react-router-dom'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
// import VerifyEmailHash from 'pages/VerifyEmailHash'
// import AppLayout from 'components/layouts/AppLayout/AppLayout'
// import ProtectedRoute from './ProtectedRoute'
import Page404 from 'pages/Page404'
// import AuthGuard from 'guards/auth-guard/auth-guard'
import Home from 'pages/landing/home'
import Blog from 'pages/landing/blog'
import Wrapper from 'pages/landing/wrapper'
// import Container from 'pages/auth/container'
import Layout from 'pages/auth/layout'
import routesConfig from 'pages/auth/routesConfig'
import Login from 'pages/AccountPages/login'
import Registration from 'pages/AccountPages/registration'
import ResetPassword from 'pages/AccountPages/reset-password'
import ForgotPassword from 'pages/AccountPages/forgot-password'
import VerifyEmail from 'pages/AccountPages/verify-email'
import AuthGuard from 'guards/auth-guard/auth-guard'
import PublicGuard from 'guards/public-guard/public-guard'
import SupplementaryFiles from 'pages/landing/supplementary-files'
//import routesConfig from './routesConfig'
// import Layout from 'pages/auth/layout'
//import routesConfig from 'pages/auth/routesConfig'
//import routesConfig from './routesConfig'

function AppRoutes() {
  const userType = useSelector((state: RootState) =>
    typeof state.auth.userType === 'string' ? state.auth.userType : null
  )

  return (
    <ReactRoutes>
      <Route
        path="/"
        element={
          <Wrapper>
            <Home />
          </Wrapper>
        }
      />
      <Route
        path="/blog"
        element={
          <Wrapper>
            <Blog />
          </Wrapper>
        }
      />
      <Route
        path="/supplementary-files"
        element={
          <Wrapper>
            <SupplementaryFiles />
          </Wrapper>
        }
      />
      <Route
        path="/login"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <Login />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/register"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <Registration />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <ResetPassword />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <ForgotPassword />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route
        path="/auth/verify-email"
        element={
          <PublicGuard>
            <Wrapper footer={false} isScrolled={true}>
              <VerifyEmail />
            </Wrapper>
          </PublicGuard>
        }
      />
      <Route>
        <Route
          path="/app"
          element={
            <Wrapper navbar={false} footer={false}>
              <AuthGuard>
                <Layout userRole={userType ?? ''} />
              </AuthGuard>
            </Wrapper>
          }
        >
          {routesConfig.map((route) => (
            <Route key={route.path} path={route.path} element={<route.component />} />
          ))}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Route>
    </ReactRoutes>
  )
}

export default AppRoutes
