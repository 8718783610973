import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface TableHeaderColumn {
  name: string
  uid: string
  sortable?: boolean
}

export const columns = [
  { name: 'COORDINATOR_INFORMATION_COLUMN', uid: 'coordinator_information', sortable: false },
  { name: 'COORDINATOR_COUNTRY_COLUMN', uid: 'coordinator_country', sortable: false },
  { name: 'REGION_COLUMN', uid: 'region', sortable: false },
  { name: 'EDUCATION_ADMINISTRATION_COLUMN', uid: 'education_administration', sortable: false },
  { name: 'SUBMISSION_DATE_COLUMN', uid: 'submission_date', sortable: false },
  { name: 'REQUEST_STATUS_COLUMN', uid: 'request_status', sortable: false },
  { name: 'ACTIONS_COLUMN', uid: 'actions' }
]

export const useTranslatedTableHeaderColumns = ({
  columns,
  language
}: {
  columns: TableHeaderColumn[]
  language: 'ar' | 'en'
}) => {
  const { t, i18n } = useTranslation()

  useMemo(() => {
    i18n.changeLanguage(language)
  }, [language, i18n])

  const translatedColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      name: t(column.name)
    }))
  }, [columns, t])

  return translatedColumns
}
