import React from 'react'
import MenuItem from './MenuItem'
// import LogoutButton from './buttons/LogoutButton'
import { msg } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
// import LanguageButton from 'components/base/language-button'

interface NavbarMenuProps {
  menuOpen: boolean
  fillColor: string
  textClass: string
  handleNavigation: (section: string) => void
  handleBlogLinkClick: () => void
  handleSupplementaryLinkClick: () => void
  toggleMenu: () => void
}

const NavbarMenu: React.FC<NavbarMenuProps> = ({
  menuOpen,
  fillColor,
  textClass,
  handleNavigation,
  handleBlogLinkClick,
  handleSupplementaryLinkClick,
  toggleMenu
}) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  // const isToken = useSelector((state: RootState) =>
  //   typeof state.auth.token === 'string' ? state.auth.token : null
  // )

  // const isLoggedIn = !!isToken

  // const placeholder = isLoggedIn ? msg`حسابي` : msg`تسجيل الدخول`

  if (menuOpen) {
    fillColor = 'rgba(63, 63, 70, 1)'
    textClass = 'text-naturalLv2'
  }

  return (
    <div className="md:flex md:items-center md:*:mx-2.5">
      <ul
        className={`w-[318px] md:w-fit lg:flex items-center px-6 lg:px-0 py-5 lg:py-0 bg-customWhite lg:bg-transparent rounded-2xl lg:rounded-none lg:*:mx-1.5 
        absolute top-28 ml-4 md:static md:top-auto h-fit z-50 transition-transform duration-500 ease-in-out
        ${menuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-5 pointer-events-none md:pointer-events-auto md:opacity-100 md:translate-y-0'}
        ${direction === 'rtl' ? 'left-0' : 'right-0'}`}
      >
        <MenuItem
          label={msg`عن المسابقة`}
          onClick={() => handleNavigation('about')}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`المسارات`}
          onClick={() => handleNavigation('paths')}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`المراحل`}
          onClick={() => handleNavigation('stages')}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`الجوائز`}
          onClick={() => handleNavigation('awards')}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`الفائزين السابقين`}
          onClick={() => handleNavigation('winners')}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`مدونة أقرأ`}
          onClick={handleBlogLinkClick}
          textClass={`${textClass}`}
        />
        <MenuItem
          label={msg`الملفات المساعدة`}
          onClick={handleSupplementaryLinkClick}
          textClass={`${textClass}`}
        />

        {/* <li className="lg:hidden my-2">
          <div className="border-b"></div>
        </li>
        <li className="lg:hidden">
          <LanguageButton fillColor={fillColor} textClass={textClass} />
        </li>
        <li className="lg:hidden">
          <LogoutButton textClass={textClass} placeholder={placeholder.id} />
        </li> */}
      </ul>
    </div>
  )
}

export default NavbarMenu
