import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import React from 'react'
import OTPVerification from '../forms/otp-verifiy'
import { Trans } from '@lingui/macro'

interface OTPModalProps {
  handleEmailModalDisplay?: (shouldShow: boolean) => void
  handleOTPModalDisplay: (shouldShow: boolean) => void
  isLoginFlow: boolean
}

const OTPModal: React.FC<OTPModalProps> = ({ handleOTPModalDisplay, handleEmailModalDisplay, isLoginFlow }) => {
  // const handleBack = () => {
  //   handleOTPModalDisplay(false)
  // }

  return (
    <ModalLayout
      // onBack={handleBack}
      showBackButton={false}
      accountPageStyle
      header={
        <ModalHeader
          subheaderText={
            <>
              <Trans>شكراً لاشتراكك معنا، يُرجى إدخال الرمز الذي تم إرساله إلى بريدك الإلكتروني:</Trans>
              <span className="text-primary_lv2_text_normal px-2">
                {localStorage.getItem('email')}
              </span>
              <Trans>لتنشيط حسابك.</Trans>
            </>
          }
        />
      }
      body={
        <div className='py-1'>
        <OTPVerification handleOTPModalDisplay={handleOTPModalDisplay} handleEmailModalDisplay={handleEmailModalDisplay ?? (() => {})} isLoginFlow={isLoginFlow} />
        </div>
      }
    />
  )
}

export default OTPModal
