import React from 'react'
import { MessageDescriptor } from '@lingui/core'
import { Trans } from '@lingui/react'

interface SectionTitleProps {
  title: MessageDescriptor
  sectionDescription: MessageDescriptor
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, sectionDescription }) => {
  return (
    <div className="flex flex-col">
      <h2 className="text-customTeal text-3xl md:text-4xl font-bold">
        <Trans id={title.id} />
      </h2>
      <p className="text-naturalLv1 text-xl md:text-2xl font-bold">
        <Trans id={sectionDescription.id} />
      </p>
    </div>
  )
}

export default SectionTitle
