export const SubmitApplicationIcon = () => {
  return (
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5136_207026)">
        <path
          d="M58.7141 8.22266H53.9656V11.357C53.9656 13.0305 52.6094 14.3867 50.9359 14.3867C49.2641 14.3867 47.9078 13.0305 47.9078 11.357V8.22266H37.7484V11.357C37.7484 13.0305 36.3937 14.3867 34.7203 14.3867C33.0469 14.3867 31.6922 13.0305 31.6922 11.357V8.22266H21.5328V11.357C21.5328 13.0305 20.1766 14.3867 18.5047 14.3867C16.8313 14.3867 15.475 13.0305 15.475 11.357V8.22266H10.7266C8.83281 8.22266 7.29688 9.75703 7.29688 11.6508V21.168H62.1437V11.6508C62.1437 9.75703 60.6078 8.22266 58.7141 8.22266Z"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.29688 57.0073V75.507C7.29688 77.4008 8.83125 78.9367 10.725 78.9367H62.1422C55.7578 78.9367 50.5813 73.7617 50.5813 67.3773C50.5813 64.1852 51.875 61.2945 53.9672 59.2023C56.0594 57.1102 58.95 55.8164 62.1422 55.8164V21.168H7.29688V43.2636"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.5053 14.387C16.8325 14.387 15.4766 13.0309 15.4766 11.3583V5.09125C15.4764 3.41859 16.8325 2.0625 18.5053 2.0625C20.1781 2.0625 21.5341 3.41859 21.5341 5.09125V11.3581C21.5341 13.0309 20.178 14.387 18.5053 14.387Z"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.935 14.387C49.2622 14.387 47.9062 13.0309 47.9062 11.3583V5.09125C47.9062 3.41844 49.2623 2.0625 50.935 2.0625C52.6078 2.0625 53.9637 3.41859 53.9637 5.09125V11.3581C53.9637 13.0309 52.6077 14.387 50.935 14.387Z"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.7241 14.387C33.0512 14.387 31.6953 13.0309 31.6953 11.3583V5.09125C31.6953 3.41844 33.0514 2.0625 34.7241 2.0625C36.3969 2.0625 37.7528 3.41859 37.7528 5.09125V11.3581C37.753 13.0309 36.3969 14.387 34.7241 14.387Z"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.1459 78.9364C55.7616 78.9364 50.5859 73.7608 50.5859 67.3764C50.5859 60.992 55.7616 55.8164 62.1459 55.8164C68.5303 55.8164 73.7059 60.992 73.7059 67.3764C73.7059 73.7608 68.5303 78.9364 62.1459 78.9364Z"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M66.8248 64.2969L60.092 71.0297L57.1406 68.0783"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.2622 30.1641L18.5295 36.8967L15.5781 33.9455"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.2622 46.6875L18.5295 53.4203L15.5781 50.4689"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.2622 63.207L18.5295 69.9397L15.5781 66.9884"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 30.2109H52.6613"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 36.8516H52.6613"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 46.6484H52.6613"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 53.2891H49.9611"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 63.082H43.9578"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.4766 69.7227H43.9578"
          stroke="#2AACAD"
          stroke-width="3"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5136_207026">
          <rect width="80" height="80" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
