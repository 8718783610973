import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { VerifyOTPResponse } from 'api/types'

interface VerifyOTPState {
  isLoading: boolean
  error: string | null
}

const initialState: VerifyOTPState = {
  isLoading: false,
  error: null
}

export const verifyOTP = createAsyncThunk(
  'verifyOTP',
  async (
    {
      email,
      otp_code
    }: {
      email: string
      otp_code: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<VerifyOTPResponse>('/auth/verify-otp', {
        email: email,
        otp_code: otp_code
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const verifyOTPSlice = createSlice({
  name: 'verifyOTP',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default verifyOTPSlice
