import img1 from 'assets/images/stage-bg-1.png'
import img2 from 'assets/images/stage-bg-2.png'
import img3 from 'assets/images/stage-bg-3.png'
import img4 from 'assets/images/stage-bg-4.png'
import img5 from 'assets/images/stage-bg-5.png'
import { Stage } from 'pages/landing/models'

import { msg } from '@lingui/macro'

const stagesList: Stage[] = [
  {
    id: 1,
    title: msg`التسجيل لمسار قارئ العام`,
    date: msg`22 Mar 2024`,
    bgImage: img1,
    description: msg`التسجيل في المنصة الإلكترونية الخاصة بالمسابقة، وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتابٍ من اختيار المُشارك.`
  },
  {
    id: 2,
    title: msg`التصفيات الأولية`,
    date: msg`22 Mar 2024`,
    bgImage: img2,
    description: msg`التسجيل في المنصة الإلكترونية الخاصة بالمسابقة، وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتابٍ من اختيار المُشارك.`
  },
  {
    id: 3,
    title: msg`المقابلات الشخصية`,
    date: msg`22 Mar 2024 `,
    bgImage: img3,
    description: msg`التسجيل في المنصة الإلكترونية الخاصة بالمسابقة، وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتابٍ من اختيار المُشارك.`
  },
  {
    id: 4,
    title: msg`ملتقى اقرأ الاثرائي`,
    date: msg`22 Mar 2024`,
    bgImage: img4,
    description: msg`التسجيل في المنصة الإلكترونية الخاصة بالمسابقة، وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتابٍ من اختيار المُشارك.`
  },
  {
    id: 5,
    title: msg`الحفل الختامي`,
    date: msg`22 Mar 2024`,
    bgImage: img5,
    description: msg`التسجيل في المنصة الإلكترونية الخاصة بالمسابقة، وتعبئة كافة البيانات المطلوبة بشكل صحيح، ثم تقديم مراجعة وافية باللغة العربية لكتابٍ من اختيار المُشارك.`
  }
]

export default stagesList
