import events from './events'
import MarathonCard from './marathon-card'

const MarathonSection: React.FC = () => {
  return (
    <div className="border-b border-gray-300">
      <div className="py-12 w-full md:w-10/12 mx-auto px-3.5 md:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {events.map((event, index) => (
            <MarathonCard key={index} event={event} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MarathonSection
