import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ResendOTPResponse } from 'api/types'

interface ResendOTPState {
  isLoading: boolean
  error: string | null
}

const initialState: ResendOTPState = {
  isLoading: false,
  error: null
}

export const resendOTP = createAsyncThunk(
  'resendOTP',
  async (
    {
      email
    }: {
      email: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<ResendOTPResponse>('/auth/resend-otp', {
        email: email
      })
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const resendOTPSlice = createSlice({
  name: 'resendOTP',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resendOTP.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default resendOTPSlice
