import {
  Button,
  Chip,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useDisclosure
} from '@nextui-org/react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchWorkflows, setPage } from 'store/slices/workFlowSlice'
import CreateAndUpdateWorkflow from './creatWorkflow'
import { deleteWorkflow } from 'store/slices/deleteWorkflowSlice'
import ButtonVariant from 'components/button-variant/button'
import DataTable from 'components/data-table/data-table'

type WorkFlowsProps = {
  id: string | undefined
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const WorkFlows: FC<WorkFlowsProps> = ({ id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const { workflows, isLoading, page, per_page, total_pages } = useSelector(
    (state: RootState) => state.workflows
  )
  const { isLoading: isDeleting } = useSelector((state: RootState) => state.deleteWorkflow)

  const [workflowId, setWorkflowId] = useState<string>('')

  useEffect(() => {
    if (id) {
      dispatch(fetchWorkflows({ competitionId: id }))
    }
  }, [dispatch, id, page, per_page])

  const renderStatusChip = (status: string) => {
    const translatedStatus = t(capitalizeFirstLetter(status))
    const color = status === 'active' ? 'success' : 'danger'
    return (
      <Chip color={color} variant="flat">
        {translatedStatus}
      </Chip>
    )
  }

  const handleEditClick = (workflowId: string) => {
    setWorkflowId(workflowId)
    onOpen()
  }

  const handleDeleteClick = async (workflowId: string) => {
    try {
      await dispatch(
        deleteWorkflow({ competitionId: Number(id), workflowId: Number(workflowId) })
      ).unwrap()
      await dispatch(fetchWorkflows({ competitionId: id! })).unwrap()
    } catch (error) {
      console.error('Failed to delete workflow:', error)
    }
  }

  const renderSettingsPopover = (workflow: any) => {
    return (
      <Popover>
        <PopoverTrigger>
          <Button color="secondary">⋮</Button>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col gap-2 p-2">
            <Button color="primary" onPress={() => handleEditClick(workflow.id)}>
              {t('Edit')}
            </Button>
            <Button
              color="danger"
              isDisabled={workflow.status === 'active'}
              onPress={() => handleDeleteClick(workflow.id)}
            >
              {isDeleting ? <Spinner size="sm" /> : t('Delete')}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    )
  }

  const columns = [
    { key: 'name', label: t('Name') },
    { key: 'description', label: t('Description Workflow') },
    { key: 'end_date', label: t('End Date of the Competition') },
    { key: 'status', label: t('Status') },
    { key: 'settings', label: t('Settings') }
  ]

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage))
  }

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-row justify-end">
        <ButtonVariant
          color="primary"
          onPress={() => {
            setWorkflowId('')
            onOpen()
          }}
          size="lg"
        >
          {t('Add a new workflow')}
        </ButtonVariant>
      </div>
      <DataTable
        columns={columns}
        data={workflows || []}
        isLoading={isLoading}
        page={page}
        totalPages={total_pages}
        onPageChange={handlePageChange}
        renderCell={(item, columnKey) =>
          columnKey === 'status'
            ? renderStatusChip(item[columnKey])
            : columnKey === 'settings'
              ? renderSettingsPopover(item)
              : item[columnKey]
        }
      />
      <CreateAndUpdateWorkflow
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        competitionId={id as string}
        workflowId={workflowId}
      />
    </div>
  )
}

export default WorkFlows
