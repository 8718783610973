import useLanguage from 'hooks/useLanguage'
import { getLocalizedName } from '../utils'
import { useCountries } from './get-countries'

export const useCountryOptions = () => {
  const language = useLanguage() as 'ar' | 'en' // Using language from the custom hook
  const { countries, isLoading } = useCountries()

  // Create options for the select field
  const options = countries.map((country: any) => ({
    key: String(country.id),
    label: getLocalizedName(country.name, language)
  }))

  return { options, isLoading }
}
