import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { CompetitionYear, CompetitionYearResponse } from 'api/types'
import dayjs from 'dayjs'
import { cleanObject } from 'utils/cleanObject'

interface CompetitionYearState {
  competitionYears: CompetitionYear[] | null
  competitionYear: CompetitionYear | null
  isLoading: boolean
  error: string | null
  auto_start: boolean | undefined
  search: string
  start_date: string | undefined
  end_date: string | undefined
  page: number
  per_page: number
  total_pages: number
  total_items: number
  status: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived' | null
}

const initialState: CompetitionYearState = {
  competitionYears: null,
  competitionYear: null,
  isLoading: false,
  error: null,
  auto_start: undefined,
  search: '',
  start_date: undefined,
  end_date: undefined,
  page: 1,
  per_page: 8,
  total_pages: 0, // Total number of pages
  total_items: 0, // Total number of items
  status: null
}

export const fetchCompetitionYears = createAsyncThunk(
  'competitionYear/fetch',
  async (params: {
    auto_start?: boolean
    search?: string
    start_date?: string
    end_date?: string
    page?: number
    per_page?: number
    status?: 'not_started' | 'started' | 'ended' | 'terminated' | 'archived' | null
  }) => {
    const { auto_start, search, start_date, end_date, page = 1, per_page = 8, status } = params

    const paramsObj = {
      auto_start: auto_start,
      search: search,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : '',
      end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : '',
      page: page,
      per_page: per_page,
      status: status
    }

    const cleanedParams = cleanObject(paramsObj)

    const response = await axiosInstance.get<CompetitionYearResponse>(
      '/management/competition-years',
      {
        params: {
          ...cleanedParams
        }
      }
    )
    return response.data
  }
)

const competitionYearSlice = createSlice({
  name: 'competitionYear',
  initialState,
  reducers: {
    setCompetitionYears: (state, action: PayloadAction<CompetitionYear[]>) => {
      state.competitionYears = action.payload
    },
    setCompetitionYear: (state, action: PayloadAction<CompetitionYear>) => {
      state.competitionYear = action.payload
    },
    setAutoStart: (state, action: PayloadAction<boolean | undefined>) => {
      state.auto_start = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setStartDate: (state, action: PayloadAction<string | undefined>) => {
      state.start_date = action.payload
    },
    setEndDate: (state, action: PayloadAction<string | undefined>) => {
      state.end_date = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload
    },
    setStatusFilter: (
      state,
      action: PayloadAction<'not_started' | 'started' | 'ended' | 'terminated' | 'archived' | null>
    ) => {
      state.status = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitionYears.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitionYears.fulfilled, (state, action) => {
        state.isLoading = false
        state.competitionYears = action.payload.data
        state.total_pages = action.payload.meta.pagination.total_pages
        state.page = action.payload.meta.pagination.current_page
        state.per_page = action.payload.meta.pagination.per_page
        state.total_items = action.payload.meta.pagination.total
        if (action.payload.data.length > 0) {
          state.competitionYear = action.payload.data[0] // Set the first competition year as the selected one
        }
      })
      .addCase(fetchCompetitionYears.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const {
  setCompetitionYears,
  setCompetitionYear,
  setAutoStart,
  setSearch,
  setStartDate,
  setEndDate,
  setPage,
  setPerPage,
  setStatusFilter
} = competitionYearSlice.actions

export default competitionYearSlice
