import { useSelector } from 'react-redux'
import { RootState } from 'store'

const useLanguage = () => {
  const direction = useSelector((state: RootState) => state.layout.direction)
  const language = direction === 'rtl' ? 'ar' : 'en'

  return language
}

export default useLanguage
