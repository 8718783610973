import { Path } from 'pages/landing/models'
import img1 from 'assets/images/path-bg-1.png'
import img2 from 'assets/images/path-bg-2.png'
import img3 from 'assets/images/path-bg-3.png'
import { msg } from '@lingui/macro'

const pathList: Path[] = [
  {
    id: 1,
    title: msg`قارئ العام`,
    description: msg`مسابقة سنوية للقراءة أطلقها مركز الملك عبدالعزيز الثقافي العالمي (إثراء) – مبادرة أرامكو السعودية في عام 2013م من المملكة العربية السعودية بهدف نشر ثقافة القراءة وتقدير المعرفة في المجتمع وغرس مفاهيم الاطلاع والقراءة والإنتاج الثقافي المكتوب باللغة العربية إيمانًا بأهمية القراءة بوصفها واحدة من أهم وسائل الإثراء المعرفي للأجيال القادمة. `,
    bgImage: img1,
    showBtn: true,
    type: 'student'
  },
  {
    id: 2,
    title: msg`المدرسة القارئة`,
    description: msg`مسابقة سنوية للقراءة أطلقها مركز الملك عبدالعزيز الثقافي العالمي (إثراء) – مبادرة أرامكو السعودية في عام 2013م من المملكة العربية السعودية بهدف نشر ثقافة القراءة وتقدير المعرفة في المجتمع وغرس مفاهيم الاطلاع والقراءة والإنتاج الثقافي المكتوب باللغة العربية إيمانًا بأهمية القراءة بوصفها واحدة من أهم وسائل الإثراء المعرفي للأجيال القادمة. `,
    bgImage: img2,
    showBtn: false
  },
  {
    id: 3,
    title: msg`سفراء أقرأ`,
    description: msg`مسابقة سنوية للقراءة أطلقها مركز الملك عبدالعزيز الثقافي العالمي (إثراء) – مبادرة أرامكو السعودية في عام 2013م من المملكة العربية السعودية بهدف نشر ثقافة القراءة وتقدير المعرفة في المجتمع وغرس مفاهيم الاطلاع والقراءة والإنتاج الثقافي المكتوب باللغة العربية إيمانًا بأهمية القراءة بوصفها واحدة من أهم وسائل الإثراء المعرفي للأجيال القادمة. `,
    bgImage: img3,
    showBtn: true,
    type: 'teacher'
  }
]

export default pathList
