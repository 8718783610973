import { FC, useEffect, useState } from 'react'
import { DatePicker, DatePickerProps } from '@nextui-org/react'
import { DateValue } from '@internationalized/date'
import Typography from 'components/typography/typography'
import useLanguage from 'hooks/useLanguage'
import classNames from 'classnames' // Install this package for conditional class names

interface DateFieldProps extends Omit<DatePickerProps, 'value' | 'onChange'> {
  label?: string
  helperText?: string
  error?: string | null
  value?: DateValue | null
  onChange?: (value: DateValue | null) => void
}

const DateField: FC<DateFieldProps> = ({ label, helperText, error, value, onChange, ...other }) => {
  const language = useLanguage() // Assuming this hook gives you 'ar' for Arabic and 'en' for English
  const [isRtl, setIsRtl] = useState(language === 'ar')

  useEffect(() => {
    setIsRtl(language === 'ar')
  }, [language])

  return (
    <div className={classNames('flex flex-col gap-2', { rtl: isRtl })}>
      {label && (
        <Typography align={isRtl ? 'right' : 'left'} weight="medium" size="sm">
          {label}
        </Typography>
      )}
      <DatePicker
        aria-label="date picker component"
        {...other}
        color="primary"
        radius="sm"
        variant="bordered"
        value={value}
        onChange={onChange}
      />
      {error && <Typography className="text-red-500 text-sm">{error}</Typography>}
      {helperText && !error && (
        <Typography className="text-gray-500 text-sm">{helperText}</Typography>
      )}
    </div>
  )
}

export default DateField
