import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ActivateRequest, ActivateRequestsResponse, Pagination } from 'api/types'
import dayjs from 'dayjs'
import { cleanObject } from 'utils/cleanObject'

interface ActivateRequestsState {
  requests: ActivateRequest[]
  pagination: Pagination | null
  isLoading: boolean
  error: string | null
  search: string
  status: string
  sortDescriptor: { column: string; direction: 'ascending' | 'descending' }
  page: number
  rowsPerPage: number
  fromSubmissionDate: Date | string
  toSubmissionDate: Date | string
  fromResponseDate: Date | string
  toResponseDate: Date | string
  ithraUsersIds: string[]
  studentsNumberFrom: number | null
  studentsNumberTo: number | null
}

const initialState: ActivateRequestsState = {
  requests: [],
  pagination: null,
  isLoading: false,
  error: null,
  search: '',
  status: '',
  sortDescriptor: { column: 'name', direction: 'ascending' },
  page: 1,
  rowsPerPage: 5,
  fromSubmissionDate: '',
  toSubmissionDate: '',
  fromResponseDate: '',
  toResponseDate: '',
  ithraUsersIds: [],
  studentsNumberFrom: null,
  studentsNumberTo: null
}

export const fetchActivateRequests = createAsyncThunk(
  'activateRequests',
  async (params: {
    // search: string
    status: string
    // sortDescriptor: { column: string; direction: 'ascending' | 'descending' }
    page: number
    rowsPerPage: number
    fromSubmissionDate: Date | string
    toSubmissionDate: Date | string
    fromResponseDate: Date | string
    toResponseDate: Date | string
    ithraUsersIds: string[]
    studentsNumberFrom: number | null
    studentsNumberTo: number | null
  }) => {
    const {
      // search,
      status,
      // sortDescriptor,
      page = 1,
      rowsPerPage = 5,
      fromSubmissionDate,
      toSubmissionDate,
      fromResponseDate,
      toResponseDate,
      ithraUsersIds,
      studentsNumberFrom,
      studentsNumberTo
    } = params

    const paramsObj = {
      // search: search,
      status: status,
      // sortColumn: sortDescriptor.column,
      // sortOrder: sortDescriptor.direction,
      page: page,
      per_page: rowsPerPage,
      submission_date_from: fromSubmissionDate
        ? dayjs(fromSubmissionDate).format('YYYY-MM-DD')
        : '',
      submission_date_to: toSubmissionDate ? dayjs(toSubmissionDate).format('YYYY-MM-DD') : '',
      response_date_from: fromResponseDate ? dayjs(fromResponseDate).format('YYYY-MM-DD') : '',
      response_date_to: toResponseDate ? dayjs(toResponseDate).format('YYYY-MM-DD') : '',
      managed_by: ithraUsersIds,
      number_of_students_from: studentsNumberFrom,
      number_of_students_to: studentsNumberTo
    }

    const cleanedParams = cleanObject(paramsObj)

    const response = await axiosInstance.get<ActivateRequestsResponse>('/management/coordinators', {
      params: {
        ...cleanedParams
      }
    })
    return response.data
  }
)

const activateRequestsSlice = createSlice({
  name: 'activateRequests',
  initialState,
  reducers: {
    setSearchFilter(state, action: PayloadAction<string>) {
      state.search = action.payload
      state.page = 1
    },
    setStatusFilter(state, action: PayloadAction<string>) {
      state.status = action.payload
      state.page = 1
    },
    setSortDescriptor(
      state,
      action: PayloadAction<{ column: string; direction: 'ascending' | 'descending' }>
    ) {
      state.sortDescriptor = action.payload
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload
      state.page = 1
    },
    setSubmissionDate: (
      state,
      action: PayloadAction<{ from: Date | string; to: Date | string }>
    ) => {
      state.fromSubmissionDate = action.payload.from
      state.toSubmissionDate = action.payload.to
      state.page = 1
    },
    setResponseDate: (state, action: PayloadAction<{ from: Date | string; to: Date | string }>) => {
      state.fromResponseDate = action.payload.from
      state.toResponseDate = action.payload.to
      state.page = 1
    },
    setIthraUsersIds(state, action: PayloadAction<string[]>) {
      state.ithraUsersIds = action.payload
      state.page = 1
    },
    setStudentsNumberFrom: (state, action: PayloadAction<number | null>) => {
      state.studentsNumberFrom = action.payload
      state.page = 1
    },
    setStudentsNumberTo: (state, action: PayloadAction<number | null>) => {
      state.studentsNumberTo = action.payload
      state.page = 1
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivateRequests.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchActivateRequests.fulfilled, (state, action) => {
        state.isLoading = false
        state.requests = action.payload?.data ?? []
        state.pagination = action.payload?.meta?.pagination ?? null
      })
      .addCase(fetchActivateRequests.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'Failed to fetch requests'
      })
  }
})

export const {
  setSearchFilter,
  setStatusFilter,
  setSortDescriptor,
  setPage,
  setRowsPerPage,
  setSubmissionDate,
  setResponseDate,
  setIthraUsersIds,
  setStudentsNumberFrom,
  setStudentsNumberTo
} = activateRequestsSlice.actions

export default activateRequestsSlice
