import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import SelectInput from 'components/hook-form/select-input'
import CheckboxInput from 'components/hook-form/checkbox-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { register } from 'store/slices/registerSlice'
import { AppDispatch, RootState } from 'store'
import { setToken, setUserType } from 'store/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { Country } from 'api/types'
import { msg, Trans } from '@lingui/macro'
import { i18n } from '@lingui/core'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'

interface UserRegistrationProps {
  account_type: string
  countries: Country[]
  setShowOTPModal: (show: boolean) => void
  setShowTermsModal: (show: boolean) => void
}

const UserRegistration: React.FC<UserRegistrationProps> = ({
  account_type,
  countries,
  setShowOTPModal,
  setShowTermsModal
}) => {
  const [errorMsg, setErrorMsg] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector((state: RootState) => state.register.isLoading)
  const navigate = useNavigate()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const genderOptions = [
    {
      key: 'male',
      label: direction === 'rtl' ? 'ذكر' : 'Male'
    },
    {
      key: 'female',
      label: direction === 'rtl' ? 'أنثى' : 'Female'
    }
  ]

  const handleLinkClick = () => {
    setShowTermsModal(true)
  }

  const countriesOptions = countries
    .map((country) => {
      if (country) {
        return {
          key: country.id.toString(),
          label:
            direction === 'rtl'
              ? country.name.ar || country.name.en
              : country.name.en || country.name.ar
        }
      }
      return { key: '', label: '' }
    })
    .filter((option) => option.key !== '')

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    gender: '',
    country: '',
    password: '',
    account_type: account_type,
    terms_agreed: false
  }

  const handleTokenAndVerification = (result: any) => {
    // console.log("registration form", localStorage)
    if (result?.data?.accessToken) {
      dispatch(setToken(result.data?.accessToken))
      dispatch(setUserType(result.data.user?.account_type))

      if (result?.data?.user?.email) {
        localStorage.setItem('email', result.data?.user?.email)
      }

      if (!result?.data?.user?.email_verified_at) {
        setShowOTPModal(true)
      } else {
        toast.success(i18n._(msg`يرجى إدخال الكود المرسل إلى بريدك الإلكتروني لتفعيل حسابك`))
        navigate('/login')
      }
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')
    try {
      const result = await dispatch(
        register({
          email: data.email,
          name: data.name,
          phone: data.phone,
          gender: data.gender,
          country_id: parseInt(data.country, 10),
          password: data.password,
          account_type: account_type,
          terms_agreed: data.terms_agreed
        })
      ).unwrap()

      if (result.status === 'success') {
        handleTokenAndVerification(result)
      }
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message))
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}
      <TextInput name="name" label={msg`الإسم`.id} placeholder={msg`الإسم الأول`.id} />
      <TextInput
        name="email"
        label={msg`البريد الإلكتروني`.id}
        type="email"
        placeholder={msg`exmple@gmail.com`.id}
        helperText="Enter your email address"
        formNoValidate
      />
      <TextInput
        name="password"
        label={msg`كلمة المرور`.id}
        type="password"
        placeholder={msg`أدخل كلمة مرور`.id}
        helperText="Exmple@gmail.com"
      />
      <TextInput name="phone" label={msg`رقم الجوال`.id} placeholder="" type="phone-number" />
      <SelectInput
        name="gender"
        label={msg`الجنس`.id}
        options={genderOptions}
        selectionMode="single"
        placeholder={msg`الجنس`.id}
      />
      <SelectInput
        name="country"
        label={msg`الدولة`.id}
        mode="search"
        options={countriesOptions}
        selectionMode="single"
        placeholder={msg`الدولة`.id}
      />
      <CheckboxInput
        name="terms_agreed"
        label="terms-and-conditions"
        labelWithLink={{
          text: msg`اقرار على الموافقه على`.id,
          linkText: msg`الشروط والاحكام`.id,
          onClick: handleLinkClick
        }}
        layout="second"
      />

      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          isLoading={isLoading}
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          <p>
            <Trans>انضم الان</Trans>
          </p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default UserRegistration
