const ArrowDawnIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M11.9968 13.1727L16.9468 8.22266L18.3608 9.63666L11.9968 16.0007L5.63281 9.63666L7.04681 8.22266L11.9968 13.1727Z"
          fill="#13100D"
        />
      </g>
    </svg>
  )
}

export default ArrowDawnIcon
