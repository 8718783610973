import { FC, ReactNode } from 'react'

interface CardLayoutProps {
  children: ReactNode
  onClick?: () => void
}

const CardLayout: FC<CardLayoutProps> = ({ children, onClick }) => {
  return (
    <div
      className="py-8 px-2 flex flex-col gap-2 border rounded-xl bg-bgCard border-borderCard cursor-pointer hover:bg-bgCardHover"
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default CardLayout
