import {
  Modal,
  ModalContent,
  ModalProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ScrollShadow
} from '@nextui-org/react'
import ArrowIcon from 'assets/icons/ArrowIcon'
import ButtonVariant from 'components/button-variant/button'
import { FC, ReactNode } from 'react'

interface ModalLayoutProps extends Omit<ModalProps, 'children' | 'open'> {
  showCloseButton?: boolean
  showBackButton?: boolean
  defaultOpen?: boolean
  isKeyboardDismissDisabled?: boolean
  isDismissable?: boolean
  onBack?: () => void
  header?: ReactNode
  body?: ReactNode
  footer?: ReactNode
  children?: ReactNode
  className?: string
  backdrop?: 'transparent' | 'opaque' | 'blur' | undefined
  accountPageStyle?: boolean
  appStyle?: boolean
}

const ModalLayout: FC<ModalLayoutProps> = ({
  showCloseButton = false,
  showBackButton = true,
  defaultOpen = true,
  isKeyboardDismissDisabled = true,
  isDismissable = false,
  onBack,
  header,
  body,
  footer,
  children,
  className,
  backdrop = 'transparent',
  accountPageStyle,
  appStyle,
  ...other
}) => {
  const combinedClassNames = {
    wrapper: `${accountPageStyle ? `justify-center pt-24 md:pt-32` : ''} ${appStyle ? `bg-[#44146D4D]` : ''}`,
    base: `${accountPageStyle ? `pointer-events-auto` : ''} ${appStyle ? `` : ''}`
  }

  return (
    <Modal
      hideCloseButton={!showCloseButton}
      backdrop={backdrop}
      placement="center"
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      defaultOpen={defaultOpen}
      className={`rounded-[12px] shadow-md bg-white ${className}`}
      classNames={combinedClassNames}
      {...other}
    >
      <ModalContent className="flex flex-col px-10 py-6 gap-6">
        {(onClose) => (
          <>
            {showBackButton && (
              <div className="w-full flex justify-end text-natural_icon_normal">
                <ButtonVariant
                  isIconOnly
                  size="sm"
                  variant="clear"
                  iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
                  iconRotate
                  onClick={onBack}
                />
              </div>
            )}
            <ModalHeader className="w-full p-0">{header}</ModalHeader>
            <ScrollShadow className="w-full p-0 scrollbar-thin scrollbar-thumb-gray-400">
              <ModalBody className="w-full p-0">
                {body}
                {children}
              </ModalBody>
            </ScrollShadow>
            <ModalFooter className="w-full p-0 flex justify-center items-center">
              {footer}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalLayout
