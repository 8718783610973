import { Button } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function ReviewButton({ requestId }: { requestId: number }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToReview = () => {
    navigate(`/app/activate-requests/review-request/${requestId}`)
  }

  return (
    <Button color="primary" onPress={goToReview}>
      {t('REVIEW')}
    </Button>
  )
}
