import React from 'react'
import { AboutCard as AboutCardType } from '../../models'
import { Trans } from '@lingui/react'
import { useDevice } from 'pages/landing/context/device-context'

interface AboutCardProps {
  card: AboutCardType
  isActive: number | null
  setIsActive: React.Dispatch<React.SetStateAction<number | null>>
}

const AboutCard: React.FC<AboutCardProps> = ({ card, isActive, setIsActive }) => {
  const isMobile = useDevice()

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsActive(card.id)
    }
  }

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsActive(null)
    }
  }

  const handleClick = () => {
    if (isMobile) {
      setIsActive(card.id === isActive ? null : card.id)
    }
  }

  return (
    <div
      className={`mx-2.5 h-[320px] transition-all duration-500 ease-in-out
      ${isActive === card.id ? 'flex-grow' : 'w-[66vw] md:w-[323px]'}
      `}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`w-[66vw] h-full flex flex-col place-content-center px-6 md:px-9 rounded-[20px] shadow-lg transition-all duration-500 ease-in-out
          ${isActive === card.id ? 'md:w-full items-start bg-customTeal' : 'md:w-[323px] items-center bg-customWhite'}
          `}
      >
        <h2
          className={`font-bold pb-3
            ${isActive === card.id ? 'text-4xl text-customWhite' : 'text-5xl text-customTeal'}
            `}
        >
          <Trans id={card.title.id} />
        </h2>
        <ul
          className={` text-customWhite
              ${card.description.length > 1 ? 'text-xl' : 'text-3xl'}
              ${isActive === card.id ? 'flex flex-col space-y-1' : 'hidden'}
              `}
        >
          {card.description.map((point, index) => (
            <li key={index}>
              <Trans id={point.id} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AboutCard
