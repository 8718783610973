import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { Workflow, WorkflowResponse } from 'api/types'

interface WorkflowState {
  workflows: Workflow[] | null
  isLoading: boolean
  error: string | null
  page: number
  per_page: number
  total_pages: number
  total_items: number
}

const initialState: WorkflowState = {
  workflows: null,
  isLoading: false,
  error: null,
  page: 1,
  per_page: 10,
  total_pages: 1,
  total_items: 0
}

export const fetchWorkflows = createAsyncThunk(
  'workflow/fetch',
  async ({
    competitionId,
    page = 1,
    per_page = 10
  }: {
    competitionId: string
    page?: number
    per_page?: number
  }) => {
    const response = await axiosInstance.get<WorkflowResponse>(
      `/management/competition-years/${competitionId}/workflows`,
      {
        params: { page, per_page }
      }
    )
    return response.data
  }
)

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflows: (state, action: PayloadAction<Workflow[]>) => {
      state.workflows = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflows.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchWorkflows.fulfilled, (state, action) => {
        state.isLoading = false
        state.workflows = action.payload.data
        state.page = action.payload.meta.pagination.current_page
        state.per_page = action.payload.meta.pagination.per_page
        state.total_pages = action.payload.meta.pagination.total_pages
        state.total_items = action.payload.meta.pagination.total
      })
      .addCase(fetchWorkflows.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export const { setWorkflows, setPage, setPerPage } = workflowSlice.actions

export default workflowSlice
