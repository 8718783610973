import React from 'react'
import UserSection from './UserSection'
import IntroSection from './IntroSection'
import { User } from '../types'

interface SelectUserBackgroundProps {
  selectedUser: User | null
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>
  userList: User[]
}

const SelectUserBackground: React.FC<SelectUserBackgroundProps> = ({
  selectedUser,
  setSelectedUser,
  userList
}) => {
  const handleUserClick = (user: User) => {
    setSelectedUser(user)
  }

  return (
    <div className="relative h-full flex flex-col md:flex-row">
      <div
        className={`md:h-[100%] ${selectedUser ? 'w-[100%] h-[0%] md:w-[0%]' : 'w-[100%] h-[100%] md:w-[31%]'} transition-all ease-in-out duration-1000`}
      >
        {!selectedUser && <IntroSection />}
      </div>

      {userList.map((user: User) => (
        <div
          key={user.id}
          className={`w-[100%] ${selectedUser && selectedUser === user ? 'h-[100%] md:w-[100%] md:h-[100%]' : 'h-[0%] md:w-[0%] md:h-[100%]'} ${!selectedUser ? 'md:w-[23%] h-[100%]' : ''} transition-all ease-in-out duration-1000`}
          onClick={() => handleUserClick(user)}
        >
          <UserSection user={user} isActive={user === selectedUser} selectedUser={selectedUser} />
        </div>
      ))}
    </div>
  )
}

export default SelectUserBackground
