export const PendingApplicationIcon = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64.3281 35.677V55.2769C66.4466 57.0133 68.7945 58.4542 71.3155 59.5372C74.1855 60.7702 76.1683 63.6731 76.0411 67.023C75.8873 71.0722 72.588 74.3716 68.5387 74.5253C67.0014 74.5838 65.5589 74.1967 64.3281 73.4845V78.4375H4.95312V1.5625H64.3281V21.5755"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.7656 7.8125V12.0312C21.9781 12.0312 13.2344 20.775 13.2344 31.5625V7.8125H32.7656Z"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.7656 51.0938C43.5524 51.0938 52.2969 42.3493 52.2969 31.5625C52.2969 20.7757 43.5524 12.0312 32.7656 12.0312C21.9788 12.0312 13.2344 20.7757 13.2344 31.5625C13.2344 42.3493 21.9788 51.0938 32.7656 51.0938Z"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.9141 53.0238L54.5433 46.3945L63.1094 54.2203C65.533 56.4345 68.3028 58.2409 71.3191 59.5367C74.1891 60.7697 76.1719 63.6727 76.0447 67.0225C75.8909 71.0717 72.5916 74.3711 68.5423 74.5248C65.1923 74.652 62.2894 72.6692 61.0566 69.7992C59.7608 66.7831 57.9544 64.0131 55.7402 61.5897L47.9141 53.0238Z"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.2821 55.2344C42.5024 56.4552 44.4813 56.4553 45.702 55.2347L56.7512 44.1857C57.9715 42.9654 57.9715 40.9871 56.7512 39.7668C55.531 38.5466 53.5526 38.5464 52.3324 39.7666L41.2827 50.8152C40.0623 52.0355 40.062 54.0139 41.2821 55.2344Z"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56.0469 20.3125H48.7812"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56.0469 14.0625H41.5938"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56.0469 7.8125H47.7656"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2344 69.8438H53.5469"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2344 63.5938H48.3906"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2344 57.3438H32.7656"
        stroke="#2AACAD"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
