import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Country, User } from 'pages/AccountPages/types'
import ModalLayout from 'components/modal/modal-layout'
import ModalHeader from 'components/modal/modal-header'
import ButtonVariant from 'components/button-variant/button'
import UserRegistration from '../forms/user-registration'
import { Trans } from '@lingui/react'
import { Trans as Translation } from '@lingui/macro'

interface RegistrationModalProps {
  countries?: Country[]
  selectedUser?: User | null
  setSelectedUser?: React.Dispatch<User | null>
  isHidden: boolean
  setShowTermsModal: (show: boolean) => void
  setShowOTPModal: (show: boolean) => void
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  countries,
  selectedUser,
  setSelectedUser,
  isHidden,
  setShowTermsModal,
  setShowOTPModal
}) => {
  const navigate = useNavigate()

  const handleBack = () => {
    if (setSelectedUser) {
      setSelectedUser(null)
    }
  }

  const handleClick = () => {
    navigate('/login')
  }

  return (
    <>
      {selectedUser && (
        <ModalLayout
          className={`${isHidden ? 'opacity-0' : ''} transition-opacity ease-in-out duration-500`}
          onBack={handleBack}
          accountPageStyle
          placement='top'
          scrollBehavior="outside"
          header={
            <ModalHeader
              headerText={selectedUser.modalTitle}
              subheaderText={<Trans id={selectedUser.description.id} />}
            />
          }
          body={
            countries && (
              <UserRegistration
                countries={countries}
                account_type={selectedUser.type}
                setShowOTPModal={setShowOTPModal}
                setShowTermsModal={setShowTermsModal}
              />
            )
          }
          footer={
            <div className="mx-auto">
              <ButtonVariant variant="clear" onClick={handleClick}>
                <p>
                  <Translation>هل لديك حساب ؟</Translation>
                </p>
              </ButtonVariant>
            </div>
          }
        />
      )}
    </>
  )
}

export default RegistrationModal
