import { FC } from 'react'
import ButtonVariant from 'components/button-variant/button'
import Typography from 'components/typography/typography'
import useLanguage from 'hooks/useLanguage'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

type AlertProps = {
  mainText: string
  weightMainText?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold'
  sizeMainText?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  subText?: string
  buttonText?: string
  onButtonClick?: () => void
  onClose?: () => void // Add a close handler for the alert
  closable?: boolean
}

export const Alert: FC<AlertProps> = ({
  mainText,
  weightMainText = 'semibold',
  sizeMainText = 'lg',
  subText,
  buttonText,
  onButtonClick,
  onClose,
  closable = false
}) => {
  const language = useLanguage()
  const { _ } = useLingui()

  const handleClick = () => {
    if (closable && onClose) {
      onClose()
    }
  }

  return (
    <div
      dir={language === 'ar' ? 'rtl' : 'ltr'}
      className="relative py-4 px-2 flex flex-row items-center bg-secondary_fill_normal_pressed bg-opacity-10 rounded-xl border-1 border-success_default_stroke_pressed  mb-4 text-success_default_stroke_pressed w-full justify-between"
      onClick={handleClick}
    >
      <div className="flex flex-col gap-1">
        <Typography color="success" size={sizeMainText} weight={weightMainText}>
          {mainText}
        </Typography>
        {subText && (
          <Typography color="success" size="md">
            {subText}
          </Typography>
        )}
      </div>
      {buttonText && (
        <ButtonVariant
          className="bg-successDarker text-white"
          radius="full"
          onPress={onButtonClick}
        >
          {buttonText}
        </ButtonVariant>
      )}

      {/* Close Icon */}
      {!closable && onClose && (
        <ButtonVariant variant="secondary" onClick={onClose}>
          {_(msg`غلق`)}
        </ButtonVariant>
      )}
    </div>
  )
}

export default Alert
