const FileSecondIcon = ({ size = '24' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00002 1.33403V1.33203H13.332C13.7 1.33203 14 1.63536 14 1.99336V14.004C13.9998 14.1795 13.93 14.3477 13.8059 14.4717C13.6818 14.5957 13.5135 14.6654 13.338 14.6654H2.66202C2.57447 14.6648 2.4879 14.6469 2.40725 14.6128C2.3266 14.5788 2.25345 14.5291 2.19197 14.4668C2.1305 14.4045 2.08191 14.3306 2.04897 14.2495C2.01604 14.1684 1.9994 14.0816 2.00002 13.994V5.33203L6.00002 1.33403ZM3.88668 5.33203H6.00002V3.2187L3.88668 5.33203ZM7.33335 2.66536V5.9987C7.33335 6.17551 7.26311 6.34508 7.13809 6.4701C7.01306 6.59513 6.84349 6.66536 6.66668 6.66536H3.33335V13.332H12.6667V2.66536H7.33335Z"
        fill="#3F3F46"
      />
    </svg>
  )
}

export default FileSecondIcon
