import React from "react";

const LogoutIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7073_72710)">
        <path
          d="M5.33594 17.0013H7.0026V18.668H17.0026V5.33464H7.0026V7.0013H5.33594V4.5013C5.33594 4.28029 5.42373 4.06833 5.58002 3.91205C5.7363 3.75577 5.94826 3.66797 6.16927 3.66797H17.8359C18.057 3.66797 18.2689 3.75577 18.4252 3.91205C18.5815 4.06833 18.6693 4.28029 18.6693 4.5013V19.5013C18.6693 19.7223 18.5815 19.9343 18.4252 20.0906C18.2689 20.2468 18.057 20.3346 17.8359 20.3346H6.16927C5.94826 20.3346 5.7363 20.2468 5.58002 20.0906C5.42373 19.9343 5.33594 19.7223 5.33594 19.5013V17.0013ZM7.0026 11.168H12.8359V12.8346H7.0026V15.3346L2.83594 12.0013L7.0026 8.66797V11.168Z"
          fill="#52525B"
        />
      </g>
      <defs>
        <clipPath id="clip0_7073_72710">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
