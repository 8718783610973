import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { ResendVerificationEmailResponse } from 'api/types'

interface ResendVerificationEmailState {
  isLoading: boolean
  error: string | null
}

const initialState: ResendVerificationEmailState = {
  isLoading: false,
  error: null
}

export const resendVerificationEmail = createAsyncThunk(
  'resendVerificationEmail',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.post<ResendVerificationEmailResponse>(
        '/auth/resend-verification-email',
        {}
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const resendVerificationEmailSlice = createSlice({
  name: 'resendVerificationEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resendVerificationEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(resendVerificationEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(resendVerificationEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default resendVerificationEmailSlice
