import React from 'react'
import ButtonVariant from 'components/button-variant/button'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import UserLogin from '../forms/user-login'
import { useNavigate } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { msg } from '@lingui/macro'

const LoginModal: React.FC<{ handleOTPModalDisplay: (shouldShow: boolean) => void }> = ({
  handleOTPModalDisplay
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/register')
  }

  return (
    <ModalLayout
      showBackButton={false}
      accountPageStyle
      header={
        <ModalHeader
          headerText={msg`تسجيل الدخول`}
          subheaderText={<Trans>أهلاً بك مجدداً، سجل دخولك الآن واستفد من خدماتنا</Trans>}
        />
      }
      body={<UserLogin handleOTPModalDisplay={handleOTPModalDisplay} />}
      footer={
        <div className="mx-auto">
          <ButtonVariant variant="clear" onClick={handleClick}>
            <p><Trans>ليس لديك حساب ؟</Trans></p>
          </ButtonVariant>
          {/* <span className="border border-r h-6" />
          <ButtonVariant variant="clear">
            <p>هل واجهت مشكلة ؟</p>
          </ButtonVariant> */}
        </div>
      }
    />
  )
}

export default LoginModal
