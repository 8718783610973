import React from 'react'

const Users: React.FC = () => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Users</h2>
      <p>This is the Users page.</p>
      <h2 className="text-xl font-bold mb-4">Users</h2>
      <p>This is the Users page.</p>
    </div>
  )
}

export default Users
