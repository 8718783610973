export interface LocalizedName {
  ar: string
  en: string
}

export const getNameByLanguage = (name: LocalizedName | null, lang: 'ar' | 'en'): string => {
  if (!name) {
    return '-'
  }

  const primaryName = name[lang]
  const fallbackLang = lang === 'ar' ? 'en' : 'ar'
  const fallbackName = name[fallbackLang]

  if (primaryName && primaryName.trim()) {
    return primaryName
  } else if (fallbackName && fallbackName.trim()) {
    return fallbackName
  } else {
    return '-'
  }
}
