import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userList } from './user-list'
import { User } from 'pages/AccountPages/types'
import Layout from 'pages/AccountPages/Layout'
import SelectUserBackground from 'pages/AccountPages/components/SelectUserBackground'
import { AppDispatch, RootState } from 'store'
import RegistrationModal from 'pages/AccountPages/components/modals/RegistrationModal'
import OTPModal from 'pages/AccountPages/components/modals/OTPModal'
import TermsModal from 'pages/AccountPages/components/modals/TermsModal'
import ChangeEmailModal from '../components/modals/ChangeEmailModal'
import { fetchCountries } from 'store/slices/countriesSlice'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { msg } from '@lingui/macro'

const Registration = () => {
  useDocumentTitle(msg`التسجيل`)
  const dispatch = useDispatch<AppDispatch>()
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const { countries } = useSelector((state: RootState) => state.countries)

  const [showOTPModal, setShowOTPModal] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  let modalContent
  if (showOTPModal) {
    modalContent = (
      <OTPModal
        handleOTPModalDisplay={setShowOTPModal}
        handleEmailModalDisplay={setShowChangeEmailModal}
        isLoginFlow={false}
      />
    )
  } else if (showTermsModal) {
    modalContent = <TermsModal handleTermsModalDisplay={setShowTermsModal} />
  } else if (showChangeEmailModal) {
    modalContent = (
      <ChangeEmailModal
        handleEmailModalDisplay={setShowChangeEmailModal}
        handleOTPModalDisplay={setShowOTPModal}
      />
    )
  }

  return (
    <Layout>
      <SelectUserBackground
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        userList={userList}
      />
      {modalContent}
      <RegistrationModal
        countries={countries}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isHidden={showTermsModal || showOTPModal || showChangeEmailModal}
        setShowTermsModal={setShowTermsModal}
        setShowOTPModal={setShowOTPModal}
      />
    </Layout>
  )
}

export default Registration
