import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Spinner,
  Pagination
} from '@nextui-org/react'
import { FC } from 'react'

interface Column {
  key: string
  label: string
}

interface DataTableProps<T> {
  columns: Column[]
  data: T[]
  isLoading: boolean
  page: number
  totalPages: number
  onPageChange: (newPage: number) => void
  renderCell: (item: T, columnKey: string | number) => React.ReactNode
}

export const DataTable: FC<DataTableProps<any>> = ({
  columns,
  data,
  isLoading,
  page,
  totalPages,
  onPageChange,
  renderCell
}) => {
  return (
    <div className="block-inline">
      <Table aria-label="Dynamic data table">
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={data}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
          emptyContent="No data available"
        >
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="mt-8 flex justify-end w-full">
        <Pagination
          showControls
          loop
          total={totalPages}
          initialPage={page}
          onChange={onPageChange}
        />
      </div>
    </div>
  )
}

export default DataTable
