import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setResponseDate } from 'store/slices/activateRequestsSlice'
import DateRangePicker from 'components/DateRangePicker'

const ResponseDateFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fromResponseDate, toResponseDate } = useSelector(
    (state: RootState) => state.activateRequests
  )

  const handleResponseDateChange = (selectedDates: (Date | string)[]) => {
    const [start, end] = selectedDates
    const fromDate = start ? start : ''
    const toDate = end ? end : ''
    dispatch(setResponseDate({ from: fromDate, to: toDate }))
  }

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('RESPONSE_DATE_FILTER')}</p>
      <DateRangePicker
        value={[fromResponseDate, toResponseDate]}
        onChange={handleResponseDateChange}
      />
    </div>
  )
}

export default ResponseDateFilter
