import { FC } from 'react'
import { Activity } from 'api/types'
import HistoryAction from 'components/history-actions/history-actions'
import { useTranslation } from 'react-i18next'

type HistoryChangesProps = {
  activities: Activity[]
}

export const HistoryChanges: FC<HistoryChangesProps> = ({ activities }) => {
  const { t } = useTranslation()
  return (
    <div className="relative flex flex-col mt-8 gap-6">
      {activities.map((activity, index, arr) => (
        <HistoryAction
          key={index}
          event={activity.event as 'created' | 'updated'}
          description={
            activity.event === 'created'
              ? `${t('It has been determined')} ${activity.properties?.attributes?.name}`
              : `${t('Modified')} ${activity.properties?.attributes?.name}`
          }
          date={new Date(activity.created_at).toLocaleDateString()}
          time={new Date(activity.created_at).toLocaleTimeString()}
          causerName={activity.causer?.name || 'Unknown'}
          showDivider={index < arr.length - 1}
        />
      ))}
    </div>
  )
}

export default HistoryChanges
