import useLanguage from 'hooks/useLanguage'
import { useSchools } from './get-schools'
import { getLocalizedName } from '../utils'

interface UseSchoolOptionsProps {
  countryId?: string
  regionId?: string // Array of region IDs
}

export const useSchoolOptions = ({ countryId, regionId }: UseSchoolOptionsProps) => {
  const language = useLanguage() as 'ar' | 'en'
  const { schools, isLoading } = useSchools({ countryId, regionId })

  const options =
    schools.length === 0
      ? [
          {
            key: '', // Empty value for the placeholder option
            label: language === 'ar' ? 'لا يوجد بيانات للاختيار' : 'No options available'
          }
        ]
      : schools.map((school: any) => ({
          key: String(school.id),
          label: getLocalizedName(school.name, language)
        }))

  return { options, isLoading }
}
