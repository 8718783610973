import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface ArrowLeftProps {
  fill?: string
}

export const ArrowLeft: React.FC<ArrowLeftProps> = ({ fill = '#F9FAFB' }) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  const rotate = direction === 'rtl' ? 0 : 180
  const style = {
    transform: `rotate(${rotate}deg)`
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        d="M7.828 11.0007H20V13.0007H7.828L13.192 18.3647L11.778 19.7787L4 12.0007L11.778 4.22266L13.192 5.63666L7.828 11.0007Z"
        fill={fill}
      />
    </svg>
  )
}
