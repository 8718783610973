import { Winner } from 'pages/landing/models'
import { useState } from 'react'
import WinnerCard from './winner-card'
import winnersList from './winners-list'
import YearSelector from './year-selector'
import CardSlider from 'components/base/card-slider'
import SectionTitle from 'components/base/section-title'
import { msg } from '@lingui/macro'

const WinnersSection = () => {
  const yearsList = Object.keys(winnersList).map((year) => parseInt(year))
  const [activeYear, setActiveYear] = useState(yearsList[yearsList.length - 1])

  return (
    <div className="py-7 flex flex-col space-y-14">
      <div className="w-full md:w-10/12 mx-auto space-y-14 px-3.5 md:px-0">
        <SectionTitle
          title={msg`الفائزين`}
          sectionDescription={msg`فصلٌ من الحكاية هنا تعرفوا عليها`}
        />

        <YearSelector yearsList={yearsList} activeYear={activeYear} setActiveYear={setActiveYear} />
      </div>
      <CardSlider>
        {winnersList[activeYear].map((winner: Winner, index: number) => (
          <WinnerCard key={index} winner={winner} />
        ))}
      </CardSlider>
    </div>
  )
}

export default WinnersSection
