import React, { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Typography from 'components/typography/typography'
import OtpInputField from 'components/otp-field/otp-field'

interface OtpInputProps {
  name: string
}

const OtpInput: React.FC<OtpInputProps> = ({ name }) => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger
  } = useFormContext()

  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target

    if (!/^\d*$/.test(value)) {
      return
    }

    if (value.length > 1) {
      e.target.value = value.slice(-1)
    }

    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  // Handle pasting of OTP values
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const paste = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 4) // Get only the first 4 digits
    paste.split('').forEach((char, index) => {
      if (inputRefs.current[index]) {
        setValue(`otp${index + 1}`, char)
        const inputEvent = new Event('input', { bubbles: true })
        inputRefs.current[index]!.dispatchEvent(inputEvent)
      }
    })
    trigger()
  }

  const otpError = errors?.[name]?.message?.toString()

  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-4 justify-center" onPaste={handlePaste} dir="ltr">
        {Array.from({ length: 4 }).map((_, index) => (
          <Controller
            key={index}
            name={`otp${index + 1}`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <OtpInputField
                {...field}
                ref={(el: HTMLInputElement) => (inputRefs.current[index] = el)}
                maxLength={1}
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value)
                  handleInputChange(e, index)
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
                onFocus={handleFocus}
              />
            )}
          />
        ))}
      </div>
      {otpError && <Typography className="text-red-500 text-sm mt-2">{otpError}</Typography>}
    </div>
  )
}

export default OtpInput
