import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Form from 'components/FormStructure/Form'
import validationSchema from './validation-schema'
import TextInput from 'components/hook-form/text-input'
import ButtonVariant from 'components/button-variant/button'
import ArrowIcon from 'assets/icons/ArrowIcon'
import { AppDispatch, RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { signIn } from 'store/slices/signInSlice'
import { setToken, setEmail, setVerifiedAt, setUserType } from 'store/slices/authSlice'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro'
import ErrorMessage from 'pages/AccountPages/components/ErrorMessage'

const UserLogin: React.FC<{ handleOTPModalDisplay: (shouldShow: boolean) => void }> = ({ handleOTPModalDisplay }) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState<string>('')

  const isLoading = useSelector((state: RootState) => state.signIn.isLoading)

  const defaultValues = {
    email: '',
    password: ''
  }

  const handleTokenAndVerification = (result: any) => {
    if (result?.data.accessToken) {
      dispatch(setToken(result.data.accessToken))
      dispatch(setEmail(result.data.user?.email))
      dispatch(setVerifiedAt(result.data.user?.email_verified_at))
      dispatch(setUserType(result.data.user?.account_type))

      

      if (!result.data.user?.email_verified_at) {
        handleOTPModalDisplay(true)
      } else {
        toast.success( i18n._(msg`تم تسجيل الدخول بنجاح`))
        navigate('/app/home')
      }
    }
  }

  const onSubmit = async (data: typeof defaultValues) => {
    setErrorMsg('')
    try {
      const result = await dispatch(
        signIn({
          email: data.email,
          password: data.password
        })
      ).unwrap()

      if (result.status === 'success') {
        handleTokenAndVerification(result)
      }
    } catch (error: any) {
      setErrorMsg(error.message)
      toast.error(i18n._(error.message))
    }
  }

  return (
    <Form schema={validationSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
      {errorMsg && <ErrorMessage msgstr={errorMsg} />}
      <TextInput
        name="email"
        label={msg`البريد الإلكتروني`.id}
        type="email"
        placeholder={msg`Exmple@gmail.com`.id}
        helperText="Enter your email address"
      />
      <TextInput
        name="password"
        label={msg`كلمة المرور`.id}
        type="password"
        placeholder={msg`أدخل كلمة المرور`.id}
        helperText="Enter your password"
      />

      <div className="flex w-full justify-center">
        <ButtonVariant variant="clear" onClick={() => navigate('/forgot-password')} size="lg">
          <p><Trans>هل نسيت كلمة المرور؟</Trans></p>
        </ButtonVariant>
      </div>

      <div className="flex w-full justify-center">
        <ButtonVariant
          variant="secondary"
          type="submit"
          size="lg"
          isLoading={isLoading}
          iconEnd={<ArrowIcon fill="currentColor" width="24" height="24" />}
          iconRotate
        >
          <p><Trans>تسجيل الدخول</Trans></p>
        </ButtonVariant>
      </div>
    </Form>
  )
}

export default UserLogin
