import React from 'react'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <div className={`transition-opacity duration-700 ease-in-out`}>
        <div className="pt-24 md:pt-32 h-screen">{children}</div>
      </div>
    </>
  )
}

export default Layout
