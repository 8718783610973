import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  newEmail: string | null
}

const initialState: AuthState = {
  newEmail: (localStorage.getItem('newEmail') as string) || null
}

const newEmailSlice = createSlice({
  name: 'newEmail',
  initialState,
  reducers: {
    setNewEmail: (state, action: PayloadAction<string | null>) => {
      state.newEmail = action.payload
      if (action.payload) {
        localStorage.setItem('newEmail', action.payload)
      }
    }
  }
})

export const { setNewEmail } = newEmailSlice.actions

export default newEmailSlice
