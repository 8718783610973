import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { VerifyNewEmailResponse } from 'api/types'

interface VerifyNewEmailState {
  isLoading: boolean
  error: string | null
}

const initialState: VerifyNewEmailState = {
  isLoading: false,
  error: null
}

export const verifyNewEmail = createAsyncThunk(
  'verifyNewEmail',
  async (
    {
      code
    }: {
      code: string
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post<VerifyNewEmailResponse>(
        '/auth/profile/new-email/verify',
        {
          code: code,
          guard: 'users'
        }
      )
      return response.data
    } catch (error: any) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else if (error.request) {
        return thunkAPI.rejectWithValue(error.request)
      } else {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  }
)

const verifyNewEmailSlice = createSlice({
  name: 'verifyNewEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(verifyNewEmail.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(verifyNewEmail.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(verifyNewEmail.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default verifyNewEmailSlice
