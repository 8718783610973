import React, { useState } from 'react'
import MenuIcon from 'assets/icons/MenuIcon'
import { useWindowSize } from 'usehooks-ts'
import CompetitionYear from 'components/CompetitionYear'
import NotificationDropdown from 'components/Notification'
import UserDropDown from 'components/UserDropDown'
import DividerHeader from 'components/DividerHeader'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import LanguageButton from 'components/base/language-button'
import Logo from './Logo'
import MobileMenu from '../components/MobileMenu'

function Header() {
  const { width = 0 } = useWindowSize()
  const data = useSelector((state: RootState) => state.me?.me?.data)
  const isMobile = width <= 430
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => setIsMenuOpen((prev) => !prev)

  return (
    <header className="p-2 mt-4 md:mt-0 md:px-[30px] md:pt-10 md:pb-6">
      <div className="bg-white px-3 py-1.5 md:px-8 md:py-3.5 rounded-2xl md:rounded-[24px] flex justify-between items-center md:shadow-xl">
        <div className="w-[60px] h-[45px] md:w-[88px] md:h-[94px] flex items-center justify-center">
          <Logo />
        </div>

        {!isMobile ? (
          <div className="py-0.5 flex gap-4 items-center">
            {data && <UserDropDown name={data?.name} account_type={data?.account_type} />}
            <DividerHeader />
            <CompetitionYear />
            <DividerHeader />
            <NotificationDropdown />
            <DividerHeader />
            <LanguageButton fillColor={'rgba(63, 63, 70, 1)'} textClass={'text-naturalLv2'} />
          </div>
        ) : (
          <div className="flex items-center">
            <LanguageButton fillColor={'rgba(63, 63, 70, 1)'} textClass={'text-naturalLv2'} />
            <div onClick={toggleMenu}>
              <MenuIcon />
            </div>
          </div>
        )}

        {isMobile && isMenuOpen && <MobileMenu />}
      </div>
    </header>
  )
}

export default Header
