import { User } from 'api/types'

export function getCoordinatorStatusApplication(data: User) {
  return data.coordinator?.status
}

export function getRegionLabelFromCountry(
  labelRegion: { ar: string; en: string },
  language: 'ar' | 'en'
) {
  return language === 'ar' ? labelRegion.ar : labelRegion.en
}

export async function fetchFileFromURL(url: string): Promise<File | null> {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error('Network response was not ok')
    const blob = await response.blob()
    const file = new File([blob], 'proof_document', { type: blob.type })
    return file
  } catch (error) {
    console.error('Failed to fetch file:', error)
    return null
  }
}
