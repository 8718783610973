import { DatePicker, Select, SelectItem } from '@nextui-org/react'
import { useStatusOptions } from 'pages/CompetitionYear/CreateCompetition/CreateCompetition'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateValue, parseDate } from '@internationalized/date'
import { CompetitionYearDetails } from 'api/types'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { toast } from 'react-toastify'
import { updateCompetition } from 'store/slices/updateCompetitionYear'
import { fetchCompetitionYearDetails } from 'store/slices/competitionYearDetails'
import ButtonVariant from 'components/button-variant/button'

export const useYearOptions = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i) // Generate the next 10 years
  return years.map((year) => ({ key: year.toString(), label: t(year.toString()) }))
}

export const getYearFromDate = (dateString: string): string => {
  const date = new Date(dateString)
  return date.getFullYear().toString()
}

type YearInformationProps = {
  competitionYear: CompetitionYearDetails
  id: string | undefined
}

const schema = z.object({
  status: z.string().nonempty({ message: 'Status is required' }),
  startDate: z.string().nonempty({ message: 'Start date is required' }),
  endDate: z.string().nonempty({ message: 'End date is required' }),
  year: z.string().nonempty({ message: 'Year is required' })
})

type YearInformationSchemaType = z.infer<typeof schema>

export const YearInformation: FC<YearInformationProps> = (props) => {
  const { t } = useTranslation()

  const isLoading = useSelector((state: RootState) => state.updateCompetitionYear.isLoading)
  const dispatch = useDispatch<AppDispatch>()
  const { competitionYear, id: idCompetition } = props
  const statusOptions = useStatusOptions()
  const yearOptions = useYearOptions()

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<YearInformationSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      status: competitionYear.status,
      startDate: competitionYear.start_date,
      endDate: competitionYear.end_date,
      year: getYearFromDate(competitionYear.start_date)
    }
  })

  const selectedYear = watch('year')
  const startDate = watch('startDate')
  const endDate = watch('endDate')

  useEffect(() => {
    const startYear = getYearFromDate(competitionYear.start_date)
    setValue('year', startYear)
    setValue('startDate', competitionYear.start_date)
    setValue('endDate', competitionYear.end_date)
    setValue('status', competitionYear.status)
  }, [competitionYear, setValue])

  useEffect(() => {
    if (selectedYear) {
      const newStartDate = `${selectedYear}-${startDate.split('-')[1]}-${startDate.split('-')[2]}`
      const newEndDate = `${selectedYear}-${endDate.split('-')[1]}-${endDate.split('-')[2]}`
      setValue('startDate', newStartDate)
      setValue('endDate', newEndDate)
    }
  }, [selectedYear, setValue, startDate, endDate])

  const onSubmit = async (data: YearInformationSchemaType) => {
    const {
      notification_days,
      notification_enable,
      notification_message,
      auto_start,
      id,
      name,
      close_at
    } = competitionYear

    try {
      await dispatch(
        updateCompetition({
          id,
          name,
          startDate: data.startDate,
          endDate: data.endDate,
          status: data.status as 'not_started' | 'started' | 'ended' | 'terminated' | 'archived',
          notification_days,
          notification_enable,
          notification_message,
          auto_start,
          close_at
        })
      ).unwrap()
      dispatch(fetchCompetitionYearDetails({ id: idCompetition as string }))
      toast.success(t('Competition year updated successfully'))
    } catch (error: any) {
      toast.error(error.message ?? t('Failed to update competition year'))
    }
  }

  const parseStringToDateValue = (dateString: string): DateValue | null => {
    const date = new Date(dateString)
    return !isNaN(date.getTime()) ? parseDate(date.toISOString().split('T')[0]) : null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 w-full">
      <div className="flex flex-row w-full gap-8">
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              size="lg"
              items={statusOptions}
              placeholder={t('Status')}
              className="w-1/4"
              selectedKeys={new Set([field.value])}
              onSelectionChange={(keys) => field.onChange(Array.from(keys).join(''))}
            >
              {(status) => <SelectItem key={status.key}>{status.label}</SelectItem>}
            </Select>
          )}
        />
        {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
        <Controller
          name="year"
          control={control}
          render={({ field }) => (
            <Select
              size="lg"
              items={yearOptions}
              placeholder={t('Select Year')}
              className="w-1/4"
              selectedKeys={new Set([field.value])}
              onSelectionChange={(keys) => field.onChange(Array.from(keys).join(''))}
            >
              {(year) => <SelectItem key={year.key}>{year.label}</SelectItem>}
            </Select>
          )}
        />
        {errors.year && <p className="text-red-500 text-sm">{errors.year.message}</p>}
      </div>
      <div className="flex flex-row w-full gap-8">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              label={t('Start Date')}
              variant="bordered"
              className="w-1/4"
              minValue={parseDate(`${field.value.split('-')[0]}-01-01`)}
              maxValue={parseDate(`${field.value.split('-')[0]}-12-31`)}
              value={parseStringToDateValue(field.value)}
              onChange={(date) => field.onChange(date?.toString() ?? '')}
            />
          )}
        />
        {errors.startDate && <p className="text-red-500 text-sm">{errors.startDate.message}</p>}
        <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              label={t('End Date')}
              variant="bordered"
              className="w-1/4"
              minValue={parseDate(`${field.value.split('-')[0]}-01-01`)}
              maxValue={parseDate(`${field.value.split('-')[0]}-12-31`)}
              value={parseStringToDateValue(field.value)}
              onChange={(date) => field.onChange(date?.toString() ?? '')}
            />
          )}
        />
        {errors.endDate && <p className="text-red-500 text-sm">{errors.endDate.message}</p>}
      </div>
      <div className="flex flex-row justify-end w-full">
        <ButtonVariant
          type="submit"
          color="primary"
          size="lg"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t('Save Changes')}
        </ButtonVariant>
      </div>
    </form>
  )
}

export default YearInformation
