import { z } from 'zod'
import { msg } from '@lingui/macro'

const validationSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: msg`يرجى ملء جميع الحقول المطلوبة (*) للمتابعة`.id })
    .email({ message: msg`الرجاء إدخال عنوان بريد إلكتروني صحيح. مثال: mailto:example@email.com`.id }),
  password: z.string().min(1, msg`كلمة المرور مطلوبة`.id)
})

export default validationSchema
