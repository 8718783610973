import React from 'react'
import { Pagination, PaginationItemType } from '@nextui-org/react'
import ChevronIcon from 'assets/icons/ChevronIcon'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface CustomPaginationProps {
  total: number
  currentPage: number
  onPageChange: (page: number) => void
}

const cn = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  total,
  currentPage,
  onPageChange
}) => {
  const direction = useSelector((state: RootState) => state.layout.direction)

  const renderItem = ({
    ref,
    key,
    value,
    isActive,
    onNext,
    onPrevious,
    setPage,
    className
  }: any) => {
    if (value === PaginationItemType.NEXT) {
      return (
        <button
          key={key}
          className={cn(
            className,
            `min-w-8 w-8 h-8 ${direction === 'rtl' ? '-rotate-90' : 'rotate-90'}`
          )}
          onClick={onNext}
        >
          <ChevronIcon fill="rgba(63, 63, 70, 1)" width="24" height="24" />
        </button>
      )
    }

    if (value === PaginationItemType.PREV) {
      return (
        <button
          key={key}
          className={cn(
            className,
            `min-w-8 w-8 h-8 ${direction === 'rtl' ? 'rotate-90' : '-rotate-90'}`
          )}
          onClick={onPrevious}
        >
          <ChevronIcon fill="rgba(63, 63, 70, 1)" width="24" height="24" />
        </button>
      )
    }

    if (value === PaginationItemType.DOTS) {
      return (
        <button key={key} className={className}>
          ...
        </button>
      )
    }

    return (
      <button
        key={key}
        ref={ref}
        className={cn(
          className,
          isActive
            ? 'text-white bg-primary_stroke_normal font-bold'
            : 'bg-gray-200 hover:bg-primary_fill_hover',
          'transition-colors duration-200 ease-in-out cursor-pointer'
        )}
        onClick={() => setPage(value)}
      >
        {value}
      </button>
    )
  }

  return (
    <Pagination
      disableCursorAnimation
      showControls
      total={total}
      initialPage={1}
      page={currentPage}
      className="gap-2"
      radius="full"
      renderItem={renderItem}
      onChange={onPageChange}
      variant="light"
    />
  )
}

export default CustomPagination
