import { FC, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { UploadProps } from './types'
import Typography from 'components/typography/typography'
import UploadIcon from 'assets/icons/uploadIcon'
import ButtonVariant from 'components/button-variant/button'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Button } from '@nextui-org/react'
import { useLingui } from '@lingui/react'
import { msg } from '@lingui/macro'
import FileSecondIcon from 'assets/icons/FileSecond'
import TrashIcon from 'assets/icons/TrashIcon'

const UploadBox: FC<UploadProps> = ({
  multiple = false,
  label,
  placeholder,
  disabled,
  error,
  file,
  files = [],
  onDelete,
  onUpload,
  onRemove,
  onRemoveAll,
  className,
  ...other
}) => {
  const { _ } = useLingui()
  const direction = useSelector((state: RootState) => state.layout.direction)

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other
  })

  const createPreviews = (files: (File | string)[]) => {
    return files.map((file) => {
      if (typeof file === 'string') {
        return file
      } else if (file.type === 'application/pdf') {
        return 'pdf-icon'
      } else {
        return URL.createObjectURL(file)
      }
    })
  }

  const filePreviews = useMemo(() => {
    if (file) {
      return createPreviews([file])
    } else if (files.length > 0) {
      return createPreviews(files)
    }
    return []
  }, [file, files])

  useEffect(() => {
    return () => {
      filePreviews.forEach((url) => {
        if (url !== 'pdf-icon') URL.revokeObjectURL(url)
      })
    }
  }, [filePreviews])

  const hasFile = !!file && !multiple
  const hasFiles = multiple && files.length > 0
  const hasError = isDragReject || !!error

  return (
    <div>
      {label && (
        <Typography
          size="sm"
          weight="bold"
          className={`mb-2 text-${direction === 'rtl' ? 'right' : 'left'}`}
        >
          {label}
        </Typography>
      )}
      {hasFile ? (
        <div className="flex items-center justify-between gap-4 px-8 py-2 rounded-full border-2 my-4 border-gray-200 flex-wrap  max-w-fit">
          <div className="flex items-center gap-4">
            <FileSecondIcon />
            <Typography size="sm" weight="bold">
              {typeof file === 'string' ? file : (file as File).name}
            </Typography>
          </div>
          <div onClick={() => onRemove?.(file)} className="cursor-pointer">
            <TrashIcon />
          </div>
        </div>
      ) : (
        <div
          className={`upload-file border-dashed border-2 p-4 rounded-lg ${hasError ? 'border-danger_default_lv1_text_normal_active_hover_pressed' : 'border-gray-300'} ${className}`}
          style={{ backgroundColor: '#FAFAFA', direction }}
        >
          <div
            className={`flex items-center justify-between p-4 relative ${hasError ? 'border-danger_default_lv1_text_normal_active_hover_pressed' : 'border-gray-300'} ${disabled ? 'opacity-50 pointer-events-none' : ''} ${isDragActive ? 'opacity-75' : 'opacity-100'}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} className="z-10 cursor-pointer rounded-lg w-full" />
            <div className={`flex items-center justify-between w-full relative`}>
              <div className="flex items-center gap-4">
                <div className="icon-circle bg-blue-100 rounded-full p-2">
                  <UploadIcon size="24" fill="rgba(42, 172, 173, 1)" />
                </div>
                <div className="flex flex-col">
                  <Typography
                    size="md"
                    weight="bold"
                    className={`mt-1 text-${direction === 'rtl' ? 'right' : 'left'}`}
                  >
                    {_(msg`تحميل ملفك`)}
                  </Typography>
                  <Typography
                    size="sm"
                    weight="normal"
                    className={`mt-1 text-${direction === 'rtl' ? 'right' : 'left'}`}
                  >
                    {placeholder ?? _(msg`حجم الملف الاقصى 10 ميغا`)}
                  </Typography>
                </div>
              </div>
              <div className={`btn-upload ${direction === 'rtl' ? 'mr-auto' : 'ml-auto'}`}>
                <Button radius="full" variant="bordered" color="default">
                  {_(msg`استعراض الملفات`)}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {hasFiles && (
        <div className="mt-4">
          {files.map((file, index) => (
            <div key={index} className="flex items-center p-4 w-full justify-between gap-4 mb-2">
              <div className="flex flex-row gap-4 items-center">
                {typeof file === 'string' ? (
                  <Typography size="sm" weight="bold">
                    {file}
                  </Typography>
                ) : (
                  <div className="flex flex-col justify-between">
                    <Typography size="sm" weight="bold">
                      {(file as File).name}
                    </Typography>
                    <Typography size="sm">{((file as File).size / 1024).toFixed(2)} KB</Typography>
                  </div>
                )}
              </div>
              <ButtonVariant color="danger" size="sm" onClick={() => onRemove?.(files[index])}>
                {_(msg`حذف`)}
              </ButtonVariant>
            </div>
          ))}
          <div className="flex flex-row gap-4">
            <ButtonVariant variant="ghost" color="default" onClick={onRemoveAll}>
              {_(msg`حذف الكل`)}
            </ButtonVariant>
            <ButtonVariant
              startContent={<UploadIcon fill="#fff" />}
              color="primary"
              fontBold
              onClick={onUpload}
            >
              {_(msg`تحميل`)}
            </ButtonVariant>
          </div>
        </div>
      )}
      {fileRejections.length > 0 && (
        <div className="mt-2 text-danger_default_lv1_text_normal_active_hover_pressed">
          <p>Some files were rejected:</p>
          <ul>
            {fileRejections.map(({ file, errors }) => (
              <li key={file.name}>
                {file.name} - {errors.map((e) => e.message).join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )}
      {error && (
        <div className="mt-2 text-danger_default_lv1_text_normal_active_hover_pressed text-sm">
          <p>{error.message}</p>
        </div>
      )}
    </div>
  )
}

export default UploadBox
