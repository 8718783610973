import React from 'react'
import { Trans } from '@lingui/macro'
import ButtonVariant from 'components/button-variant/button'
import fourOfour from 'assets/icons/404.svg'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { useNavigate } from 'react-router-dom'

const Page404: React.FC = () => {
  const navigate = useNavigate()

  const handleClick = async () => {
    navigate('/app/home')
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-full gap-10">
      <img src={fourOfour} alt="404" className="rounded-lg" />
      <h1 className="font-heading-h3-ar text-natural_icon_normal text-center">
        <Trans>خطأ في تحميل البيانات ، يرجى تحديث الصفحة</Trans>
      </h1>

      <div>
        <ButtonVariant
          variant="secondary"
          endContent={<ArrowLeft/>}
          onClick={handleClick}
        >
          <p>
            <Trans>الذهاب للصفحة الرئيسية</Trans>
          </p>
        </ButtonVariant>
      </div>

    </div>
  )
}

export default Page404
