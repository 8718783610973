import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useScrollContext } from './context/scroll-context'
import AboutSection from './components/about/about-section'
import StagesSection from './components/stages/stages-section'
import PathsSection from './components/paths/paths-section'
import AwardsSection from './components/awards/awards-section'
import WinnersSection from './components/winners/winners-section'
import SpeakersSection from './components/speakers/speakers-section'
import FaqSection from './components/faq/faq-section'
import Hero from './components/hero/hero-section'
import useDocumentTitle from 'hooks/useDocumentTitle'

const Home: React.FC = () => {
  useDocumentTitle()
  const location = useLocation()
  const scrollToSection = useScrollContext()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const section = params.get('section')
    if (section) {
      scrollToSection(section)
    } else {
      window.scrollTo(0, 0)
    }
  }, [location, scrollToSection])

  return (
    <div>
      <section id="hero">
        <Hero />
      </section>
      <div
        className=""
        style={{
          background:
            'radial-gradient(55.13% 22.7% at 75.63% 22.72%, rgba(43, 173, 173, 0.1) 0%, rgba(43, 173, 173, 0.03) 100%), radial-gradient(44.53% 18.34% at 13.43% 20.37%, rgba(249, 250, 251, 0.2) , rgba(249, 250, 251, 0) )'
        }}
      >
        <section id="about" className="">
          <AboutSection />
        </section>
        <section id="paths" className="">
          <PathsSection />
        </section>
        <section id="stages" className="">
          <StagesSection />
        </section>
        <section id="awards" className="">
          <AwardsSection />
        </section>
        <section id="winners" className="">
          <WinnersSection />
        </section>
        <section id="speakers" className="">
          <SpeakersSection />
        </section>
      </div>
      <section id="faq" className="">
        <FaqSection />
      </section>
    </div>
  )
}

export default Home
