import { Term } from "pages/AccountPages/types";
import { msg } from '@lingui/macro'

  const termsAndConditions: Term[] = [
    {
      id: 1,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 2,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 3,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 4,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 5,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 6,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 7,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 8,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 9,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 10,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 1,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 2,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 3,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 4,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 5,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 6,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    },
    {
      id: 7,
      content: msg`هذا النص قابل للتعديل وتم وضعه لغرض التصميم`
    }
  ];
  
  export default termsAndConditions;
  