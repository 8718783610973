import React from 'react'
import { useSelector } from 'react-redux'
import { NextUIProvider } from '@nextui-org/react'
import { RootState } from 'store'
import { useTranslation } from 'react-i18next'
import Routes from 'routes/routes'

function App() {
  return (
    <NextUIProvider>
      <Routes />
    </NextUIProvider>
  )
}

export default App
