import { Review } from 'pages/landing/models'
import React from 'react'
import { Trans } from '@lingui/react'

interface ReviewsCardProps {
  review: Review
}

const ReviewsCard: React.FC<ReviewsCardProps> = ({ review }) => {
  return (
    <div className="group lg:w-1/2 p-3 md:p-6 flex flex-row items-start md:items-center rounded-xl hover:bg-customTeal10">
      <img
        src={review.image}
        alt={review.title.id}
        className="w-1/3 object-cover rounded-xl transform transition-transform duration-300 group-hover:scale-105"
      />
      <div className="md:p-6 flex flex-col justify-between w-1/2 md:w-full mx-4 md:mx-0">
        <h2 className="text-lg md:text-4xl font-bold text-customTeal">
          <Trans id={review.title.id} />
        </h2>
        <div className="flex *:mx-0.5 items-center py-2">
          <img src={review.avatar} alt={review.name.id} className="w-6 md:w-8" />
          <p className="text-naturalLv2 font-medium text-base md:text-2xl">
            <Trans id={review.name.id} />
          </p>
        </div>
        <p className="font-medium text-sm md:text-xl text-naturalLv2 md:mt-2 line-clamp-1 md:line-clamp-none">
          <Trans id={review.description.id} />
        </p>
      </div>
    </div>
  )
}

export default ReviewsCard
