import React from 'react'
import { Event } from '../../models'
import { Trans } from '@lingui/react'

interface MarathonCardProps {
  event: Event
}

const MarathonCard: React.FC<MarathonCardProps> = ({ event }) => {
  return (
    <div className={event.id === 1 ? 'md:col-span-2' : ''}>
      <div className="p-3 md:p-6 flex flex-row items-center rounded-xl hover:bg-customTeal10 group">
        <img
          src={event.image}
          alt={event.title.id}
          className="w-2/5 h-full object-cover rounded-xl transform transition-transform duration-300 group-hover:scale-105"
        />
        <div className="lg:p-4 md:p-6 flex flex-col justify-between mx-4 md:mx-0">
          <h2 className="text-lg md:text-4xl font-bold text-customTeal line-clamp-1 md:line-clamp-none">
            <Trans id={event.title.id} />
          </h2>
          <p className="text-naturalLv2 font-medium text-base md:text-2xl md:mb-6">
            <Trans id={event.date.id} />
          </p>
          <p className="font-helveticaNeueArabic font-bold text-sm md:text-xl text-naturalLv2 md:mt-2 line-clamp-1 md:line-clamp-none">
            <Trans id={event.description.id} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default MarathonCard
