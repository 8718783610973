import axios from 'axios'
import { toast } from 'react-toastify'

const baseURL = process.env.REACT_APP_API_ENDPOINT

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: any) {
    // Handle server errors
    if (error?.response?.data?.status === 'fail') {
      const message = error?.response?.data?.message ?? ''
      toast.error(message)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
