import React, { useEffect, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type Props = {
  children: ReactNode
}

const PublicGuard: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const { token, verifiedAt } = useSelector((state: RootState) => state.auth) // Access token and verifiedAt from state
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const localToken = localStorage.getItem('token')
    const localVerifiedAt = localStorage.getItem('verifiedAt')
    // Check if token exists in state or local storage, and verifiedAt exists either in state or local storage
    if ((localToken || token) && (localVerifiedAt || verifiedAt)) {
      navigate('/app/home') // Redirect to the home/dashboard if logged in and verified
    } else {
      setChecked(true) // Allow access if not logged in or verified
    }
  }, [navigate, token, verifiedAt])

  if (!checked) {
    return null // Render nothing until user's status is confirmed
  }

  return <>{children}</>
}

export default PublicGuard
