import useDocumentTitle from 'hooks/useDocumentTitle'
import BlogNavbar from './components/navbars/blog-navbar'
import DebatesSection from './components/debates/debates-section'
import DialoguesSection from './components/dialogues/dialogues-section'
import BlogHero from './components/hero/blog-hero'
import MarathonSection from './components/marathon/marathon-section'
import OffersSection from './components/offers/offers-section'
import PodcastSection from './components/podcast/podcast-section'
import ReviewsSection from './components/reviews/reviews-section'
import { msg } from '@lingui/macro'

const Blog: React.FC = () => {
  useDocumentTitle(msg`مدونة أقرأ`)

  return (
    <div>
      <section id="">
        <BlogHero />
      </section>
      <section id="" className="">
        <BlogNavbar />
      </section>
      <div
        className=""
        style={{
          background:
            'radial-gradient(55.13% 22.7% at 75.63% 22.72%, rgba(43, 173, 173, 0.1) 0%, rgba(43, 173, 173, 0.03) 100%), radial-gradient(44.53% 18.34% at 13.43% 20.37%, rgba(249, 250, 251, 0.2) , rgba(249, 250, 251, 0) )'
        }}
      >
        <section id="podcast" className="">
          <PodcastSection />
        </section>
        <section id="dialogues" className="">
          <DialoguesSection />
        </section>
        <section id="debates" className="">
          <DebatesSection />
        </section>
        <section id="marathon" className="">
          <MarathonSection />
        </section>
        <section id="offers" className="">
          <OffersSection />
        </section>
        <section id="reviews" className="">
          <ReviewsSection />
        </section>
      </div>
    </div>
  )
}

export default Blog
