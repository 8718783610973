import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRequestStatusFilterOptions,
  getRequestStatusFilterLabel,
  getRequestStatusFilterValue
} from 'constants/requestStatusFilterOptions'

import { setStatusFilter } from 'store/slices/activateRequestsSlice'
import useLanguage from 'hooks/useLanguage'
import { RootState } from 'store'

const StatusFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const language = useLanguage()
  const { status } = useSelector((state: RootState) => state.activateRequests)

  const handleSetStatus = (status: any) => {
    dispatch(setStatusFilter(status))
  }

  const handelStatus = (selectedOption: any) => {
    handleSetStatus(selectedOption ? selectedOption.value : '')
  }

  const options = getRequestStatusFilterOptions(language)

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('REQUEST_STATUS_FILTER')}</p>
      <Select
        options={options.map((option) => ({
          value: option.value,
          label: getRequestStatusFilterLabel(option.value, language)
        }))}
        onChange={handelStatus}
        placeholder={t('REQUEST_STATUS_FILTER')}
        isClearable
        value={
          status
            ? {
                value: getRequestStatusFilterValue(status),
                label: getRequestStatusFilterLabel(status, language)
              }
            : undefined
        }
      />
    </div>
  )
}

export default StatusFilter
