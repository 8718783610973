import { useTranslation } from 'react-i18next'
import './ReviewRequestForm.css'
import { Button, Checkbox, Input, Radio, RadioGroup, Textarea } from '@nextui-org/react'
import Select, { components } from 'react-select'
import ArrowDawnIcon from 'assets/icons/ArrowDawnIcon'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchRegions } from 'store/slices/regionsSlice'
import { fetchEducationAdministrations } from 'store/slices/educationAdministrationsSlice'
import { fetchSchools } from 'store/slices/schoolsSlice'
import { fetchUniversities } from 'store/slices/universitiesSlice'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { ReviewRequestSchemaType } from './ReviewRequestSchemaType'
import useLanguage from 'hooks/useLanguage'
import {
  getEducationStageLabel,
  getEducationStageOptions,
  getEducationStageValue
} from 'constants/educationStageOptions'
import {
  getGenderOfEducationLabel,
  getGenderOfEducationOptions,
  getGenderOfEducationValue
} from 'constants/genderOfEducationOptions'
import { getNameByLanguage } from 'utils/getNameByLanguage'
import { activateRequest } from 'store/slices/activateRequestSlice'

// custom dropdown Indicator
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDawnIcon />
    </components.DropdownIndicator>
  )
}

// custom styles
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '44px',
    height: '40px',
    // borderRadius: '8px',
    borderRadius: '10px',
    borderWidth: '2px',
    fontFamily: 'ithraV3',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    width: '100%',
    borderColor: state.isFocused ? '#ccc' : '#ddd',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? '#ccc' : '#ddd'
    }
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    padding: '0 6px'
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px'
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none' // remove the default divider
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#2AACAD' : 'white',
    ':hover': {
      backgroundColor: '#2AACAD'
    }
  })
}

const customStylesMulti = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '44px',
    height: 'auto',
    borderRadius: '10px',
    borderWidth: '2px',
    fontFamily: 'ithraV3',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    width: '100%',
    borderColor: state.isFocused ? '#ccc' : '#ddd',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? '#ccc' : '#ddd'
    }
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    height: 'auto',
    minHeight: '40px',
    padding: '0 6px'
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    minHeight: '30px'
  }),
  indicatorSeparator: () => ({
    display: 'none' // remove the default divider
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 'auto'
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    borderRadius: '10px',
    backgroundColor: '#e1f7f7'
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 400
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d3e0e0'
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#2AACAD' : 'white',
    ':hover': {
      backgroundColor: '#2AACAD'
    }
  })
}

interface ReviewRequestFormProps {
  reviewRequestValues?: ReviewRequestSchemaType
  countryCode?: string
  regionLabel: { ar: string; en: string } | undefined
}

const ReviewRequestForm: React.FC<ReviewRequestFormProps> = ({
  reviewRequestValues,
  countryCode,
  regionLabel
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()
  const language = useLanguage()
  const [label] = useState<{ ar: string; en: string }>(
    regionLabel ? regionLabel : { ar: 'المنطقة', en: 'Region' }
  )
  const { isLoading } = useSelector((state: RootState) => state.activateRequest)
  const { regions, isLoading: regionsLoading } = useSelector((state: RootState) => state.regions)

  const [regionsIds, setRegionsIds] = useState<string[]>(
    reviewRequestValues?.region?.length ? reviewRequestValues?.region.map((r) => r.value) : []
  )

  const { educationAdministrations, isLoading: educationAdministrationsLoading } = useSelector(
    (state: RootState) => state.educationAdministrations
  )

  const { schools, isLoading: schoolsLoading } = useSelector((state: RootState) => state.schools)
  const { universities, isLoading: universitiesLoading } = useSelector(
    (state: RootState) => state.universities
  )

  useEffect(() => {
    dispatch(fetchRegions({}))
  }, [])

  type ReviewRequestSchemaType = z.infer<typeof ReviewRequestSchema>

  const ReviewRequestSchema = z.object({
    region: z
      .array(
        z.object({
          label: z.string(),
          value: z.string()
        })
      )
      .min(1, { message: t('PLEASE_PROVIDE_AT_LEAST_ONE_REGION') }),
    educationStage: z
      .union([
        z.object({
          label: z.string(),
          value: z.string()
        }),
        z.undefined()
      ])
      .refine((value) => value !== undefined, {
        message: t('This field is required')
      }),
    educationalAdministration: z.array(
      z.object({
        label: z.string(),
        value: z.string()
      })
    ),
    genderOfEducation: z
      .union([
        z.object({
          label: z.string(),
          value: z.string()
        }),
        z.undefined()
      ])
      .refine((value) => value !== undefined, {
        message: t('This field is required')
      }),
    school: z.array(
      z.object({
        label: z.string(),
        value: z.string()
      })
    ),
    university: z.array(
      z.object({
        label: z.string(),
        value: z.string()
      })
    ),
    schoolOrUniversityName: z.string().trim().optional(),
    supportingDocument: z.string().trim().optional(),
    comment: z.string().trim().optional(),
    reviewResult: z
      .object({
        status: z.enum(['approval', 'rejected', 'none'], {
          required_error: t('REVIEW_RESULT_IS_REQUIRED')
        }),
        reason: z.string().optional()
      })
      .superRefine((data, ctx) => {
        if (data.status === 'rejected' && !data.reason) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['reason'],
            message: t('REASON_FOR_REJECTION_IS_REQUIRED_WHEN_REVIEW_RESULT_IS_REJECTED')
          })
        }
      }),
    schoolOrUniversityNameObject: z
      .object({
        schoolOrUniversityNameChecked: z
          .boolean({
            required_error: t('SCHOOL_OR_UNIVERSITY_NAME_CHECKED_IS_REQUIRED')
          })
          .default(false),
        schoolOrUniversityNameAr: z.string().optional(),
        schoolOrUniversityNameEn: z.string().optional()
      })
      .superRefine((data, ctx) => {
        const arabicRegex = /^[\u0600-\u06FF\s]+$/
        const englishRegex = /^[A-Za-z\s]+$/

        if (data.schoolOrUniversityNameChecked) {
          if (!data.schoolOrUniversityNameAr) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['schoolOrUniversityNameAr'],
              message: t('SCHOOL_OR_UNIVERSITY_NAME_ARABIC_IS_REQUIRED_WHEN_CHECKED')
            })
          } else if (!arabicRegex.test(data.schoolOrUniversityNameAr)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['schoolOrUniversityNameAr'],
              message: t(
                'SCHOOL_OR_UNIVERSITY_NAME_ARABIC_MUST_CONTAIN_ONLY_ARABIC_CHARACTERS_AND_SPACES'
              )
            })
          }

          if (!data.schoolOrUniversityNameEn) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['schoolOrUniversityNameEn'],
              message: t('SCHOOL_OR_UNIVERSITY_NAME_ENGLISH_IS_REQUIRED_WHEN_CHECKED')
            })
          } else if (!englishRegex.test(data.schoolOrUniversityNameEn)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['schoolOrUniversityNameEn'],
              message: t(
                'SCHOOL_OR_UNIVERSITY_NAME_ENGLISH_MUST_CONTAIN_ONLY_ENGLISH_CHARACTERS_AND_SPACES'
              )
            })
          }
        }
      }),
    numberOfStudents: z
      .number({ message: t('MUST_BE_A_POSITIVE_INTEGER_GREATER_THAN_0') })
      .int({ message: t('MUST_BE_A_POSITIVE_INTEGER_GREATER_THAN_0') })
      .positive({
        message: t('MUST_BE_A_POSITIVE_INTEGER_GREATER_THAN_0')
      })
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors, defaultValues }
  } = useForm<ReviewRequestSchemaType>({
    mode: 'onBlur',
    resolver: zodResolver(ReviewRequestSchema),
    defaultValues:
      reviewRequestValues !== undefined
        ? {
            ...reviewRequestValues
          }
        : {
            region: [],
            educationStage: undefined,
            educationalAdministration: undefined,
            genderOfEducation: undefined,
            school: [],
            university: [],
            comment: '',
            schoolOrUniversityName: undefined,
            schoolOrUniversityNameObject: {
              schoolOrUniversityNameChecked: false
            },
            numberOfStudents: 0
          }
  })

  const isCoordinator = true
  const isLocatedInSA = countryCode === 'SA'

  const onSubmit = async (data: ReviewRequestSchemaType) => {
    console.log('data', data)

    if (
      (data.educationStage?.value === 'general' || data.educationStage?.value === 'university') &&
      defaultValues?.schoolOrUniversityName
    ) {
      if (
        !data.schoolOrUniversityName ||
        !/^[\u0600-\u06FF\sA-Za-z]+$/.test(data.schoolOrUniversityName.trim())
      ) {
        setError('schoolOrUniversityName', {
          type: 'manual',
          message: t('This field should only contain letters and spaces')
        })
        return
      }
    }

    clearErrors('schoolOrUniversityName')

    if (
      data?.educationStage?.value === 'general' &&
      isCoordinator &&
      isLocatedInSA &&
      (data.educationalAdministration || []).length === 0
    ) {
      setError('educationalAdministration', {
        type: 'manual',
        message: t('This field is required')
      })
      return
    }

    clearErrors('educationalAdministration')

    if (data?.educationStage?.value === 'general' && (data.school || []).length === 0) {
      setError('school', {
        type: 'manual',
        message: t('PLEASE_SELECT_AT_LEAST_ONE_SCHOOL')
      })
      return
    }
    clearErrors('school')

    if (data?.reviewResult?.status === 'none') {
      setError('reviewResult.status', {
        type: 'manual',
        message: t('REVIEW_RESULT_IS_REQUIRED')
      })
      return
    }
    clearErrors('reviewResult.status')

    const formData = new FormData()

    formData.append('_method', 'put')

    if (data?.reviewResult?.status === 'approval') {
      formData.append('result', 'approve')
    }
    if (data?.reviewResult?.status === 'rejected') {
      formData.append('result', 'reject')
      formData.append('rejection_reason', data?.reviewResult?.reason ?? '')
    }

    if (data?.region?.length > 0) {
      data?.region?.forEach((region) => {
        formData.append('access[regions][]', region.value.toString())
      })
    }

    formData.append('education_stage', educationStage?.value ?? '')

    if (educationStage?.value === 'general' && isCoordinator && isLocatedInSA) {
      data?.educationalAdministration?.forEach((educationalA) => {
        formData.append('access[education_administrations][]', educationalA.value.toString())
      })
    }

    if (educationStage?.value === 'general') {
      if (data?.schoolOrUniversityName) {
        formData.append('school_university_name', data?.schoolOrUniversityName ?? '')
      }
    }

    if (educationStage?.value === 'general') {
      if (data?.school?.length > 0) {
        data?.school?.forEach((s) => {
          formData.append('access[schools][]', s.value.toString())
        })
      }
    }

    if (educationStage?.value === 'general') {
      if (data?.schoolOrUniversityNameObject.schoolOrUniversityNameChecked) {
        formData.append('school[id]', '')
        formData.append(
          'school[name][ar]',
          data?.schoolOrUniversityNameObject?.schoolOrUniversityNameAr ?? ''
        )
        formData.append(
          'school[name][en]',
          data?.schoolOrUniversityNameObject?.schoolOrUniversityNameEn ?? ''
        )
      }
    }

    if (educationStage?.value === 'university') {
      if (data?.schoolOrUniversityName) {
        formData.append('school_university_name', data?.schoolOrUniversityName ?? '')
      }
    }

    if (educationStage?.value === 'university') {
      if (data?.university?.length > 0) {
        data?.university?.forEach((s) => {
          formData.append('access[universities][]', s.value.toString())
        })
      }
    }

    if (educationStage?.value === 'university') {
      if (data?.schoolOrUniversityNameObject.schoolOrUniversityNameChecked) {
        formData.append('university[id]', '')
        formData.append(
          'university[name][ar]',
          data?.schoolOrUniversityNameObject?.schoolOrUniversityNameAr ?? ''
        )
        formData.append(
          'university[name][en]',
          data?.schoolOrUniversityNameObject?.schoolOrUniversityNameEn ?? ''
        )
      }
    }

    formData.append('gender_of_education', data?.genderOfEducation?.value ?? '')
    // formData.append('comment', data?.comment ?? '')
    formData.append('number_of_students', data?.numberOfStudents?.toString() ?? '')

    if (id) {
      dispatch(activateRequest({ id: id, formData: formData }))
        .unwrap()
        .then((result: any) => {
          if (result.status === 'success') {
            navigate(-1)
            // const message = result.message ?? ''
            const message = t(
              'YOUR_UPDATE_DECISION_IS_UPDATED_SUCCESSFULLY_AND_COORDINATOR_HAS_BEEN_NOTIFIED_ABOUT_IT'
            )
            toast.success(message)
          }
        })
        .catch((error: any) => {
          if (error.status === 'error') {
            const message = error.message ?? ''
            toast.error(message)
          }
        })
    }
  }

  const educationStage = watch('educationStage', undefined)
  const school = watch('school', undefined)
  const university = watch('university', undefined)
  const genderOfEducation = watch('genderOfEducation', undefined)
  const region = watch('region', [])
  const educationalAdministration = watch('educationalAdministration', [])
  const reviewResultStatus = watch('reviewResult.status', undefined)
  const schoolOrUniversityNameChecked = watch(
    'schoolOrUniversityNameObject.schoolOrUniversityNameChecked',
    undefined
  )

  const schoolOrUniversityName = watch('schoolOrUniversityName', undefined)

  function handleRegionChange(newValue: any) {
    setValue('region', newValue, { shouldValidate: true })
    if (newValue) {
      setRegionsIds(newValue ? newValue.map((option: any) => option.value) : [])
    }
  }

  function handleEducationLevelChange(newValue: any) {
    setValue('educationStage', newValue ?? undefined, { shouldValidate: true })
  }

  function handleEducationalAdministrationChange(newValue: any) {
    setValue('educationalAdministration', newValue, { shouldValidate: true })
  }

  function handleGenderOfEducationChange(newValue: any) {
    setValue('genderOfEducation', newValue ?? undefined, { shouldValidate: true })
  }

  function handleSchoolChange(newValue: any) {
    setValue('school', newValue, { shouldValidate: true })
  }

  function handleUniversityChange(newValue: any) {
    setValue('university', newValue, { shouldValidate: true })
  }

  const regionOptions = regions.map((region: any) => {
    return { value: region.id.toString(), label: getNameByLanguage(region.name, language) }
  })

  const educationAdministrationOptions = educationAdministrations.map(
    (educationAdministration: any) => {
      return {
        value: educationAdministration.id.toString(),
        label: getNameByLanguage(educationAdministration.name, language)
      }
    }
  )

  const schoolOptions = schools.map((school: any) => {
    return { value: school.id.toString(), label: getNameByLanguage(school.name, language) }
  })

  const universityOptions = universities.map((university: any) => {
    return {
      value: university.id.toString(),
      label: getNameByLanguage(university.name, language)
    }
  })

  const isEducationAdministrationError = useMemo(() => {
    return (
      educationStage?.value === 'general' &&
      isCoordinator &&
      isLocatedInSA &&
      (educationalAdministration || []).length === 0
    )
  }, [educationStage, isCoordinator, isLocatedInSA, educationalAdministration])

  useEffect(() => {
    try {
      if (isEducationAdministrationError) {
        setError('educationalAdministration', {
          type: 'manual',
          message: t('This field is required')
        })
      } else {
        clearErrors('educationalAdministration')
      }
    } catch (error) {
      console.error(error)
    }
  }, [isEducationAdministrationError, t, clearErrors, setError])

  const isSchoolError = useMemo(() => {
    return educationStage?.value === 'general' && (school || []).length === 0
  }, [educationStage, school])

  useEffect(() => {
    try {
      if (isSchoolError) {
        setError('school', {
          type: 'manual',
          message: t('PLEASE_SELECT_AT_LEAST_ONE_SCHOOL')
        })
      } else {
        clearErrors('school')
      }
    } catch (error) {
      console.error(error)
    }
  }, [isSchoolError, t, clearErrors, setError])

  const isUniversityError = useMemo(() => {
    return educationStage?.value === 'university' && (university || []).length === 0
  }, [educationStage, university])

  useEffect(() => {
    try {
      if (isUniversityError) {
        setError('university', {
          type: 'manual',
          message: t('PLEASE_SELECT_AT_LEAST_ONE_UNIVERSITY')
        })
      } else {
        clearErrors('university')
      }
    } catch (error) {
      console.error(error)
    }
  }, [isUniversityError, t, clearErrors, setError])

  const isSchoolOrUniversityNameWithGeneralError = useMemo(() => {
    return (
      educationStage?.value === 'general' &&
      defaultValues?.schoolOrUniversityName &&
      !schoolOrUniversityName
    )
  }, [educationStage, defaultValues?.schoolOrUniversityName, schoolOrUniversityName])

  useEffect(() => {
    try {
      if (isSchoolOrUniversityNameWithGeneralError) {
        setError('schoolOrUniversityName', {
          type: 'manual',
          message: t('This field is required')
        })
      } else {
        clearErrors('schoolOrUniversityName')
      }
    } catch (error) {
      console.error(error)
    }
  }, [isSchoolOrUniversityNameWithGeneralError, t, clearErrors, setError])

  const isSchoolOrUniversityNameWithUniversityError = useMemo(() => {
    return (
      educationStage?.value === 'university' &&
      defaultValues?.schoolOrUniversityName &&
      !schoolOrUniversityName
    )
  }, [educationStage, defaultValues?.schoolOrUniversityName, schoolOrUniversityName])

  useEffect(() => {
    try {
      if (isSchoolOrUniversityNameWithUniversityError) {
        setError('schoolOrUniversityName', {
          type: 'manual',
          message: t('This field is required')
        })
      } else {
        clearErrors('schoolOrUniversityName')
      }
    } catch (error) {
      console.error(error)
    }
  }, [isSchoolOrUniversityNameWithUniversityError, t, clearErrors, setError])

  const educationStageOptions = getEducationStageOptions(language)
  const genderOfEducationOptions = getGenderOfEducationOptions(language)

  const regionValue = region
  const schoolValue = school
  const universityValue = university
  const educationalAdministrationValue = educationalAdministration

  const shouldFetchEducationAdministrations = useMemo(() => {
    return (
      regionValue.length === regionsIds.length &&
      educationStage?.value === 'general' &&
      isCoordinator &&
      isLocatedInSA
    )
  }, [educationStage?.value, isCoordinator, isLocatedInSA, regionValue.length, regionsIds.length])

  useEffect(() => {
    if (shouldFetchEducationAdministrations) {
      dispatch(
        fetchEducationAdministrations({
          regionsIds: regionsIds,
          countryCode: isLocatedInSA ? 'SA' : ''
        })
      )
    }
  }, [shouldFetchEducationAdministrations, regionsIds, dispatch, setValue, isLocatedInSA])

  const shouldFetchSchools = useMemo(() => {
    return regionValue.length === regionsIds.length && educationStage?.value === 'general'
  }, [educationStage?.value, regionValue.length, regionsIds.length])

  useEffect(() => {
    if (shouldFetchSchools) {
      dispatch(fetchSchools({ regionsIds: regionsIds }))
    }
  }, [shouldFetchSchools, regionsIds, dispatch, setValue])

  const shouldFetchUniversities = useMemo(() => {
    return regionValue.length === regionsIds.length && educationStage?.value === 'university'
  }, [educationStage?.value, regionValue.length, regionsIds.length])

  useEffect(() => {
    if (shouldFetchUniversities) {
      dispatch(fetchUniversities({ regionsIds: regionsIds }))
    }
  }, [shouldFetchUniversities, regionsIds, dispatch, setValue])

  const isDisabled =
    reviewRequestValues?.reviewResult?.status === 'approval' ||
    reviewRequestValues?.reviewResult?.status === 'rejected'

  return (
    <div className="review-request-form flex flex-col justify-start items-start w-full">
      {isDisabled && (
        <div className=" flex flex-row justify-center items-center flex-wrap w-full shadow-lg p-2 rounded-lg mb-4 mt-4">
          <p className=" text-[#18181B] font-ithraV3 text-16 font-400 leading-20">
            {t('THE_REQUEST_HAS_ALREADY_BEEN_REVIEWED_BEFORE')}
          </p>
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`flex flex-col justify-start items-start w-full ${isDisabled ? 'pointer-events-none opacity-50' : ''}`}
      >
        <div className="flex flex-col justify-start items-start w-full gap-[8px]">
          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {label[language]}
            </p>
            <Select
              options={regionOptions}
              styles={customStylesMulti}
              components={{ DropdownIndicator }}
              placeholder={label[language]}
              className="w-full"
              {...register('region')}
              onChange={(newValue: any) => {
                handleRegionChange(newValue)
              }}
              isClearable
              defaultValue={
                (defaultValues?.region || []).filter((r) => r && r.value && r.label) as {
                  value: string
                  label: string
                }[]
              }
              isLoading={regionsLoading}
              value={regionValue}
              isMulti
            />
            {errors?.region?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.region.message.toString()}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('Education Stage')}
            </p>
            <Select
              options={educationStageOptions}
              styles={customStyles}
              components={{ DropdownIndicator }}
              placeholder={t('Education Stage')}
              className="w-full"
              {...register('educationStage')}
              onChange={(newValue: any) => {
                handleEducationLevelChange(newValue)
              }}
              isClearable
              defaultValue={defaultValues?.educationStage}
              value={
                educationStage
                  ? {
                      value: getEducationStageValue(educationStage?.value),
                      label: getEducationStageLabel(educationStage?.value, language)
                    }
                  : undefined
              }
            />
            {errors?.educationStage?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.educationStage.message.toString()}
              </p>
            )}
          </div>
          {educationStage?.value === 'general' && isCoordinator && isLocatedInSA && (
            <div className="flex flex-col justify-start items-start w-full gap-[4px]">
              <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                {t('Educational Administration')}
              </p>
              <Select
                options={educationAdministrationOptions}
                styles={customStylesMulti}
                components={{ DropdownIndicator }}
                placeholder={t('Educational Administration')}
                className="w-full"
                {...register('educationalAdministration')}
                onChange={(newValue: any) => {
                  handleEducationalAdministrationChange(newValue)
                }}
                isClearable
                isLoading={educationAdministrationsLoading}
                defaultValue={
                  (defaultValues?.educationalAdministration || []).filter(
                    (e) => e && e.value && e.label
                  ) as { value: string; label: string }[]
                }
                value={educationalAdministrationValue}
                isMulti
              />
              {errors?.educationalAdministration?.message && (
                <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                  {errors.educationalAdministration.message.toString()}
                </p>
              )}
            </div>
          )}

          {educationStage?.value === 'general' && (
            <div className="flex flex-col justify-start items-start w-full gap-[4px]">
              <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                {t('School')}
              </p>
              <Select
                options={schoolOptions}
                styles={customStylesMulti}
                components={{ DropdownIndicator }}
                placeholder={t('School')}
                className="w-full"
                {...register('school')}
                onChange={(newValue: any) => {
                  handleSchoolChange(newValue)
                }}
                isClearable
                defaultValue={
                  (defaultValues?.school || []).filter((s) => s && s.value && s.label) as {
                    value: string
                    label: string
                  }[]
                }
                isLoading={schoolsLoading}
                value={schoolValue}
                isMulti
              />
              {errors?.school?.message && (
                <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                  {errors.school.message.toString()}
                </p>
              )}
            </div>
          )}

          {educationStage?.value === 'university' && (
            <div className="flex flex-col justify-start items-start w-full gap-[4px]">
              <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                {t('University')}
              </p>
              <Select
                options={universityOptions}
                styles={customStylesMulti}
                components={{ DropdownIndicator }}
                placeholder={t('Universities')}
                className="w-full"
                {...register('university')}
                onChange={(newValue: any) => {
                  handleUniversityChange(newValue)
                }}
                isClearable
                defaultValue={
                  (defaultValues?.university || []).filter((u) => u && u.value && u.label) as {
                    value: string
                    label: string
                  }[]
                }
                isLoading={universitiesLoading}
                value={universityValue}
                isMulti
              />
              {errors?.university?.message && (
                <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                  {errors.university.message.toString()}
                </p>
              )}
            </div>
          )}

          {(educationStage?.value === 'general' || educationStage?.value === 'university') &&
            defaultValues?.schoolOrUniversityName && (
              <>
                <div className="flex flex-col justify-start items-start w-full gap-[4px]">
                  <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                    {t('SCHOOL_OR_UNIVERSITY_NAME')}
                  </p>
                  <Input
                    label=""
                    placeholder={t('SCHOOL_OR_UNIVERSITY_NAME')}
                    type="text"
                    variant="bordered"
                    radius="md"
                    size="lg"
                    {...register('schoolOrUniversityName')}
                  />
                  {errors?.schoolOrUniversityName?.message && (
                    <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                      {errors.schoolOrUniversityName.message.toString()}
                    </p>
                  )}
                </div>
                <div className="flex flex-col justify-start items-start w-full gap-[4px]">
                  <Controller
                    name={'schoolOrUniversityNameObject.schoolOrUniversityNameChecked'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        color="primary"
                        className=" z-0"
                        onChange={(e: any) => {
                          onChange(e.target.checked ? true : undefined)
                        }}
                        checked={value}
                        size="sm"
                      >
                        <div className="flex flex-row justify-start items-start flex-wrap w-full">
                          <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                            {educationStage?.value === 'general' && t('ADD_TO_SCHOOLS_DATABASE')}
                            {educationStage?.value === 'university' &&
                              t('ADD_TO_UNIVERSITIES_DATABASE')}
                          </p>
                        </div>
                      </Checkbox>
                    )}
                  />
                  {errors?.schoolOrUniversityNameObject?.schoolOrUniversityNameChecked?.message && (
                    <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                      {errors.schoolOrUniversityNameObject.schoolOrUniversityNameChecked.message.toString()}
                    </p>
                  )}
                </div>
                {schoolOrUniversityNameChecked && (
                  <>
                    <div className="flex flex-col justify-start items-start w-full gap-[4px]">
                      <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                        {t('SCHOOL_OR_UNIVERSITY_ENGLISH_NAME')}
                      </p>
                      <Input
                        label=""
                        placeholder={t('SCHOOL_OR_UNIVERSITY_ENGLISH_NAME')}
                        type="text"
                        variant="bordered"
                        radius="md"
                        size="lg"
                        {...register('schoolOrUniversityNameObject.schoolOrUniversityNameEn')}
                      />
                      {errors?.schoolOrUniversityNameObject?.schoolOrUniversityNameEn?.message && (
                        <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                          {errors.schoolOrUniversityNameObject.schoolOrUniversityNameEn.message.toString()}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col justify-start items-start w-full gap-[4px]">
                      <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                        {t('SCHOOL_OR_UNIVERSITY_ARABIC_NAME')}
                      </p>
                      <Input
                        label=""
                        placeholder={t('SCHOOL_OR_UNIVERSITY_ARABIC_NAME')}
                        type="text"
                        variant="bordered"
                        radius="md"
                        size="lg"
                        {...register('schoolOrUniversityNameObject.schoolOrUniversityNameAr')}
                      />
                      {errors?.schoolOrUniversityNameObject?.schoolOrUniversityNameAr?.message && (
                        <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                          {errors.schoolOrUniversityNameObject.schoolOrUniversityNameAr.message.toString()}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </>
            )}

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('Gender of Education')}
            </p>
            <Select
              options={genderOfEducationOptions}
              styles={customStyles}
              components={{ DropdownIndicator }}
              placeholder={t('Gender of Education')}
              className="w-full"
              {...register('genderOfEducation')}
              onChange={(newValue: any) => {
                handleGenderOfEducationChange(newValue)
              }}
              isClearable
              defaultValue={defaultValues?.genderOfEducation}
              value={
                genderOfEducation
                  ? {
                      value: getGenderOfEducationValue(genderOfEducation?.value),
                      label: getGenderOfEducationLabel(genderOfEducation?.value, language)
                    }
                  : undefined
              }
            />
            {errors?.genderOfEducation?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.genderOfEducation.message.toString()}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('NUMBER_OF_STUDENTS')}
            </p>
            <Input
              label=""
              placeholder={t('NUMBER_OF_STUDENTS')}
              type="number"
              variant="bordered"
              radius="md"
              size="lg"
              {...register('numberOfStudents', { valueAsNumber: true })}
            />
            {errors?.numberOfStudents?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.numberOfStudents.message.toString()}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('Supporting documents')}
            </p>
            {defaultValues?.supportingDocument ? (
              <a href={defaultValues?.supportingDocument} target="_blank" rel="noopener noreferrer">
                <span className="text-sm text-blue-500">{t('download')}</span>
              </a>
            ) : (
              '-'
            )}
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('COMMENT')}
            </p>
            <Textarea
              label=""
              placeholder={t('COMMENT')}
              type="text"
              variant="bordered"
              radius="md"
              size="lg"
              {...register('comment')}
              isReadOnly
              isDisabled={true}
            />
            {errors?.comment?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.comment.message.toString()}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-[4px]">
            <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
              {t('REVIEW_RESULT')}
            </p>
            <Controller
              name="reviewResult.status"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e: any) =>
                    setValue('reviewResult.status', e.target.value, { shouldValidate: true })
                  }
                >
                  <Radio value="approval">{t('APPROVAL')}</Radio>
                  <Radio value="rejected">{t('REJECTED')}</Radio>
                </RadioGroup>
              )}
            />
            {errors?.reviewResult?.status?.message && (
              <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                {errors.reviewResult.status.message.toString()}
              </p>
            )}
          </div>

          {reviewResultStatus === 'rejected' && (
            <div className="flex flex-col justify-start items-start w-full gap-[4px]">
              <p className=" text-[#18181B] font-ithraV3 text-14 font-400 leading-20">
                {t('REASON_FOR_REJECTION')}
              </p>
              <Textarea
                label=""
                placeholder={t('REASON_FOR_REJECTION')}
                type="text"
                variant="bordered"
                radius="md"
                size="lg"
                {...register('reviewResult.reason')}
              />
              {errors?.reviewResult?.reason?.message && (
                <p className="pt-0 text-red-500 font-ithraV3 font-300 text-16">
                  {errors.reviewResult.reason.message.toString()}
                </p>
              )}
            </div>
          )}
        </div>

        <div className=" mt-[16px]" />

        <div className="flex flex-row justify-center items-center w-full gap-4">
          <Button
            type="submit"
            className="flex flex-row justify-center items-center gap-[0px] bg-[#44146D] rounded-[9999px] px-[16px] pt-[10px] pb-[12px]"
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            <span className=" text-white font-ithraV3 text-17 font-400 leading-20">
              {t('ACTIVATE_REQUEST')}
            </span>
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ReviewRequestForm
