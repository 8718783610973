import { Avatar, Chip } from '@nextui-org/react'
import { ActivateRequestDetailResponse } from 'api/types'
import { getRequestActivityStatusLabel } from 'constants/requestActivityStatus'
import dayjs from 'dayjs'
import useLanguage from 'hooks/useLanguage'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface RequestActivitesProps {
  activateRequestDetail?: ActivateRequestDetailResponse | null
}

const RequestActivites: React.FC<RequestActivitesProps> = ({ activateRequestDetail }) => {
  const data = activateRequestDetail?.data
  const { t } = useTranslation()
  const language = useLanguage()

  const renderActivityComponent = (status: string, activity: any) => {
    const renderIcon = (color: string, pathD: string) => (
      <div className={`w-8 h-8 bg-${color}-100 rounded-full flex items-center justify-center`}>
        <svg
          className={`w-6 h-6 text-${color}-500`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={pathD}></path>
        </svg>
      </div>
    )

    const renderActivityCard = (iconColor: string, iconPath: string, title: string) => (
      <div className="flex items-center mb-8 gap-2">
        <div className="flex flex-col items-center">
          {renderIcon(iconColor, iconPath)}
          <div className="h-full w-px bg-gray-300"></div>
        </div>
        <div className="ml-4 flex-grow bg-white p-4 rounded-md shadow-md">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <p className="text-sm text-gray-500">
            {activity?.created_at
              ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
              : '-'}
          </p>
          {/* <p className="text-xs text-gray-400 mt-1">{activity?.causer?.name}</p> */}
        </div>
      </div>
    )

    switch (status) {
      case 'coordinator.submit':
        return renderActivityCard('green', 'M5 13l4 4L19 7', t('SUBMITTED_AN_APPLICATION'))

      case 'coordinator.approve':
        return (
          <div className="flex items-center mb-8 gap-2">
            {renderIcon('red', 'M12 8v4l3 3')}
            <div className="ml-4 flex-grow bg-white p-4 rounded-md shadow-md">
              <Chip color="success">
                {getRequestActivityStatusLabel(activity?.description ?? '', language)}
              </Chip>
              <h3 className="text-lg font-semibold text-black-600">
                {t('THE_ORDER_STATUS_HAS_BEEN_MODIFIED')}
              </h3>
              <p className="text-sm text-gray-500">
                {activity?.created_at
                  ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
                  : '-'}
              </p>
              <p className="flex flex-row justify-start items-center text-xs text-gray-400 mt-1 gap-1">
                <Avatar src="" style={{ width: 20, height: 20 }} />
                <span>{t('FROM')}</span>
                {activity?.causer?.name}
              </p>
            </div>
          </div>
        )

      case 'coordinator.reject':
        return (
          <div className="flex items-center mb-8 gap-2 w-full">
            {renderIcon('red', 'M12 8v4l3 3')}
            <div className="ml-4 flex-grow bg-white p-4 rounded-md shadow-md">
              <Chip color="danger">
                {getRequestActivityStatusLabel(activity?.description ?? '', language)}
              </Chip>
              <h3 className="text-lg font-semibold text-black-600">
                {t('THE_ORDER_STATUS_HAS_BEEN_MODIFIED')}
              </h3>
              <p className="text-sm text-gray-500">
                {activity?.created_at
                  ? dayjs(new Date(activity?.created_at)).format('DD MMM YYYY hh:mm A')
                  : '-'}
              </p>
              <p className="flex flex-row justify-start items-center text-xs text-gray-400 mt-1 gap-1">
                <Avatar src="" style={{ width: 20, height: 20 }} />
                <span>{t('FROM')}</span>
                {activity?.causer?.name}
              </p>
              {activity?.description === 'coordinator.reject' && (
                <p className="text-xs text-gray-400 mt-1 flex flex-row justify-start items-start gap-0.5">
                  <span className="text-xs text-gray-400 mt-1">{t('REJECTED_REASON')}</span>
                  <span className="text-xs text-gray-400 mt-1">{':'}</span>
                  <span className="text-xs text-gray-400 mt-1">
                    {activity?.properties?.rejection_reason ?? ''}
                  </span>
                </p>
              )}
            </div>
          </div>
        )

      default:
        return <div></div>
    }
  }

  const activityComponents = data?.activites?.map((activity) =>
    renderActivityComponent(activity?.description ?? '', activity)
  )

  return (
    <div className="w-full flex flex-col justify-start items-start">
      <div className="flex flex-col">{activityComponents}</div>
    </div>
  )
}

export default RequestActivites
