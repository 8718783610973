import React from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/airbnb.css'
import './DateRangePicker.css'

interface DateRangePickerProps {
  value: [Date | string, Date | string]
  onChange: (selectedDates: (Date | string)[]) => void
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ value, onChange }) => {
  return (
    <Flatpickr
      options={{ mode: 'range', dateFormat: 'Y-m-d' }}
      value={value}
      onChange={onChange}
      className="custom-date-flatpicker"
    />
  )
}

export default DateRangePicker
