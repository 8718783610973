import React from 'react'
import ModalHeader from 'components/modal/modal-header'
import ModalLayout from 'components/modal/modal-layout'
import termsAndConditions from './terms-and-conditions'
// import ButtonVariant from 'components/button-variant/button'
import { Trans } from '@lingui/react'
import { msg } from '@lingui/macro'

interface termsModalProps {
  handleTermsModalDisplay: (shouldShow: boolean) => void
}

const TermsModal: React.FC<termsModalProps> = ({ handleTermsModalDisplay }) => {
  const handleBack = () => {
    handleTermsModalDisplay(false)
  }

  return (
    <ModalLayout
      onBack={handleBack}
      accountPageStyle
      header={<ModalHeader headerText={msg`الشروط والاحكام`} />}
      body={
        <>
          <div className="border-1 rounded-md p-3">
            {termsAndConditions.map((term) => (
              <p key={term.id}>
                {term.id}. <Trans id={term.content.id} />
              </p>
            ))}
          </div>
          {/* <div className='flex w-fit mx-auto gap-2'>
            <ButtonVariant variant='bordered' onClick={handleBack}><span>إلغاء</span></ButtonVariant>
            <ButtonVariant variant='secondary'><span>موافقة</span></ButtonVariant>
          </div> */}
        </>
      }
    />
  )
}

export default TermsModal
