import { useEffect } from 'react'
import { verifyEmailHash } from 'store/slices/verifyEmailHashSlice'
import { setToken, setVerifiedAt } from 'store/slices/authSlice'
import { useNavigate, useLocation } from 'react-router-dom'
import { AppDispatch } from 'store'
import { useDispatch } from 'react-redux'
import { msg } from '@lingui/macro'
import { i18n } from '@lingui/core'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { toast } from 'react-toastify'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { fetchMe } from 'store/slices/meSlice'

const VerifyEmail = () => {
  useDocumentTitle(msg`التحقق من البريد الإلكتروني`)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const name = useSelector((state: RootState) => {
    const data = state.me?.me?.data
    return typeof data?.first_name === 'string' ? data.first_name : ''
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const hash = queryParams.get('hash')
    const id = queryParams.get('id')

    const handleSuccess = (result: any) => {
      dispatch(setToken(result.data.accessToken))
      dispatch(setVerifiedAt(result.data.user.email_verified_at))

      navigate('/app/home')
      if (name) {
        const message = i18n._(msg`مرحبا`) + ' ' + name + i18n._(msg`، تم تفعيل حسابك بنجاح`)
        toast.success(message)
      }
    }

    if (hash && id) {
      const verify = async () => {
        try {
          const result = await dispatch(
            verifyEmailHash({
              id,
              hash
            })
          ).unwrap()

          if (result.status === 'success') {
            await dispatch(fetchMe())
            handleSuccess(result)
          }
        } catch (error: any) {
          if (error.status === 'error') {
            const message = error.message ?? i18n._(msg`فشل التحقق من البريد الإلكتروني!`)
            toast.error(message)
          }
        }
      }

      verify()
    }
  }, [location.search, dispatch, navigate, name])

  return null
}

export default VerifyEmail
