import useLanguage from 'hooks/useLanguage'
import { useUniversities } from './get-universities'
import { getLocalizedName } from '../utils'

interface UseUniversityOptionsProps {
  countryId?: string
  regionId?: string // Single region ID
}

export const useUniversityOptions = ({ countryId, regionId }: UseUniversityOptionsProps = {}) => {
  const language = useLanguage() as 'ar' | 'en'
  const { universities, isLoading } = useUniversities({ countryId, regionId })

  // Map universities and always add the "Other" option
  const options = universities.map((university: any) => ({
    key: String(university.id),
    label: getLocalizedName(university.name, language)
  }))

  // Add the "Other" option at the end, in both Arabic and English
  const otherOption = {
    key: 'other',
    label: language === 'ar' ? 'اسم الجامعة غير متوفر' : 'Other'
  }

  return {
    options: [...options, otherOption],
    isLoading
  }
}
