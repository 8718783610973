import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { NationalitiesResponse, Nationality } from 'api/types'

interface RegisterState {
  nationalities: Nationality[]
  isLoading: boolean
  error: string | null
}

const initialState: RegisterState = {
  nationalities: [],
  isLoading: false,
  error: null
}

export const fetchNationalities = createAsyncThunk('nationalities', async () => {
  const response = await axiosInstance.get<NationalitiesResponse>('/geo/nationalities')
  return response.data
})

const nationalitiesSlice = createSlice({
  name: 'nationalities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchNationalities.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchNationalities.fulfilled, (state, action) => {
        state.isLoading = false
        const nationalities = action.payload.data ?? []
        state.nationalities = nationalities
      })
      .addCase(fetchNationalities.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? ''
      })
  }
})

export default nationalitiesSlice
