// hooks/useNationalities.ts

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { fetchCountries } from 'store/slices/countriesSlice'

export const useCountries = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { countries, isLoading } = useSelector((state: RootState) => state.countries)

  useEffect(() => {
    dispatch(fetchCountries())
  }, [dispatch])

  return { countries, isLoading }
}
