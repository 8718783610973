import { z } from 'zod'
import { msg } from '@lingui/macro'

const validationSchema = z
  .object({
    otp1: z.string().min(1, msg`OTP must be 1 digit`.id),
    otp2: z.string().min(1, msg`OTP must be 1 digit`.id),
    otp3: z.string().min(1, msg`OTP must be 1 digit`.id),
    otp4: z.string().min(1, msg`OTP must be 1 digit`.id)
  })
  .superRefine((data, ctx) => {
    const otp = `${data.otp1}${data.otp2}${data.otp3}${data.otp4}`

    if (otp.length !== 4 || !/^\d{4}$/.test(otp)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: msg`Please enter a valid 4-digit OTPmsg`.id,
        path: ['otp']
      })
    }
  })

export default validationSchema
