import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextareaField from 'components/textarea-field/textarea-field'

type TextareaInputProps = {
  name: string
  label?: string
  helperText?: React.ReactNode
  maxLength?: number // You can pass maxLength as a prop to enable character counting
} & Omit<React.ComponentProps<typeof TextareaField>, 'name' | 'label' | 'helperText'>

const TextareaInput: FC<TextareaInputProps> = ({
  name,
  label,
  helperText,
  maxLength,
  ...other
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextareaField
          {...field}
          label={label || name}
          helperText={helperText}
          error={error ? error.message : null}
          maxLength={maxLength} // Pass maxLength to enable character counting
          {...other}
        />
      )}
    />
  )
}

export default TextareaInput
