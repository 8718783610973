import React from 'react'
import { Trans } from '@lingui/react'
import { MessageDescriptor } from '@lingui/core'

interface LinkProps {
  placeholder: MessageDescriptor
  className?: string
}

const Link: React.FC<LinkProps> = ({ placeholder, className }) => {
  return (
    <div className={`text-lg flex flex-col space-y-2 group cursor-pointer ${className}`}>
      <h5 className="font-ithra font-medium px-1.5">
        <Trans id={placeholder.id} />
      </h5>
      <div className="self-end w-0 h-1 bg-customTealLv2 transition-all duration-500 ease-in-out group-hover:w-full text-left rounded-full"></div>
    </div>
  )
}

export default Link
