import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { z } from 'zod'

export const useRestAccountPasswordSchema = () => {
  const { _ } = useLingui()

  return z
    .object({
      currentPassword: z.string({
        required_error: _(msg`هذا الحقل مطلوب`)
      }),
      newPassword: z
        .string({
          required_error: _(msg`هذا الحقل مطلوب`)
        })
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[0-9a-zA-Z!@#$%^&*()]+$/,
          _(msg`كلمه السر ليست قوية بما فيه الكفاية`)
        )
        .min(8, {
          message: _(msg`كلمة المرور على الاقل 8 رموز`)
        }),
      confirmNewPassword: z
        .string({
          required_error: _(msg`هذا الحقل مطلوب`)
        })
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[0-9a-zA-Z!@#$%^&*()]+$/,
          _(msg`كلمه السر ليست قوية بما فيه الكفاية`)
        )
        .min(8, {
          message: _(msg`كلمة المرور على الاقل 8 رموز`)
        })
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: _(msg`كلمة المرور الحالية لاتتطابق مع كلمة مرور الحساب`),
      path: ['confirmNewPassword']
    })
}
