type ValidValues = string | number | boolean | object | symbol | bigint | null | undefined

export function cleanObject<T extends Record<string, ValidValues>>(obj: T): Partial<T> {
  const cleanedObj: Partial<T> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (value !== undefined && value !== null && value !== false && value !== '') {
        cleanedObj[key] = value
      }
    }
  }

  return cleanedObj
}
