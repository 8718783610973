import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setSubmissionDate } from 'store/slices/activateRequestsSlice'
import DateRangePicker from 'components/DateRangePicker'

const SubmissionDateFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fromSubmissionDate, toSubmissionDate } = useSelector(
    (state: RootState) => state.activateRequests
  )

  const handleSubmissionDateChange = (selectedDates: (Date | string)[]) => {
    const [start, end] = selectedDates
    const fromDate = start ? start : ''
    const toDate = end ? end : ''
    dispatch(setSubmissionDate({ from: fromDate, to: toDate }))
  }

  return (
    <div className="flex flex-col gap-0.5">
      <p>{t('SUBMISSION_DATE_FILTER')}</p>
      <DateRangePicker
        value={[fromSubmissionDate, toSubmissionDate]}
        onChange={handleSubmissionDateChange}
      />
    </div>
  )
}

export default SubmissionDateFilter
